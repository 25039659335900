import dayjs from 'dayjs';
import {createSelector} from 'reselect';
import {LoadStatuses} from '@types/load.types';

const emptyArr = [];
const emptyObj = {};

export const selectProfile = state => state.profile || emptyObj;
export const getActiveOrgId = ({profile: {activeOrg}}) => activeOrg.orgId;

export const getProfile = ({profile: {person}}) => person;
export const getRoles = ({profile: {roles}}) => roles;
export const getEvents = ({profile: {events}}) => events;
export const getSelfSign = ({profile: {selfSign}}) => selfSign;
export const getExpiring = ({profile: {expiring}}) => expiring;
export const getShowRoles = ({profile: {show_role}}) => show_role;

export const getProfileCompetenceLevel = ({profile: {person: {competencelevel}}}) => competencelevel;

export const getIsFetchingProfile = createSelector(
  getProfile,
  ({status}) => status === LoadStatuses.IS_LOADING,
);

export const isManager = createSelector(
  getProfile,
  person => {
    if (person) {
      return person.storeManager;
    }

    return false;
  },
);

export const getPassed = ({profile: {passed}}) => passed;

export const getSection = ({profile: {section}}) => section;
export const getTab = ({profile: {tab}}) => tab;
export const getCompetences = ({profile: {competences}}) => competences.data;
export const shouldUpdate = ({profile: {updated}}) => updated === null;

export const getProfileData = ({profile: {person: {data}}}) => data;

export const getProfileId = createSelector(
  getProfileData,
  data => data?.id,
);

const selectSummary = ({profile: {summary}}) => summary;

const selectRolesData = createSelector(
  selectSummary,
  summary => summary?.data?.requirement || emptyArr,
);

export const selectOnboardingData = createSelector(
  selectRolesData,
  rolesData => rolesData?.find(req => req.type === 'onboarding'),
);

export const getIsFullPersonFetched = createSelector(
  selectProfile,
  ({person, roles}) => {
    let progress = 0;

    if (person && person.data) {
      progress += 1;
    }
    if (roles && roles.data) {
      progress += 1;
    }
    if (progress === 2) {
      return 100;
    }

    return 100 / progress;
  },
);

export const getPersonOrganisations = createSelector(
  getProfile,
  person => person?.data?.organisations || emptyArr,
);

export const getOrganisation = createSelector(
  getPersonOrganisations,
  getActiveOrgId,
  (organisations, activeOrgId) => {
    if (organisations) {
      let orgId = activeOrgId || localStorage.getItem('orgId');

      if (typeof orgId === 'string') {
        orgId = Number.parseInt(orgId, 0);
      }

      return (
        organisations?.find(o => o.id === orgId)
        || organisations?.[0]
      );
    }

    return null;
  },
);

export const getOrganisationId = createSelector(
  isManager,
  getOrganisation,
  getActiveOrgId,
  (manager, mainOrg, activeOrgId) => {
    const orgId = activeOrgId || localStorage.getItem('orgId');

    if (manager && orgId) {
      return orgId;
    }

    if (mainOrg) {
      return mainOrg.organisation_id;
    }

    return mainOrg;
  },
);

export const getBrandId = createSelector(
  getOrganisation,
  org => {
    if (org) {
      return org.brand_id;
    }

    return org;
  },
);

export const getSelectedRoleId = createSelector(
  selectProfile,
  ({show_role}) => {
    console.log({show_role});
    if (show_role?.data) {
      return show_role.data.id;
    }

    return null;
  },
);

export const getHelptexts = ({profile: {helptexts}}) => helptexts;

export const getMainOrganisations = createSelector(
  getProfile,
  getActiveOrgId,
  (person, activeOrgId) => {
    if (person.data) {
      const orgId = activeOrgId || localStorage.getItem('orgId');

      if (orgId && person.organisations && Array.isArray(person.organisations)) {
        const can_we_return = person.organisations.filter(o => o.id === Number.parseInt(orgId, 0));

        if (can_we_return.length !== 0) {
          return {
            title: can_we_return[0].title,
            brand_id: person.data.organisations[0].brand_id,
            id: can_we_return[0].id,
          };
        }
      }

      return person.data.organisations[0];
    }

    return null;
  },
);

export const getSpecialroles = ({profile: {specialroles}}) => specialroles.data;
export const getIsSuperuser = ({profile: {specialroles}}) => specialroles.superuser;
export const getIsDriftsjef = ({profile: {specialroles}}) => specialroles.driftsjef;

export const getProfileUserName = ({profile: {person}}) => {
  if (person.data) {
    return person.data.user_name;
  }

  return null;
};

export const getProfileOrganisations = ({profile: {person}}) => person.data.organisations;

export const getPassedData = createSelector(
  getPassed,
  passed => passed?.data || emptyArr,
);

export const getPassedIds = createSelector(
  getPassedData,
  passed => {
    if (!passed?.length) {
      return emptyArr;
    }

    const ids = [];

    for (const element of passed) {
      if (element.passed === 100) {
        if (element.valid_until && dayjs(element.valid_until).isBefore(dayjs())) {
          ids.push(element.competence_id);
        } else {
          ids.push(element.competence_id);
        }
      }
    }

    return ids;
  },
);

export const getPassedIdsNew = createSelector(
  getPassedData,
  passed => {
    if (!passed?.length) return emptyArr;

    const ids = [];

    for (const competence of passed) {
      if (competence.passed === 100) {
        ids.push(competence.competence_id);
      }
    }

    return ids;
  },
);

export const getPassedCompetencesIds = competences => {
  if (!competences.data?.length) {
    return emptyArr;
  }

  const ids = [];

  for (let i = 0; i < competences.data.length; i += 1) {
    if (competences.data[i].passed === 100) {
      ids.push(competences.data[i].competence_id);
    }
  }

  return ids;
};

// export const getRole = ({profile: {roles}}, roleId = null) => {
//   if (!roles.data) {
//     return emptyArr;
//   }
//   if (roleId) {
//     return roles.data.find(f => f.id === roleId);
//   }

//   return roles.data;
// };

const getPassedCompetencesCheck = competences => {
  if (!Array.isArray(competences.data)) {
    return emptyArr;
  }

  return competences.data.filter(competence => competence.passed === 100);
};

export const getMessageUnreadCount = ({messages: {unread}}) => unread.notification;

export const getPassedCompetences = createSelector(
  ({profile: {competences}}) => competences,
  competences => ({
    passed: getPassedCompetencesCheck(competences),
    keys: getPassedCompetencesIds(competences),
  }),
);

export const getPassedCompetencesGroupedByType = ({profile: {passed_full}}) => {
  if (!passed_full.data) {
    return passed_full;
  }

  return {
    ...passed_full,
    data: Object.entries(passed_full.data.reduce((groupedCompetences, personcompetence) => {
      const {competence_type: competenceType} = personcompetence;

      return {
        ...groupedCompetences,
        [competenceType.id]: {
          ...competenceType,
          personcompetences: [
            ...groupedCompetences[competenceType.id]
              ? groupedCompetences[competenceType.id].personcompetences
              : [],
            personcompetence,
          ],
        },
      };
    }, {})).map(([, competenceTypeGroup]) => competenceTypeGroup),
  };
};

export const getRecommendedCompetences = ({profile: {recommendedCompetences}}) => recommendedCompetences;

export const getCVs = ({profile: {cvs}}) => cvs;
export const getCVsFull = ({profile: {cvs_full}}) => cvs_full;

export const getReport = ({profile: {report}}) => report;

export const getNormalizedProfileEvents = ({profile: {normalizedData: {events}}}) => events;

export const getProfileEventsLoadStatus = ({profile: {normalizedData: {events: {status}}}}) => status;
