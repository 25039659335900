
export function searchItems(node, find) {
  if (node) {
    if (node.organisation_id === find) {
      return node;
    } else if (node.children != null) {
      let result = null;

      for (let i = 0; result == null && i < node.children.length; i++) {
        result = searchItems(node.children[i], find);
      }

      return result;
    }
  }

  return null;
}
