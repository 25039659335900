import {selectors} from '../selectors';
import {breakpointKeys, breakpoints} from './breakpoints';
import {units} from './units';

const postFix = (prefix, key) => `${prefix}-${key}`;

export const camelCaseToDash = str => str.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();

export const dashToCamelCase = str => str.replace(/-([a-z])/g, g => g[1].toUpperCase());

/*
    Takes a selector and returns a string with postfixed selectors
    for each breakpoint before given breakpoint key
    e.g.:
    selectorsBefore('.--default-padding', 'mobile')
        => '.--default-padding, .--default-padding-desktop, .--default-padding-tablet'
*/
export const getBreakpointSelectorsBefore = (baseClassName = '', breakpointKey = '', includeFirst = true, toDashCase = true) => {
  const idx = breakpointKeys.indexOf(breakpointKey);

  if (idx === -1 || idx === 0 && !includeFirst) return '';

  const breakpointsBefore = breakpointKeys.slice(0, idx + 1);

  const classNamesBefore = breakpointsBefore
    .map(key => postFix(
      baseClassName,
      toDashCase
        ? camelCaseToDash(key)
        : key,
    ))
    .join(', ');

  if (includeFirst) {
    return `${baseClassName}${classNamesBefore.length ? ', ' + classNamesBefore : ''}`;
  }

  return classNamesBefore;
};

const noBorderRadius = () => `
    border-radius: 0 !important;

    * {
        border-radius: 0 !important;
    }
`;

const overrideSidePadding = sidePadding => `
    margin-left: -${sidePadding} !important;
    width: calc(100% + ${sidePadding} * 2) !important;
    max-width: calc(100% + ${sidePadding} * 2) !important;
`;

export const containerMaxWidth = width => `
    &.--container-${width} {
        ${selectors.global.container} {
            max-width: ${width}px;
        }
    }
`;

export const imageReset = `
    position: relative !important;
    display: block !important;
    overflow: hidden !important;
    max-width: unset !important;
    border-radius: 0 !important;
`;

const fullwidthImage = sidePadding => `
    ${imageReset}
    ${overrideSidePadding(sidePadding)}
`;

export const getSidePadding = sidePadding => `
    padding-left: ${sidePadding} !important;
    padding-right: ${sidePadding} !important;
`;

const utilClasses = breakpointKeys.reduce((acc, breakpointKey) => {
  const sidePadding = units[breakpointKey]?.sidePadding;

  if (!sidePadding) return acc;

  acc[breakpointKey] = {
    defaultPadding: `${getBreakpointSelectorsBefore(
      selectors.utils.defaultPadding,
      breakpointKey,
    )} {
        ${getSidePadding(sidePadding)}
    }`,
    overrideSidepadding: `${getBreakpointSelectorsBefore(
      selectors.utils.overrideSidepadding,
      breakpointKey,
    )} {
        ${overrideSidePadding(sidePadding)}
    }`,
    fullwidthImage: `${getBreakpointSelectorsBefore(
      selectors.utils.fullwidthImage,
      breakpointKey,
    )} {
        ${fullwidthImage(sidePadding)}
    }`,
    globalContainer: `${selectors.global.container} {
        ${getSidePadding(sidePadding)}
    }`,
    noBorderRadius: `${getBreakpointSelectorsBefore(
      selectors.utils.noBorderRadius,
      breakpointKey,
    )} {
        ${noBorderRadius()}
    }`,
  };

  return acc;
}, {});

export const getUtilClassesForBreakpoint = breakpointKey => Object.values(utilClasses[breakpointKey] || {}).join('\n');

export const buildMediaQueriesString = styleObject => {
  const mediaQueries = {};

  Object.keys(styleObject).forEach(selector => {
    const properties = styleObject[selector];

    Object.keys(properties).forEach(property => {
      const value = properties[property];

      const dashProperty = camelCaseToDash(property);

      if (typeof value === 'object') {
        Object.keys(value).forEach(breakpoint => {
          if (!breakpoints[breakpoint]) return;

          if (!mediaQueries[breakpoint]) {
            mediaQueries[breakpoint] = {};
          }
          if (!mediaQueries[breakpoint][selector]) {
            mediaQueries[breakpoint][selector] = '';
          }
          mediaQueries[breakpoint][selector] += `${dashProperty}:${value[breakpoint]};`;
        });
      }
    });
  });

  const mediaQueriesString = breakpointKeys.map(breakpoint => {
    const mediaQuery = mediaQueries[breakpoint];

    if (!mediaQuery) return '';

    return `@media (max-width: ${breakpoints[breakpoint]}) {
          ${Object.keys(mediaQuery).map(selector => `${selector}{${mediaQuery[selector]}}`)
      .join('')}
          ${getUtilClassesForBreakpoint(breakpoint)}
      }`;
  })
    .join('');

  const base = mediaQueries?.base
    ? `@media (min-width: ${breakpoints.base}) {
          ${Object.keys(mediaQueries.base).map(selector => `${selector}{${mediaQueries.base[selector]}}`)
      .join('')}
    }`
    : '';

  return `${base}\n${mediaQueriesString}`;
};
