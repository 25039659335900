import dayjs from 'dayjs';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';
import _uniqWith from 'lodash/uniqWith';
import {createSelector} from 'reselect';
import {EVENT_TYPES} from '@components/calendar-with-tooltip/util.jsx';
import {courseCatalog as courseCatalogRoutes} from '@routes/routes.manifest';
import {i18ndayjs} from '@src/i18n';
import {LoadStatuses} from '@types/load.types';
import {getRouteWithParams} from '@utils/routes.utils';
import {getCompentenceMetaData, getCompetenceCoverImage} from '../util/courses.util';
import {
  getConfigObject,
  getDefaultImage,
  getPropertiesForCurrLangAndTrack,
} from './config.selectors';
import {getNormalizedEmployees, getNormalizedEmployeesEvents} from './employees.selectors';
import {getNormalizedProfileEvents, getPassed, getPassedIdsNew, getProfileId, isManager} from './profile.selectors';

export const getNormalizedCompetenceDetails = ({courses: {normalizedData: {competenceDetails}}}) => competenceDetails;

export const getNormalizedCourses = ({courses: {normalizedData}}) => normalizedData;

export const getNormalizedCourseEvents = ({courses: {normalizedData: {courseEvents}}}) => courseEvents;

export const getNormalizedCompetencegroups = ({courses: {normalizedData: {competencegroups}}}) => competencegroups && competencegroups || [];

export const getGroupedCompetenceIds = ({courses: {normalizedData: {competenceIdsByGroupId}}}) => competenceIdsByGroupId;

export const getNormalizedCompetences = ({courses: {normalizedData: {competences}}}) => competences;

export const getNormalizedSelectedCompetenceGroup = ({courses: {normalizedData: {selectedCompetencegroup}}}) => selectedCompetencegroup;

export const getNormalizedSelectedCompetenceGroups = ({courses: {normalizedData: {selectedCompetencegroups}}}) => selectedCompetencegroups;

export const getCompetencesSearchTerm = ({courses: {competences: {searchTerm}}}) => searchTerm;

export const getCompetencesSearchResults = ({courses: {normalizedData: {competencesSearchResults}}}) => competencesSearchResults;

export const reselectSearchResults = createSelector(
  getNormalizedCourses,
  ({competencesSearchResults}) => competencesSearchResults,
);

export const reselectSearchResultsData = createSelector(
  reselectSearchResults,
  results => results.data,
);

export const getCompetencesSearchState = createSelector(
  reselectSearchResults,
  getCompetencesSearchTerm,
  ({status, empty}, searchTerm) => ({
    isActive: !!searchTerm,
    isLoading: status === LoadStatuses.IS_LOADING,
    isEmpty: empty,
    status,
    searchTerm,
  }),
);


export const getEventsGroupedByYearMonth = createSelector(
  getNormalizedCourseEvents,
  ({eventIdsByYearMonth, eventById}) => {
    const grouped = Object.entries(eventIdsByYearMonth)
      .sort((a, b) => Number.parseInt(a[0]) - Number.parseInt(b[0]))
      .map(([year, monthObj]) => {
        const months = Object.entries(monthObj)
          .sort((a, b) => Number.parseInt(a[0]) - Number.parseInt(b[0]))
          .map(([month, eventIds]) => ({
            monthName: i18ndayjs(`${year}-${month}`).format('MMMM'),
            month,
            events: eventIds.map(id => eventById[id]),
          }));

        return {
          year,
          months,
        };
      });

    return grouped;
  },
);

const getAutostartCourseTypes = createSelector(
  getConfigObject,
  configObject => configObject?.getProperty?.('routes.course-catalog.autostartCourseTypes') || [],
);

export const getNormalizedSelectedCompetences = createSelector(
  getNormalizedCompetencegroups,
  getNormalizedSelectedCompetenceGroups,
  getCompetencesSearchTerm,
  reselectSearchResults,
  getPassedIdsNew,
  getPassed,
  getDefaultImage,
  getAutostartCourseTypes,
  (
    {status: competenceGroupsLoadStatus},
    {competences, status: competencesLoadStatus},
    searchTerm,
    {data: searchResultsData, status: searchStatus},
    passedCompetenceIds,
    passedCompetences,
    defaultImage,
    autoStartCourseTypes,
  ) => {
    const searchActive = !!searchTerm;

    let isFetching, competencesData;

    if (searchActive) {
      isFetching = searchStatus === LoadStatuses.IS_LOADING;
      competencesData = Object.values(searchResultsData);
    } else {
      isFetching = competencesLoadStatus === LoadStatuses.IS_LOADING;
      competencesData = competences;
    }

    const passedIdsSet = new Set(passedCompetenceIds);
    const autoStartCoursetypesSet = new Set(autoStartCourseTypes);

    const competencesWithProps = competencesData?.map?.(competence => {
      const {id, url, competence_type} = competence || {};

      if (!id) return null;

      const {durationText, points, passed} = getCompentenceMetaData({
        competence,
        passedIdsSet,
        passedCompetences,
      }) || {};

      return {
        ...competence,
        durationText,
        points,
        passed,
        coverImage: getCompetenceCoverImage(competence, defaultImage),
        shouldAutostartCourse: !url && autoStartCoursetypesSet.has(competence_type), // getShouldAutostartCourse(competence_type, url),
        competenceUrl: getRouteWithParams(courseCatalogRoutes.coursePreview.path, {cid: id}),
      };
    });

    return {
      isFetching,
      data: competencesWithProps,
      empty: !isFetching && !competencesData.length,
    };
  },
);

export const getCompetences = createSelector(
  ({courses: {competences}}) => competences,
  competences => ({
    ...competences,
    data: Array.isArray(competences.data)
    && competences.data.map(competence => {
      if (!Array.isArray(competence.files)) {
        return {
          ...competence,
          image: competence.content && competence.content.image,
          contentRoute: competence.url ? `/content/${competence.url}` : null,
        };
      }
      const cover = competence.files.find(file => file.title === 'cover');
      const durations
        = competence.durations
          && competence.durations.length
          && competence.durations[0].duration
        || null; // ToDo: Get dynamic value from API
      const durationType
        = competence.durations
          && competence.durations.length
          && competence.durations[0].type
        || null;

      return {
        ...competence,
        cover,
        image:
          cover && cover.url
          || competence.content && competence.content.image,
        contentRoute: competence.url ? `/content/${competence.url}` : null,
        durations,
        allDurations: competence.durations,
        durationType,
      };
    }),
  }),
);

export const getFeeaturedCompetences = createSelector(
  ({courses: {featuredCompetences}}) => featuredCompetences,
  featuredCompetences => {
    const competences = getCompetences({courses: {competences: featuredCompetences}});

    return competences;
  },
);

export const getCourseEvents = createSelector(
  getNormalizedCourseEvents,
  ({allEventIds, eventById, ...rest}) => ({
    ...rest,
    data: allEventIds.map(id => eventById[id]),
  }),
);

export const getCompetencegroups = ({courses: {competencegroups}}) => competencegroups;

export const getCourseCatalogNews = ({courses: {courseCatalogNews}}) => courseCatalogNews;

export const getCompetencetypes = ({courses: {competencetypes}}) => competencetypes;

export const getSelectedCatalogView = ({courses: {filters: {catalogView}}}) => catalogView;

export const getSelectedCourseKindTab = ({courses: {filters: {courseKind:{tab}}}}) => tab;

export const getSelectedCompetencegroupId = ({courses: {selectedCompetencegroupId}}) => selectedCompetencegroupId;

export const getSelectedSubcompetencegroupId = ({courses: {selectedSubcompetencegroupId}}) => selectedSubcompetencegroupId;

export const getSelectedSubSubcompetencegroupId = ({courses: {selectedSubSubcompetencegroupId}}) => selectedSubSubcompetencegroupId;

export const getSigningOnCourse = ({courses: {courseSignOn}}) => courseSignOn;

export const getSigningOffCourse = ({courses: {courseSignOff}}) => courseSignOff;

export const getCoursesSorting = ({courses: {sorting}}) => sorting;

export const getCourseFilters = ({courses}) => courses.filters;

export const getSelectedCompetencetypes = createSelector(
  ({courses: {filters: {selectedCompetencetypes}}}) => selectedCompetencetypes,
  selectedCompetencetypes =>
    Object.keys(selectedCompetencetypes).reduce(
      (ids, id) => selectedCompetencetypes[id] && [...ids, id] || ids,
      [],
    ),
);

export const getSelectedCompetencetypesNames = createSelector(
  ({courses: {competencetypes, selectedCompetencetypes}}) => ({
    competencetypes,
    selectedCompetencetypes,
  }),
  ({competencetypes, selectedCompetencetypes}) => competencetypes.data
    ? new Set(competencetypes.data
      .filter(({id}) => selectedCompetencetypes.has(id))
      .map(({competence_type}) => competence_type))
    : new Set(),
);

export const getInitializeMyCoursesView = state => state.courses.initializeMyCoursesView;

export const getCompetenceDetails = createSelector(
  ({courses: {competenceDetails}}) => competenceDetails,
  competenceDetails => ({
    ...competenceDetails,
    data: competenceDetails.data && (competence => {
      let cover;
      let duration;

      if (
        Array.isArray(competence.files)
        && competence.files.filter(file => file.title === 'cover').length
      ) {
        cover = competence.files.find(file => file.title === 'cover');
      }
      if (Array.isArray(competence.durations) && competence.durations.length) {
        [duration] = competence.durations;
      }

      return {
        ...competence,
        cover,
        duration: duration && {
          value: duration.duration,
          metric: duration.type,
        },
      };
    })(competenceDetails.data),
  }),
);

export const getActiveCourse = ({courses: {activeCourse}}) => activeCourse;

export const getSignOnCourseResults = ({courses: {courseSignOn: {results, isFetching}}}) => ({
  results,
  isFetching,
});

export const getCurrentSignature = ({courses: {currentSignature}}) => currentSignature;

export const getSignCourse = ({courses: {courseSignCourse}}) => courseSignCourse;

export const getFeaturedContentIds = createSelector(
  getPropertiesForCurrLangAndTrack,
  getConfigObject,
  (configForCurrLangAndMap, configObject) => {
    const featuredTiles = configObject.getProperty('routes.course-catalog.customToplevelSelector.featuredTiles');
    const featuredTilesForCurrMap = _get(
      configForCurrLangAndMap,
      'courseCatalog.featuredTiles.contentIds',
    );

    const ids = [];

    if (featuredTilesForCurrMap) {
      if (Array.isArray(featuredTilesForCurrMap)) {
        ids.push(featuredTilesForCurrMap);
      } else {
        ids.push([featuredTilesForCurrMap]);
      }
    }
    if (featuredTiles && featuredTiles.contentIds) {
      if (Array.isArray(featuredTiles.contentIds)) {
        ids.push(featuredTiles.contentIds);
      } else {
        ids.push([featuredTiles.contentIds]);
      }
    }

    return _uniqWith(ids, _isEqual);
  },
);

export const getNormalizedCourseEventsExtraData = createSelector(
  getProfileId,
  getNormalizedCourseEvents,
  getNormalizedProfileEvents,
  getNormalizedEmployees,
  getNormalizedEmployeesEvents,
  isManager,
  (_, cid) => cid,
  (profileId, courseEvents, profileEvents, employees, employeesEvents, manager, courseId) => {
    const {status: eventsStatus, eventById, allEventIds, eventIdsByCourseId} = courseEvents || {};

    if (![LoadStatuses.LOADED, LoadStatuses.LOADED_PARTIAL].includes(eventsStatus)
      || !allEventIds?.length
      || profileEvents.status !== LoadStatuses.LOADED) {
      return courseEvents;
    }

    const extraDataLoading = profileEvents.status === LoadStatuses.IS_LOADING
      || employeesEvents.status === LoadStatuses.IS_LOADING
      || employees.status === LoadStatuses.IS_LOADING;

    if (extraDataLoading) {
      return courseEvents;
    }

    const getIsSelfInWaitlist = eventId => !!profileEvents?.waitlistIds?.includes?.(eventId);
    const getIsSelfConfirmed = eventId => !!profileEvents?.confirmedIds?.includes?.(eventId);

    const {
      byId: employeeById,
      allIds: employeeIds,
      status: employeesStatus,
    } = employees || {};

    const {
      employeeIdsConfirmedByEventId,
      employeeIdsWaitlistByEventId,
      employeeIdsByEventId,
      status: employeesEventsStatus,
      persons,
    } = employeesEvents || {};

    const isEmployeesLoaded = employeesStatus === LoadStatuses.LOADED
      && employeesEventsStatus === LoadStatuses.LOADED;

    const getEmployee = employeeId => employeeId === profileId
      ? profileEvents?.eventsConfirmed?.[0]?.person || profileEvents?.eventsWaitlist?.[0]?.person
      : persons?.[employeeId] || employeeById?.[employeeId];

    const getEmployeeIdsNotSignedUp = (eventId, selfSignedUp) => {
      const employeeIdsSignedUp = employeeIdsByEventId[eventId] || [];

      return employeeIds.filter(employeeId => {
        if (selfSignedUp && employeeId === profileId) return false;

        return !employeeIdsSignedUp.includes(employeeId);
      });
    };

    const eventIds = courseId
      ? eventIdsByCourseId
        ? eventIdsByCourseId?.[courseId] || []
        : []
      : allEventIds || [];

    const eventByIdExtraData = eventIds.reduce((acc, eventId) => {
      const event = eventById[eventId];

      if (!event) return acc;

      const loadedExtraData = manager ? isEmployeesLoaded : true;

      const isSelfInWaitlist = getIsSelfInWaitlist(eventId);
      const isSelfConfirmed = getIsSelfConfirmed(eventId);

      const isSelfSignedUp = isSelfInWaitlist || isSelfConfirmed;

      acc[eventId] = {
        ...event,
        participantsData: {
          isSelfInWaitlist,
          isSelfConfirmed,
          isSelfSignedUp,
          employeeIdsSignedUp: [],
          employeeIdsNotSignedUp: [],
          employeeIdsWaitlist: [],
          employeeIdsConfirmed: [],
          // employeesSignedUp: [],
          employeesNotSignedUp: [],
          employeesWaitlist: [],
          employeesConfirmed: [],
        },
        loadedExtraData,
      };

      const me = isSelfSignedUp ? [profileId] : [];

      if (loadedExtraData) {
        acc[eventId].participantsData.employeeIdsNotSignedUp = getEmployeeIdsNotSignedUp(eventId, isSelfSignedUp);
        acc[eventId].participantsData.employeeIdsSignedUp = [...me, ...employeeIdsByEventId[eventId] || []];
        acc[eventId].participantsData.employeeIdsConfirmed = [...me, ...employeeIdsConfirmedByEventId[eventId] || []];
        acc[eventId].participantsData.employeeIdsWaitlist = [...isSelfInWaitlist ? me : [], ...employeeIdsWaitlistByEventId[eventId] || []];

        const {
          // employeeIdsSignedUp,
          employeeIdsNotSignedUp,
          employeeIdsWaitlist,
          employeeIdsConfirmed,
        } = acc[eventId].participantsData;

        // todo: remove this and use ids instead
        acc[eventId].participantsData.employeesNotSignedUp = employeeIdsNotSignedUp.map(getEmployee)?.filter(Boolean);
        // acc[eventId].participantsData.employeesSignedUp = employeeIdsSignedUp.map(getEmployee)?.filter(Boolean);
        acc[eventId].participantsData.employeesWaitlist = employeeIdsWaitlist.map(getEmployee)?.filter(Boolean);
        acc[eventId].participantsData.employeesConfirmed = employeeIdsConfirmed.map(getEmployee)?.filter(Boolean);
      }

      return acc;
    }, {});

    return {
      ...courseEvents,
      loadedExtraData: !extraDataLoading,
      eventById: eventByIdExtraData,
    };
  },
);

export const getSelectedCompetenceTypes = createSelector(
  getNormalizedSelectedCompetenceGroups,
  competencegroups => {
    const {competences} = competencegroups || {};

    if (!competences?.length) return [];

    const types = [];
    const unique = new Set();

    competences.forEach(({
      competence_type,
      competence_type_id,
      competence_type_key,
    }) => {
      if (unique.has(competence_type_id)) return;

      unique.add(competence_type_id);

      types.push({
        competence_type,
        competence_type_id,
        competence_type_key,
      });
    });

    return types;
  },
);

export const getIsFetchingCompetenceGroups = createSelector(
  getNormalizedCompetencegroups,
  competencegroups => competencegroups.status === LoadStatuses.IS_LOADING,
);

export const getCompetencesList = createSelector(
  getCompetencesSearchTerm,
  getCompetencesSearchResults,
  getNormalizedSelectedCompetenceGroups,
  getIsFetchingCompetenceGroups,
  (searchTerm, searchResults, selectedCompetencegroups, isFetching) => {
    if (searchTerm) {
      const {data, status} = searchResults;

      return {
        data: !!data && Object.values(data),
        isFetching: status === LoadStatuses.IS_LOADING,
      };
    }

    return {
      data: selectedCompetencegroups?.competences,
      isFetching,
    };
  },
);

export const getIsFetchingCourseEvents = createSelector(
  getNormalizedCourseEvents,
  courseEvents => courseEvents.status === LoadStatuses.IS_LOADING,
);

export const getAllEventsSortedByDate = createSelector(
  getNormalizedCourseEvents,
  events => {
    const {eventById, allEventIds: sortedEventIds} = events || {};

    if (!sortedEventIds?.length) return [];

    return sortedEventIds.map(id => eventById[id]);
  },
);

export const getCompetenceDetailsById = createSelector(
  getNormalizedCompetenceDetails,
  getNormalizedCourseEventsExtraData,
  (_, cid) => cid,
  (competenceDetails, events, cid) => {
    const {data} = competenceDetails;

    if (!data?.[cid]) return null;

    const {eventById, eventIdsByCourseId} = events;

    const obj = {
      ...data[cid],
      events: [],
      calendarItems: {},
    };

    if (eventIdsByCourseId[cid]) {
      eventIdsByCourseId[cid].forEach(id => {
        const event = eventById[id];

        if (!event) return;

        obj.events.push(event);

        const year = dayjs(event.startDate).year();
        const month = dayjs(event.startDate).month();
        const yearMonthString = `${year}-${month}`;
        const dayNumber = dayjs(event.startDate).format('D');

        if (!obj.calendarItems[yearMonthString]) {
          obj.calendarItems[yearMonthString] = {};
        }

        if (!obj.calendarItems[yearMonthString][dayNumber]) {
          obj.calendarItems[yearMonthString][dayNumber] = [];
        }

        const tooltip = {
          title: event.title,
          dateStart: event.startDate,
          time: event.startTime,
          duration: event.duration,
          id: event.id,
          competence_id: event.competence_id,
          persons: event?.participantsData?.employeesConfirmed?.map?.(employee => ({
            fullname: employee.fullname,
            firstname: employee.firstname,
            lastname: employee.lastname,
            person_id: employee.person_id,
            user_name: employee.user_name,
          })),
          eventType: EVENT_TYPES.courseEvent,
          location: event.location,
        };

        obj.calendarItems[yearMonthString][dayNumber].push(tooltip);
      });
    }

    return obj;
  },
);

export const getCourseEventIdsByYearMonth = state => state.courses.normalizedData.courseEvents.eventIdsByYearMonth;

export const getIsSigningOnOrOffCourseEvent = ({
  courses: {
    courseSignOn,
    courseSignOff,
  },
}) => !!courseSignOn?.isFetching || !!courseSignOff?.isFetching;


export const getCourseCatalogDefaultCompetencegroup = createSelector(
  getPropertiesForCurrLangAndTrack,
  ({courseCatalog} = {}) => courseCatalog?.defaultSelectedCompetenceGroupId,
);

