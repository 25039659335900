/* eslint-disable react/jsx-fragments */
import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {matchPath, useLocation} from 'react-router';
import {useHistory} from 'react-router-dom';
import {MenuTwo} from '@components/menu/menu-two/MenuTwo';
import {helpPagesUrl} from '@config';
import {css} from '@emotion/react';
import {
  courseCatalog, dashboard,
  employees as employeeRoutes, help,
  myEducation,
} from '@routes/routes.manifest';
import {getConfigObject} from '@selectors/config.selectors';
import {getSelectedCompetencegroupId} from '@selectors/courses.selectors';
import {getWorklistSelector} from '@selectors/employees.selectors';
import {
  getIsMobile,
  getIsMobileCourseCatalog,
} from '@selectors/global.selectors';
import {
  getMessageUnreadCount,
  getOrganisationId,
  getProfileData,
  getProfileId,
  isManager as getIsManager,
} from '@selectors/profile.selectors';
import {i18n} from '@src/i18n';
import {getAccess} from '@utils/misc.utils';
import {getRouteWithParams} from '@utils/routes.utils';
import SearchBar from '../search-bar/search-bar';
import DesktopMenu from './desktop-menu/desktop-menu';

export const getColorConfigForGroup = ({
  courseGroupId,
  pathname,
  isMobile,
  configObject,
  forMenu,
}) => {
  const defaultColor
    = configObject.getProperty(`params.menu.${isMobile ? 'baseColorMobile' : 'baseColorDesktop'}`) || 'rgb(1, 0, 49)';

  if (forMenu) {
    return {
      color: defaultColor,
      createGradient: false,
    };
  }

  if (!isMobile) {
    return {
      color: configObject.getProperty('params.menu.baseColorDesktop'),
      createGradient: false,
    };
  }
  const noActiveCourseGroup
    = isMobile && pathname === '/course-catalog'
    || !isMobile && !courseGroupId
    || pathname === '/atlas';

  if (noActiveCourseGroup) {
    return {
      color: defaultColor,
      createGradient: false,
    };
  }

  return {
    color:
      configObject.getProperty(`params.menu.configByCourseGroupId.${courseGroupId}.baseColor`) || defaultColor,
    createGradient:
      configObject.getProperty(`params.menu.configByCourseGroupId.${courseGroupId}.gradient`) || false,
  };
};

const getMenuItems = ({
  configObject,
  access,
  history,
  orgId,
  isManager,
  showDashboard,
  location,
  worklistCount,
}) => {
  if (access.learingportal) {
    const baseTitle
      = configObject.getProperty('params.default-title-key')
      && i18n(configObject.getProperty('params.default-title-key'));

    const accessLevel = configObject.getProperty('params.menu.access');
    const menuLinksManager = [
      {
        name: i18n('menu.my-page'),
        title: i18n('menu.my-page'),
        label: i18n('menu.my-page'),
        onClick: () => {
          history.push(getRouteWithParams(myEducation.main.path, {}));
        },
        type: 'featureLink',
        attach: ['mobile'],
        path: '/my-education',
        active: matchPath(location.pathname, {
          path: '/my-education',
          exact: false,
        }),
      },
      {
        name: i18n('menu.course-catalog'),
        label: i18n('menu.course-catalog'),
        title: `${i18n('menu.course-catalog')}${
          baseTitle && ` - ${baseTitle}` || ''
        }`,
        onClick: () => {
          history.push(getRouteWithParams(courseCatalog.main.path, {}));
        },
        path: '/course-catalog',
        type: 'featureLink',
        attach: ['mobile'],
        active: matchPath(location.pathname, {
          path: '/course-catalog',
          exact: false,
        }),
      },
      {
        name: accessLevel === 3 && i18n('menu.units') || i18n('menu.employees'),
        label: accessLevel === 3 && i18n('menu.units') || i18n('menu.employees'),
        title: accessLevel === 3 && i18n('menu.units') || i18n('menu.employees'),
        type: 'featureLink',
        attach: ['mobile'],
        onClick: () => {
          history.push(getRouteWithParams(employeeRoutes.employees, {orgId}));
        },
        path: getRouteWithParams(employeeRoutes.employees, {orgId}),
        active: !location.pathname.includes('worklist') && matchPath(location.pathname, {
          path: '/employees',
          exact: false,
        }),
      },
    ];

    if (worklistCount && worklistCount > 0) {
      menuLinksManager.push({
        name: `${i18n('menu.worklist')} (${worklistCount})`,
        label: i18n('menu.worklist'),
        title: i18n('menu.worklist'),
        type: 'featureLink',
        attach: ['mobile'],
        onClick: () => {
          history.push(getRouteWithParams(employeeRoutes.worklist, {orgId}));
        },
        path: getRouteWithParams(employeeRoutes.worklist, {orgId}),
        active: location.pathname.includes('worklist'),
      });
    }

    if (showDashboard) {
      menuLinksManager.push({
        name: i18n('menu.dashboard'),
        label: i18n('menu.dashboard'),
        title: i18n('menu.dashboard'),
        type: 'featureLink',
        attach: ['mobile'],
        onClick: () => {
          history.push(getRouteWithParams(dashboard.search, {orgId}));
        },
        path: getRouteWithParams(dashboard.search, {orgId}),
        active: matchPath(location.pathname, {
          path: '/dashboard/',
          exact: false,
        }),
      });
    }

    const menuLinksEmployee = [
      {
        name: i18n('menu.my-page'),
        title: i18n('menu.my-page'),
        label: i18n('menu.my-page'),
        onClick: () => {
          history.push(getRouteWithParams(myEducation.main.path, {}));
        },
        type: 'featureLink',
        attach: ['mobile'],
        path: '/my-education',
        active: matchPath(location.pathname, {
          path: '/my-education',
          exact: false,
        }),
      },
      {
        name: i18n('menu.course-catalog'),
        label: i18n('menu.course-catalog'),
        title: `${i18n('menu.course-catalog')}${
          baseTitle && ` - ${baseTitle}` || ''
        }`,
        onClick: () => {
          history.push(getRouteWithParams(courseCatalog.main.path, {}));
        },
        path: '/course-catalog',
        type: 'featureLink',
        attach: ['mobile'],
        active: matchPath(location.pathname, {
          path: '/course-catalog',
          exact: false,
        }),
      },
    ];

    if (access.userAccess !== 'crew') {
      let menuData = [];

      if (access.userAccess === 'superuser' || access.userAccess === 'admin') {
        menuData = configObject.getProperty('params.menu.data');
      }

      return isManager && {
        menuLinks: menuLinksManager,
        adminLinks: menuData,
      } || {menuLinks: menuLinksEmployee};
    }

    return isManager && {
      menuLinks: menuLinksManager,
      adminLinks: [],
    } || {menuLinks: menuLinksEmployee};
  }else {
    const menuData = [{
      label: i18n('menu.admin'),
      type: 'menu',
      items: [
        {
          label: i18n('menu.administration'),
          onClick: () => window.location = '/admin',
          type: 'title',
        },
        {
          type: 'separator',
          margin: ['0', '0', '20px', '0'],
        },
      ],
    }];

    /* CUSTOMER HAS ONBOARDING */
    access.onboarding && menuData[0].items.push({
      label: i18n('menu.onboarding'),
      subLabel: i18n('menu.my-onboarding-text'),
      onClick: () => window.location = '/admin/onboardings',
      type: 'featureLink',
    });

    /* CUSTOMER HAS LEARNINGPATHS */
    access.learning && menuData[0].items.push({
      label: i18n('menu.maps'),
      subLabel: i18n('menu.my-maps-text'),
      onClick: () => window.location = '/admin/maps',
      type: 'featureLink',
    });

    /* CUSTOMER HAS GATHERINGS */
    access.gathering && menuData[0].items.push({
      label: i18n('menu.gathering'),
      subLabel: i18n('menu.my-gathering-text'),
      onClick: () => window.location = '/admin/gathering',
      type: 'featureLink',
    });

    /* IF YOU HAVE GATHERING OR LEARNING PATH ADD RESOURCES */
    Boolean(access.gathering || access.learning) && menuData[0].items.push({
      label: i18n('menu.resource-pages'),
      subLabel: i18n('menu.resource-pages-text'),
      onClick: () => window.location = '/admin/resources',
      type: 'featureLink',
    });

    menuData[0].items.push({
      label: i18n('menu.persons'),
      subLabel: i18n('menu.persons-text'),
      onClick: () => window.location = '/admin/persons',
      type: 'featureLink',
    });

    return {
      menuLinks: [],
      adminLinks: menuData,
    };
  }
};

const Menu = ({onLogoutClick}) => {
  const history = useHistory();

  const profileData = useSelector(getProfileData);
  const isManager = useSelector(getIsManager);

  const configObject = useSelector(getConfigObject);
  const menuConfig = configObject.getProperty('params.menu');

  const selectedCourseGroupId = useSelector(getSelectedCompetencegroupId);
  const isMobileCourseCatalog = useSelector(getIsMobileCourseCatalog);

  const access = useMemo(
    () =>
      getAccess(configObject.getProperty('params.customerType') && configObject.getProperty('params.customerType').split('') || ['2', '10'], configObject.getProperty('params.access'))
    , [configObject],
  );

  const showDashboard  = configObject.isModuleEnabled('reports');
  const useMap = configObject.isMapActivated;

  const {ids: worklistIds} = useSelector(getWorklistSelector);
  const worklistCount = worklistIds.length;

  const location = useLocation();

  const orgId = useSelector(getOrganisationId);
  const personId = useSelector(getProfileId);
  const messagesCount = useSelector(getMessageUnreadCount);
  const isMobile = useSelector(getIsMobile);

  // const menuLinks = useMap ? null : isManager ? menuLinksManager : menuLinksEmployee;

  const {menuLinks, adminLinks} = useMemo(() => getMenuItems({
    configObject,
    history,
    access,
    isManager,
    orgId,
    showDashboard,
    location,
    worklistCount,
  }), [
    access,
    configObject,
    history,
    orgId,
    location,
    showDashboard,
    isManager,
    worklistCount,
  ]);
  /*
  const setDocumentTitle = links =>
    links
      && links.map(({title, active, children}) => {
        if (active && title) {
          document.title = title + (appTitle ? ` - ${appTitle}` : '');
        }

        return setDocumentTitle(children);
      })
    ;
*/
  // setDocumentTitle(menuLinksManager);

  const colorConfigForGroup = useMemo(() => getColorConfigForGroup({
    courseGroupId: selectedCourseGroupId,
    pathname: location.pathname,
    isMobile: isMobileCourseCatalog,
    configObject,
    forMenu: true,
  }), [selectedCourseGroupId, location.pathname, isMobileCourseCatalog, configObject]);

  const enableEditProfile = configObject.getProperty('routes.profile.enable');

  const menuData = useMemo(() => {
    if (menuConfig?.type === 'menu-two') return null;

    if (menuConfig?.type === 'menu-default' && useMap) {
      return {
        config: {
          align: 'center',
          width: 1500,
          height: '70px',
          primaryColor: menuConfig.color,
          dropdownWidth: 800,
          dropdownAlign: 'center',
        },
        buttons: [{
          label: i18n('menu.logout'),
          onClick: onLogoutClick,
          icon: 'fas door-open',
          type: 'simpleButton',
        }],
        logo: {src: menuConfig.logo},
        items: menuConfig.access && menuConfig.access >= 2
          ? adminLinks
          : null,
      };
    }

    return !profileData
      ? {config: {primaryColor: '#f0f0f0'}}
      : {
        config: {
          height: '70px',
          primaryColor: menuConfig.color,
        },
        search: isManager
          ? {
            placeholder: i18n('globals.search'),
            search: (searchTerm, context) => ({
              result: <SearchBar
                term={searchTerm}
                showMenu={context.closeMenu}
              />,
            }),
          }
          : null,
        logo: {
          src: menuConfig.logo && menuConfig.logo || '/images/snapper.svg',
          href: '/',
        },
        items: isMobile ? menuLinks : adminLinks,
        profile: {
          displayName: profileData.fullname,
          notification: messagesCount,
          items: [
            {
              label: i18n('person.menu.competences'),
              subLabel: i18n('person.menu.competencesTxt'),
              onClick: () => {
                history.push(getRouteWithParams(myEducation.cvView.path, {personId}));
              },
              type: 'featureLink',
            },
            ...enableEditProfile
              ? [{
                label: i18n('person.menu.profile'),
                subLabel: i18n('person.menu.profileTxt'),
                onClick: () => {
                  history.push(getRouteWithParams(myEducation.profileView.path, {personId}));
                },
                type: 'featureLink',
              }]
              : [],
            {
              label: i18n('person.menu.messages'),
              subLabel: messagesCount && i18n('person.menu.messagesTxtUnread', {functionArgs: {x: messagesCount}}) || i18n('person.menu.messagesTxt'),
              onClick: () => {
                history.push(getRouteWithParams(myEducation.messagesView.path, {personId}));
              },
              type: 'featureLink',
            },
            {type: 'separator'},
            ...helpPagesUrl
              ? [
                {
                  label: i18n('person.menu.help'),
                  subLabel: i18n('person.menu.helpTxt'),
                  onClick: () => {
                    history.push(getRouteWithParams(help.startpage, {}));
                  },
                  type: 'featureLink',
                },
              ]
              : [],
            {
              label: i18n('person.menu.logout'),
              subLabel: i18n('person.menu.logoutTxt'),
              onClick: () => {
                history.push('/logout');
              },
              type: 'featureLink',
            },
          ],
        },
      };
  }, [
    profileData,
    menuConfig,
    enableEditProfile,
    useMap,
    onLogoutClick,
    isManager,
    messagesCount,
    history,
    personId,
    adminLinks,
    menuLinks,
    isMobile,
  ]);

  return (
    <div css={css`display: contents;`}>
      {menuConfig?.type === 'menu-two' && (
        <MenuTwo
          onLogoutClick={onLogoutClick}
          variant={isMobile ? 'hamburger' : 'horizontal'}
          createGradient={colorConfigForGroup.createGradient}
          baseColor={colorConfigForGroup.color}
          {...menuConfig || {}}
        />
      ) || menuConfig?.type === 'menu-default' && useMap && (
        <DesktopMenu
          orgId={orgId}
          history={history}
          isMobile={isMobile}
          data={menuData}
          menuLinks={null}
          onLogoutClick={onLogoutClick}
        />
      ) || (
        <DesktopMenu
          orgId={orgId}
          isMobile={isMobile}
          data={menuData}
          menuLinks={menuLinks}
          onLogoutClick={onLogoutClick}
        />
      )}
    </div>
  );
};

Menu.defaultProps = {
  personId: -1,
  orgId: -1,
  messagesCount: 0,
};

Menu.propTypes = {
  onLogoutClick: PropTypes.func.isRequired,
  orgId: PropTypes.number,
  personId: PropTypes.number,
};

export default Menu;
