import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {NavLink} from 'react-router-dom';
import {css, useTheme} from '@emotion/react';
import {Menu as SnapperMenu} from '@snapper/menu/dist/lib';
import './desktop-menu.scss';

const DesktopMenu = ({
  menuLinks,
  onLogoutClick,
  data,
  isMobile,
  history,
}) => {
  const theme = useTheme();

  return (
    <div className="desktop-menu">
      <SnapperMenu
        history={history}
        data={
          {
            ...data,
            isMobile,
          }
        }
        className="desktop-menu-wrapper"
        css={css`min-height: 70px;justify-content: center!important;flex-basis: auto !important;`}
      />
      {!isMobile && menuLinks && (
        <div className="desktop-menu__bottom-section">
          {menuLinks.map(({name, path, active, exact = false}, index) => (
            <NavLink
              className={classNames('desktop-menu__link', {'desktop-menu__link--active': active})}
              activeClassName="desktop-menu__link--active"
              exact={exact}
              data-testid={`menu-link-to-${path}`}
              style={{borderBottom: active ? `2px solid ${theme.colors.menu.header}` : '0'}}
              to={path}
              strict={false}
              key={index}
            >
              {name}
            </NavLink>
          ))}
        </div>
      )}
    </div>
  );
};

DesktopMenu.propTypes = {
  menuLinks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onLogoutClick: PropTypes.func.isRequired,
};

export default DesktopMenu;
