import * as componentActions from '@actions/components.actions';
import {LoadStatuses} from '@types/load.types';

const initialState = {
  courseCatalog: {
    state: {
      activeView: 'catalog',
      activeSorting: 'title-asc',
      selectedTopLevelCompetencegroup: null,
    },
    layout: {
      status: LoadStatuses.NOT_LOADED,
      config: {},
    },
  },
};

const components = (state = initialState, action) => {
  switch (action.type) {
  case componentActions.COMPONENTS_SET_COURSE_CATALOG_STATE:
    if (!action.payload || typeof action.payload !== 'object') return state;

    return {
      ...state,
      courseCatalog: {
        ...state.courseCatalog,
        state: {
          ...state.courseCatalog.state,
          ...action.payload,
        },
      },
    };
  case componentActions.COMPONENTS_SET_COURSE_CATALOG_LAYOUT_REQUEST:
    if (!action.payload || typeof action.payload !== 'object') return state;

    return {
      ...state,
      courseCatalog: {
        ...state.courseCatalog,
        layout: {
          ...state.courseCatalog.layout,
          status: LoadStatuses.IS_LOADING,
        },
      },
    };
  case componentActions.COMPONENTS_SET_COURSE_CATALOG_LAYOUT_SUCCESS:
    if (!action.payload || typeof action.payload !== 'object') return state;

    return {
      ...state,
      courseCatalog: {
        ...state.courseCatalog,
        layout: {
          ...state.courseCatalog.layout,
          status: LoadStatuses.LOADED,
          config: action.payload.config,
        },
      },
    };
  case componentActions.COMPONENTS_SET_COURSE_CATALOG_TOPLEVEL_COMPETENCEGROUP:
    if (!action.payload || typeof action.payload !== 'object') return state;

    return {
      ...state,
      courseCatalog: {
        ...state.courseCatalog,
        state: {
          ...state.courseCatalog.state,
          selectedTopLevelCompetencegroup: action?.payload?.competencegroupId || null,
        },
      },
    };
  case 'RESET_STORE':
    return initialState;
  default:
    return state;
  }
};

export default components;
