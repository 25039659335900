import produce from 'immer';
import {LoadStatuses} from '@types/load.types';
import {BASE, FAILURE, LOADED_PARTIAL, REQUEST, SUCCESS} from '../util/constants';
import {camel2Snake, isObjectWithKeys, isUpperCase, snake2Camel} from '../util/misc';
import {update} from '../util/updateFetchstate';
import {createAction, createReduxActions as createReduxActionCreators} from './createActions';

const RESET_STORE = 'RESET_STORE';

const DEFAULT_ACTION_TYPES = [REQUEST, SUCCESS, FAILURE, LOADED_PARTIAL];

const updateState = loadStatusType => (draftState, action) => update(draftState, action, loadStatusType);

const updateIsLoading = updateState(LoadStatuses.IS_LOADING);
const updateIsFailed = updateState(LoadStatuses.FAILED);
const updateIsLoadedPartial = updateState(LoadStatuses.LOADED_PARTIAL);
const updateIsLoaded = updateState(LoadStatuses.LOADED);

const NOP = () => {};

function getUpdateFuncFetchstateSubtype(subType) {
  const _subType = camel2Snake(subType).toUpperCase();

  switch (_subType) {
  case REQUEST: {
    return updateIsLoading;
  }
  case SUCCESS: {
    return updateIsLoaded;
  }
  case LOADED_PARTIAL: {
    return updateIsLoadedPartial;
  }
  case FAILURE: {
    return updateIsFailed;
  }
  default: {
    return NOP;
  }
  }
};

function createWrappedReducer({
  stateKey,
  actionSubType,
  updateFn,
  isFetchstate,
}) {
  const defaultUpdateFn = isFetchstate
    ? getUpdateFuncFetchstateSubtype(actionSubType)
    : null;

  if (updateFn && defaultUpdateFn) {
    return stateKey
      ? (draftState, action) => {
        defaultUpdateFn(draftState[stateKey], action);
        updateFn(draftState[stateKey], action);
      }
      : (draftState, action) => {
        defaultUpdateFn(draftState, action);
        updateFn(draftState, action);
      };
  }

  if (updateFn) {
    return stateKey
      ? (draftState, action) => {
        updateFn(draftState[stateKey], action);
      }
      : (draftState, action) => {
        updateFn(draftState, action);
      };
  }

  return stateKey
    ? (draftState, action) => {
      defaultUpdateFn(draftState[stateKey], action);
    }
    : defaultUpdateFn;
};

const allPropertiesAreNull = obj => Object.values(obj).every(val => val === null);

var _baseReducers = {};
var _curriedReducers = {};

function createReducer(initialState, prefix) {
  if (!prefix || !_baseReducers[prefix]) {
    return () => initialState;
  }

  // const prefixReducer = _baseReducers[prefix];

  if (!isObjectWithKeys(_baseReducers[prefix])) {
    return () => initialState;
  }

  return (state = initialState, action) => {
    if (!action || !action.type) {
      return state;
    }

    const {type} = action;

    if (type.startsWith(prefix) && _curriedReducers[type]) {
      return _curriedReducers[type](state, action);
    }

    if (type === RESET_STORE) {
      return initialState;
    }

    if (!type.startsWith(prefix)) {
      return state;
    }

    if (!_baseReducers[prefix][type]) {
      return state;
    }

    _curriedReducers[type] = (_state = state, action) => produce(_state, draftState => {
      _baseReducers[prefix][type](draftState, action);
    });

    return _curriedReducers[type](state, action);
  };
};

export function createReducerAndActions({
  prefix,
  actions = {},
  initialState = {},
  keepActionNames = false,
}) {
  if (!prefix) {
    throw new Error('prefix is required');
  }

  if (!isObjectWithKeys(actions)) {
    throw new Error('No actions provided');
  }

  const _initialState = initialState || {};

  const _actions = {};
  const actionNames = [...Object.keys(actions)];

  _baseReducers[prefix] = {};

  for (const actionName of actionNames) {
    const actionMatcher = actions[actionName];

    if (actionMatcher == null) {
      _actions[actionName] = createAction(keepActionNames ? actionName : `${prefix}/${actionName}`);

      continue;
    }

    const {
      stateKey,
      actionTypes,
      reducer: extraReducer,
      ...rest
    } = actionMatcher;

    if (isObjectWithKeys(actionMatcher) && allPropertiesAreNull(actionMatcher)) {
      const subTypes = [...Object.keys(actionMatcher)];

      const actionCreators = createReduxActionCreators({
        prefix,
        actionName,
        keepNames: keepActionNames,
        subTypes,
      });

      _actions[actionName] = actionCreators.BASE;

      Object.keys(actionCreators).forEach(actionSubType => {
        const actionCreator = actionCreators[actionSubType];

        if (actionSubType !== BASE) {
          const subType = isUpperCase(actionSubType) ? snake2Camel(actionSubType) : actionSubType;

          _actions[actionName][subType] = actionCreator;
        }
      });

      continue;
    }

    if (stateKey && !isObjectWithKeys(_initialState[stateKey])) {
      throw new Error('If stateKey is provided, initialState[stateKey] must be an object. '
      + `Received: ${_initialState[stateKey]} for stateKey: ${stateKey}. '
      + 'Remove stateKey or provide initialState[stateKey] for action ${actionName}`);
    }

    const initialStateForKey = stateKey
      ? _initialState[stateKey]
      : _initialState;

    const isFetchstate = initialStateForKey.__isFetchstate;

    let _extraReducer, extraReducerIsFunction;

    if (extraReducer == null) {
      if (typeof actionMatcher === 'function') {
        _extraReducer = actionMatcher;
        extraReducerIsFunction = true;
      } else {
        _extraReducer = isObjectWithKeys(rest) ? rest : null;
      }
    } else {
      _extraReducer = extraReducer;
    }

    if (extraReducerIsFunction && actionTypes != null) {
      throw new Error('"actionTypes" must be null when argument for "reducer" is of type function.\n'
      + 'Remove "actionTypes" or change "reducer" to an object with keys matching the action types.');
    }

    const _actionTypes = actionTypes == null && isFetchstate && !extraReducerIsFunction
      ? DEFAULT_ACTION_TYPES
      : Array.isArray(actionTypes)
        ? actionTypes
        : !isFetchstate || extraReducerIsFunction
          ? [BASE]
          : [actionTypes];

    const extraReducerIsObject = isObjectWithKeys(_extraReducer);

    if (extraReducerIsObject) {
      for (const actionType of Object.keys(_extraReducer)) {
        if (!_actionTypes.includes(actionType)) {
          _actionTypes.push(actionType);
        }
      }
    }

    const actionCreators = createReduxActionCreators({
      prefix,
      actionName,
      subTypes: _actionTypes,
      keepNames: keepActionNames,
      ...stateKey ? {meta: {stateKey}} : {},
    });

    const subTypesActionMatchers = _extraReducer == null
      ? null
      : extraReducerIsObject
        ? _extraReducer
        : extraReducerIsFunction
          ? {[BASE]: _extraReducer}
          : null;

    _actions[actionName] = actionCreators.BASE;

    Object.keys(actionCreators).forEach(actionSubType => {
      const actionCreator = actionCreators[actionSubType];

      if (actionSubType !== BASE) {
        const subType = isUpperCase(actionSubType) ? snake2Camel(actionSubType) : actionSubType;

        _actions[actionName][subType] = actionCreator;
      }

      const updateFn = subTypesActionMatchers == null
        ? null
        : subTypesActionMatchers[actionSubType];

      if (!updateFn && !isFetchstate) {
        return;
      }

      const fullType = actionCreator.toString();

      _baseReducers[prefix][fullType] = createWrappedReducer({
        stateKey,
        actionSubType,
        updateFn,
        isFetchstate,
      });
    });
  }

  const _reducer = createReducer(_initialState, prefix);

  return {
    reducer: _reducer,
    actions: _actions,
  };
};
