import dayjs from 'dayjs';
import produce from 'immer';
import {createSelector} from 'reselect';
import {faRoute} from '@fortawesome/pro-light-svg-icons/faRoute';
import {faTachometerAltFast} from '@fortawesome/pro-light-svg-icons/faTachometerAltFast';
import {faUserTag} from '@fortawesome/pro-light-svg-icons/faUserTag';
import {i18n} from '@src/i18n';
import {LoadStatuses} from '@types/load.types';
import {initialState} from '../initial-state/employees.initial-state';

const emptyArr = [];

export const selectEmployees = ({employees}) => employees || initialState;

export const getIsEmployeesSagasInjected = createSelector(selectEmployees, employees => employees.sagasInjected || false);

export const getWorklistSelector = createSelector(
  selectEmployees,
  ({worklist}) => worklist,
);

export const getIsSaving = createSelector(
  selectEmployees,
  ({saving}) => saving.isSaving,
);

export const getEmployeesEventsData = createSelector(
  selectEmployees,
  ({events}) => events?.data,
);

export const getEmployeesExpiring = createSelector(
  selectEmployees,
  ({expired}) => expired,
);

export const getSelectedOrganisation = createSelector(
  selectEmployees,
  ({organisation}) => organisation,
);

export const getTree = createSelector(
  selectEmployees,
  ({tree}) => tree,
);

export const getNormalizedEmployeesEvents = createSelector(
  selectEmployees,
  ({normalizedData: {events}}) => events,
);

export const getNormalizedEmployees = createSelector(
  selectEmployees,
  ({list}) => list,
);

export const getEmployeesChecklists = createSelector(
  selectEmployees,
  ({checklists}) => checklists,
);

export const _getSelectedPerson = createSelector(
  selectEmployees,
  ({selectedPerson}) => selectedPerson,
);

const getSelectedPersonData = createSelector(
  _getSelectedPerson,
  ({data}) => data || {},
);

export const getSelectedPersonEvents = createSelector(getSelectedPersonData, ({events}) => events);

export const getSelectedPersonUsername = createSelector(
  getSelectedPersonData,
  ({user_name}) => user_name,
);

export const getSelectedPersonId = createSelector(
  getSelectedPersonData,
  ({person_id}) => person_id,
);

export const getIsEmployeesLoaded = createSelector(
  selectEmployees,
  ({list: {status}}) => status === LoadStatuses.LOADED,
);

export const getEmployeesStatistics = createSelector(
  selectEmployees,
  ({statistics}) => statistics,
);

export const getFilteredEmployees =  createSelector(
  selectEmployees,
  ({filteredList}) => filteredList,
);

export const getReport = createSelector(
  selectEmployees,
  ({report}) => report,
);

export const getSelectedPersonReport = createSelector(
  _getSelectedPerson,
  ({report}) => report,
);

export const getIsFetchingFunctions = createSelector(
  selectEmployees,
  ({functions: {isFetching} = {}}) => isFetching,
);

export const getEmployees = createSelector(
  getNormalizedEmployees,
  getEmployeesChecklists,
  (employees, checklists) => {
    const {allIds} = employees;

    if (!allIds?.length || checklists?.data?.length) {
      return employees;
    }

    const result = produce(employees, draft => {
      draft.data.forEach(employee => {
        const pendingChecklistsLength = checklists.data.filter(cl => cl.person_id === employee.person_id).length || 0;

        employee.pending_checklists = pendingChecklistsLength;
        draft.byId[employee.person_id].pending_checklists = pendingChecklistsLength;
      });
    });

    return result;
  },
);

export const getSelectedPerson = createSelector(
  _getSelectedPerson,
  selectedPerson => {
    if (!selectedPerson?.data?.roles?.length) return selectedPerson;

    return {
      ...selectedPerson,
      data: selectedPerson.data && {
        ...selectedPerson.data,
        roles: Array.isArray(selectedPerson.data.roles) && selectedPerson.data.roles.map(role => ({
          ...role,
          taskdone: Array.isArray(role.competences)
            && role.competences.length
            && role.competences.reduce(
              (taskdone, {passed}) => taskdone + (passed === 100 && 1 || 0),
              0,
            )
          || 0,
          tasks: role.competences.length,
          progress: Array.isArray(role.competences)
          && role.competences.length
          && role.competences.reduce(
            (progress, {passed}) => progress + (passed || 0),
            0,
          ) / role.competences.length / 100
          || 0,
          competences: Array.isArray(role.competences)
          && role.competences.map(competence => ({
            id: competence.id,
            passed: competence.passed,
            date: competence.date ? dayjs(competence.date) : null,
            title: competence.competence.title,
            competenceType: competence.competence.competence_type,
          })),
        })),
      },
    };
  },
);

export const getStatisticsKindsForView = createSelector(
  getEmployeesStatistics,
  getIsFetchingFunctions,
  (statistics, isFetching) => {
    if (!statistics?.data || isFetching) {
      return emptyArr;
    }

    const stats = [
      {
        id: 'dashboard',
        data: {},
        icon: faTachometerAltFast,
        name: i18n('employees.report-search'),
        gotoName: '/dashboard/search',
      },
    ];

    stats.push({
      id: 'totalt',
      data: statistics.data.progress,
      name: i18n('employees.total'),
      gotoName: i18n('globals.goto-x', {functionArgs: {x: i18n('employees.total')}}),
    });

    statistics.data.functions.forEach(item => {
      stats.push({
        id: item.role_id,
        data: item.progress,
        name: item.title,
        gotoName: i18n('globals.goto-x', {functionArgs: {x: item.title}}),
      });
    });

    stats.push(
      {
        id: 'role',
        data: {},
        name: i18n('employees.report-roles'),
        gotoName: i18n('globals.goto-x', {functionArgs: {x:  i18n('employees.report-roles')}}),
        icon: faUserTag,
      },
      {
        id: 'competence',
        data: {},
        name: i18n('employees.report-competences'),
        gotoName: i18n('globals.goto-x', {functionArgs: {x:  i18n('employees.report-competences')}}),
        icon: faRoute,
      },
    );

    return stats;
  },
);

const emptyFunctions = {
  position: [],
  role: [],
};

export const getFunctions = createSelector(
  selectEmployees,
  ({functions}) => functions?.data?.length
    ? functions.data.reduce(
      (acc, cur) => {
        const {rolemetatype} = cur;
        const types = ['position', 'role'];

        types.forEach(type => {
          if (rolemetatype === type) {
            acc[type].push(cur);
          }
        });

        return acc;
      },
      {
        position: [],
        role: [],
      },
    )
    : emptyFunctions,
);
