import {css, jsx} from '@emotion/react';
import styled from '@emotion/styled';
import {rgba} from 'polished';

const colors = {
  borderGray:'#C2C2C2',
  borderPurple: '#4F31E4',
  placeholderGray:'#757575',
  textGray:'#424242',
  errorBorderRed: '#E00000;',
  errorTextRed:'#E00000',
  hoverBorderBlue:'#044DBA',
  focusBorderBlue: '#044DBA',
  focusShadowBlue:'#B6CFF3',
  purple:'#4F31E4',
  disabledGray:'#E0E0E0',
};

export const StyledRadioInput = styled.input`

`;

export const StyledCheckInput = styled.input`

`;

const inputElemenetStyle = props => css`
    border: 1px solid ${colors.borderGray};
    border-radius: 4px;
    padding: 0.5em 0.75em;
    color: ${colors.textGray};
    width: 100%;

    ${({empty}) => empty && css`
        color: ${colors.placeholderGray};
    `}

    &:not(:focus):not(:disabled){
        filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.12));
    }
    &:hover{
        border: 1px solid ${colors.hoverBorderBlue};
    }
    &:focus{
        border: 1px solid ${colors.focusBorderBlue};
        box-shadow: 0 0 0 2px ${colors.focusShadowBlue};
    }
    &:disabled{
        background: #EDEDED;
        border: 1px solid ${colors.disabledGray};
        color: ${colors.disabledGray};
    }

    ${props => props.error
        && css`
            border: 1px solid ${colors.errorBorderRed};
            filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.12));

            &:hover{
                border: 1px solid ${colors.errorBorderRed};
                filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.12));
            }

            &:focus{
                border: 1px solid ${colors.errorBorderRed};
                box-shadow: 0 0 0 2px #FFD7D7;
            }
        `
    }
`;

export const StyledInput = styled.input`
    border: 1px solid ${colors.borderGray};
    border-radius: 4px;
    color: ${colors.textGray};
    width: 100%;

    /*Ensures letters such as Å dont get cut off*/
    line-height: 1;

    padding: 0.44em 0.75em;

    ${({empty}) => empty && css`
        color: ${colors.placeholderGray};
    `}

    &:not(:focus):not(:disabled){
        filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.12));
    }
    &:hover{
        border: 1px solid ${colors.hoverBorderBlue};
    }
    &:focus{
        border: 1px solid ${colors.focusBorderBlue};
        box-shadow: 0 0 0 2px ${colors.focusShadowBlue};
    }
    &:disabled{
        background: #EDEDED;
        border: 1px solid ${colors.disabledGray};
        color: ${colors.disabledGray};
    }

    ${props => props.error
        && css`
            border: 1px solid ${colors.errorBorderRed};
            filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.12));

            &:hover{
                border: 1px solid ${colors.errorBorderRed};
                filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.12));
            }

            &:focus{
                border: 1px solid ${colors.errorBorderRed};
                box-shadow: 0 0 0 2px #FFD7D7;
            }
        `
    }
`;


export const StyledTextArea = styled.textarea`
    border: 1px solid ${colors.borderGray};
    border-radius: 4px;
    color: ${colors.textGray};
    width: 100%;
    padding: 0.44em 0.75em;
    padding-top: .75em;
    line-height: 120%;
    ${({empty}) => empty && css`
        color: ${colors.placeholderGray};
    `}
    min-height: 6em;
    &:not(:focus):not(:disabled){
        filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.12));
    }
    &:hover{
        border: 1px solid ${colors.hoverBorderBlue};
    }
    &:focus{
        border: 1px solid ${colors.focusBorderBlue};
        box-shadow: 0 0 0 2px ${colors.focusShadowBlue};
    }
    &:disabled{
        background: #EDEDED;
        border: 1px solid ${colors.disabledGray};
        color: ${colors.disabledGray};
    }

    ${props => props.error
  && css`
            border: 1px solid ${colors.errorBorderRed};
            filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.12));

            &:hover{
                border: 1px solid ${colors.errorBorderRed};
                filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.12));
            }

            &:focus{
                border: 1px solid ${colors.errorBorderRed};
                box-shadow: 0 0 0 2px #FFD7D7;
            }
        `
}
`;


export const StyledLabel = styled.label`
    display: block;
    font-weight: 500;
    margin: 0;

    ${({error}) => error
        && css`
            color: ${colors.errorTextRed};
        `
    }

    ${({disabled}) => disabled
        && css`
            color: ${colors.disabledGray};
        `
    }
`;

export const StyledErrorMsg = styled.p`
    color: ${colors.errorTextRed};
    font-size: 0.85em;
    margin-top: 0.4em;
    font-weight: 400;
`;

export const StyledHelpText = styled.p`
   color: ${colors.textGray};
   margin-top: 0.5em;
    ${({disabled}) => disabled
        && css`
            color: ${colors.disabledGray};
        `
    }
`;

export const DatePickerWrapper = styled.div`
  ${({isMobile}) => isMobile && css`
      .rw-calendar-popup{
          width: 88vw;
          max-width: 370px;
      }
  `}
  .rw-widget-picker{
      border: none;
      overflow: visible;

  }
  input{
    border: 1px solid ${colors.borderGray};
    border-radius: 4px;
    padding: 0.5em 0.75em;
    color: ${colors.textGray};
    width: 100%;

    ${({empty}) => empty && css`
        color: ${colors.placeholderGray};
    `}

    &:not(:focus):not(:disabled){
        filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.12));
    }
    &:focus{
        border: 1px solid ${colors.focusBorderBlue};
        box-shadow: 0 0 0 2px ${colors.focusShadowBlue};
    }
    &:disabled{
        background: #EDEDED;
        border: 1px solid ${colors.disabledGray};
        color: ${colors.disabledGray};
    }

    ${props => props.error
        && css`
            border: 1px solid ${colors.errorBorderRed};
            filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.12));

            &:hover{
                border: 1px solid ${colors.errorBorderRed};
                filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.12));
            }

            &:focus{
                border: 1px solid ${colors.errorBorderRed};
                box-shadow: 0 0 0 2px #FFD7D7;
            }
        `
    || css`
        &:hover{
            border: 1px solid ${colors.hoverBorderBlue};
        }
    `}

    line-height: 1.15;
  }
`;

export const InputWrapper = styled.div`
    margin-right: 2em;
    margin-bottom: 1.5em;

    ${({verticalSpacing}) => verticalSpacing === 'large' &&  css`
        margin-bottom: 3em;
    `}

    ${({horizontalSpacing}) => horizontalSpacing === 'medium' &&  css`
        margin-right: 1.5em;
    `}

    ${StyledLabel}{
        margin-bottom: 0.72em;
    }

`;

export const CheckInputWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 0.7em;
    ${StyledLabel}{
        order: 2;
        font-weight: normal;
        margin-left: 0.6em;
        margin-bottom: 0;
    }
    ${StyledCheckInput}{
        margin-left: 20px;
    }
`;

export const RadioInputWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    margin-left: 12px;
    ${StyledLabel}{
        order: 2;
        margin-left: 0.6em;
        margin-bottom: 0;
    }
    ${StyledRadioInput}{
        opacity: 0;
        width: 0;
        height: 0;
    }
`;

export const StyledRadioDot = styled.div`
  border-radius: 60%;
  height: 60%;
  width: 60%;
  background: ${colors.purple};

    ${({disabled}) => disabled && css`
        background: #C2C2C2;
        `}
`;

export const CustomStyledRadio = styled.div`
   border-radius: 50%;
   border: 1.1px solid ${colors.borderGray};

   width: 1.3em;
   height: 1.3em;
   display: flex;
    align-items: center;
    justify-content: center;

   ${ ({checked}) =>
  css`
        ${StyledRadioDot}{
            opacity: ${checked ? '1' : '0'};
        }
    `
    }

  &:hover {
    border: 1px solid ${colors.purple};
  }
  &:focus {
    border: 1px solid ${colors.purple};
    box-shadow: 0 0 0 3px ${rgba(colors.purple, 0.2)};
  }

  ${({disabled}) => disabled
        && css`
            color: red;
            border: 1.1px solid ${colors.disabledGray};
            &:hover,&:focus{
                border: 1.1px solid ${colors.disabledGray};
                box-shadow: none;
            }
        `
    }
`;
