import {useEffect, useRef} from 'react';
import {useStore} from 'react-redux';
import {applyMiddleware, combineReducers, compose, createStore} from 'redux';
import {reducer as formReducer} from 'redux-form';
import {
  createResponsiveStateReducer,
  responsiveStoreEnhancer,
} from 'redux-responsive';
import createSagaMiddleware from 'redux-saga';
import {all} from 'redux-saga/effects';
import thunk from 'redux-thunk';
import {coursesReducerImmer} from '@actions/courses.actions';
import {profileImmerReducer} from '@actions/profile.actions';
import alertReducer from '@reducers/alert.reducer';
import authReducer from '@reducers/auth.reducer';
import cmsReducer from '@reducers/cms.reducers';
import componentsReducer from '@reducers/components.reducer';
import configReducer from '@reducers/config.reducers';
import helpReducer from '@reducers/help.reducer';
import manageReducer from '@reducers/manage.reducer';
import mapReducer from '@reducers/map.reducers';
import messagesReducer from '@reducers/messages.reducer';
import newsReducer from '@reducers/news.reducer';
import notificationsReducer from '@reducers/notifications.reducer';
import rolesReducer from '@reducers/roles.reducer';
import themeReducer from '@reducers/theme.reducer';
import authSagas from '@sagas/auth.sagas';
import cmsSagas from '@sagas/cms.sagas';
import competencesSagas from '@sagas/competences.sagas';
import componentsSagas from '@sagas/components.sagas';
import configSagas from '@sagas/config.sagas';
import coursesSagas from '@sagas/courses.sagas';
import helpSagas from '@sagas/help.sagas';
import manageSagas from '@sagas/manage.sagas';
import mapSagas from '@sagas/map.sagas';
import messagesSagas from '@sagas/messages.sagas';
import newsSagas from '@sagas/news.sagas';
import profileSagas from '@sagas/profile.sagas';
import rolesSagas from '@sagas/roles.sagas';
import themeSagas from '@sagas/theme.sagas';
import {size} from '@styles/device';
import {DAEMON} from './store/util/injectors/constants';
import {env} from './env';

// from here https://github.com/AlecAivazis/redux-responsive
const defaultBreakpoints = {
  extraSmall: 480,
  small: 768,
  medium: 992,
  mobileCourseCatalog: Number.parseInt(
    size.mobileCourseCatalog.split('px')[0],
    10,
  ),
  large: 1200,
};

export function createReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    auth: authReducer,
    alert: alertReducer,
    courses: coursesReducerImmer,
    profile: profileImmerReducer,
    messages: messagesReducer,
    roles: rolesReducer,
    form: formReducer,
    browser: createResponsiveStateReducer(defaultBreakpoints),
    theme: themeReducer,
    manage: manageReducer,
    notifications: notificationsReducer,
    news: newsReducer,
    config: configReducer,
    cms: cmsReducer,
    map: mapReducer,
    components: componentsReducer,
    help: helpReducer,

    // employees: employeesReducerImmer, // to be lazy loaded
    // admin: adminReducers, // to be lazy loaded

    ...injectedReducers,
  });

  // this special reducer allows for an action to replace the entire store* (see bellow)
  // this is useful for mocking when testing for example
  // all other actions than this are sent to the normal reducer as usual
  function functionReducerWithReplaceState(state, action) {
    if(process.env && process.env.NODE_ENV !== 'production') {
      switch (action.type) {
      case 'TESTING_REPLACE_STATE':
        return {
          ...action.payload.state,
          auth: {...state.auth}, // contains stuff like session id
          config: {...state.config}, // also contains a function - not serializable
          browser: {...state.browser}, // contains info for curr screen size
        };
      default:
        return rootReducer(state, action);
      }
    } else{
      return rootReducer(state, action);
    }
  }

  return functionReducerWithReplaceState;
}

export const employeesModule = async () => {
  const reducer = import(/* webpackChunkName: 'employees-reducer' */ '@actions/employees.actions').then(module => module.default);
  const saga = import(/* webpackChunkName: 'employees-sagas' */ '@sagas/employees.sagas').then(module => module.default);

  const [reducerModule, sagaModule] = await Promise.all([reducer, saga]);

  return {
    reducer: reducerModule,
    saga: sagaModule,
  };
};

export const adminTracksModule = async () => {
  const reducer = import(/* webpackChunkName: 'admin-reducer' */ '@reducers/admin.reducers').then(module => module.default);
  const saga = import(/* webpackChunkName: 'admin-sagas' */ '@admin/store/sagas').then(module => module.default);

  const [reducerModule, sagaModule] = await Promise.all([reducer, saga]);

  return {
    reducer: reducerModule,
    saga: sagaModule,
  };
};

const modules = {
  employees: employeesModule,
  admin: adminTracksModule,
};

function getStoreModule(moduleName) {
  const module = modules[moduleName];

  if (!module) return null;

  return module();
}

function injectModuleFactory(store) {
  return async function injectModule(key, callback) {
    if (store.injectedModules[key] || store.injectingCurrent[key]) return;
    store.injectingCurrent[key] = true;

    const _module = await getStoreModule(key);

    if (!_module) return;

    const {reducer, saga} = _module;

    const awaitInject = [];

    if (saga) {
      awaitInject.push(store.injectSaga(key, {
        saga,
        mode: DAEMON,
      }));
    }

    if (reducer) {
      awaitInject.push(store.injectReducer(key, reducer));
    }

    await Promise.all(awaitInject);

    store.injectedModules[key] = true;

    if (callback) callback();
  };
};


function injectReducerFactory(store, isValid) {
  return async function injectReducer(key, reducer) {
    if (
      Reflect.has(store.injectedReducers, key)
      && store.injectedReducers[key] === reducer
    )
      return;

    store.injectedReducers[key] = reducer; // eslint-disable-line no-param-reassign
    await store.replaceReducer(createReducer(store.injectedReducers));
  };
}

export function injectSagaFactory(store, isValid = true) {
  return async function injectSaga(key, descriptor = {}, args = {}) {
    const newDescriptor = {
      ...descriptor,
      mode: descriptor.mode || DAEMON,
    };

    const {saga} = newDescriptor;

    const hasSaga = Reflect.has(store.injectedSagas, key);

    if (!hasSaga) {
      const task = await store.runSaga(saga, args);

      store.injectedSagas[key] = {
        ...newDescriptor,
        task,
      };
    }
  };
}

export const configureStore = initialState => {
  const sagaMiddleware = createSagaMiddleware({
    onError: error => {
      console.error(error);

      store.dispatch({
        type: 'ERROR',
        payload: error,
      });
    },
  });
  const middlewares = [thunk, sagaMiddleware];

  /* eslint no-underscore-dangle: 0 */
  const devTools
    = process.env.NODE_ENV !== 'production' && window.__REDUX_DEVTOOLS_EXTENSION__
      ? window.__REDUX_DEVTOOLS_EXTENSION__({trace: true})
      : undefined;

  const enhancer = devTools
    ? compose(
      applyMiddleware(...middlewares),
      responsiveStoreEnhancer,
      devTools,
    )
    : compose(applyMiddleware(...middlewares), responsiveStoreEnhancer);

  const store = createStore(createReducer(), initialState, enhancer);

  function* rootSaga() {
    yield all([
      ...authSagas,
      ...coursesSagas,
      ...profileSagas,
      ...manageSagas,
      ...competencesSagas,
      ...messagesSagas,
      ...rolesSagas,
      ...themeSagas,
      ...newsSagas,
      ...configSagas,
      ...cmsSagas,
      ...mapSagas,
      ...helpSagas,
      ...componentsSagas,
      // ...employeesSagas(), // lazy loaded
      // ...adminTracksSagas(), // (admin/tracks) lazy loaded
    ]);
  }

  // Extensions
  store.runSaga = sagaMiddleware.run;

  store.injectedReducers = {}; // Reducer registry
  store.injectedSagas = {}; // Saga registry
  store.injectedModules = {}; // Module registry (boolean)
  store.injectingCurrent = {}; // Module registry (boolean)

  store.injectSaga = injectSagaFactory(store, true);
  store.injectReducer = injectReducerFactory(store, true);
  store.injectModule = injectModuleFactory(store);

  store.getIsModuleInjected = key => store.injectedModules[key];
  store.getIsModuleInjecting = key => store.injectingCurrent[key];

  sagaMiddleware.run(rootSaga);

  return store;
};

export const useIsModuleInjected = key => {
  const store = useStore();

  return store.getIsModuleInjected(key);
};

export const useInjectModule = (moduleId, disabled) => {
  const store = useStore();

  const {injectModule, getIsModuleInjecting} = store;

  const isInjected = store.injectedModules[moduleId];

  const isInjecting = useRef(false);

  useEffect(() => {
    if (disabled || isInjected || isInjecting.current) return;

    const isInjectingCurrent = getIsModuleInjecting(moduleId);

    if (!isInjectingCurrent) {
      isInjecting.current = true;

      injectModule(moduleId);
    }
  }, [isInjected, moduleId, injectModule, getIsModuleInjecting, disabled]);

  return isInjected;
};
