import * as T from '@types/load.types';

export const initialState = {
  sagasInjected: false,
  reducersInjected: false,
  list: {
    isFetching: false,
    hasMore: false,
    status: T.LoadStatuses.NOT_LOADED,
    hasChildren: false,
    data: null,
    byId: {},
    allIds: [],
  },
  tree: {
    status: T.LoadStatuses.NOT_LOADED,
    data: null,
  },
  organisation: {
    status: T.LoadStatuses.NOT_LOADED,
    data: null,
  },
  organisations: {
    status: T.LoadStatuses.NOT_LOADED,
    hasMore: false,
    data: null,
  },
  filteredList: {
    isFetching: false,
    hasMore: false,
    data: null,
  },
  selectedPerson: {
    userName: null,
    isFetching: false,
    status: T.LoadStatuses.NOT_LOADED,
    elements: {
      checklists: {isFetching: false},
      events: {isFetching: false},
      positions: {isFetching: false},
      summary: {isFetching: false},
      activities: {isFetching: false},
      expiring: {
        status: T.LoadStatuses.NOT_LOADED,
        isFetching: false,
      },
    },
    data: null,
    tab: 'role',
    error: null,
    report: {isFetching: false},
  },
  statistics: {
    isFetching: false,
    status: T.LoadStatuses.NOT_LOADED,
    data: null,
    error: null,
  },
  checklists: {
    saving: {
      status: T.LoadStatuses.NOT_LOADED,
      error: null,
    },
    isFetching: false,
    data: [],
    error: null,
    status: T.LoadStatuses.NOT_LOADED,
  },
  expiring: {
    status: T.LoadStatuses.NOT_LOADED,
    isFetching: false,
    error: null,
    data: null,
  },
  extraData: {
    isFetching: false,
    error: null,
    data: null,
  },
  activities: {
    isFetching: false,
    data: null,
    error: null,
  },
  report: {
    status: T.LoadStatuses.NOT_LOADED,
    id: '',
    data: null,
    error: null,
  },
  worklist: {
    status: T.LoadStatuses.NOT_LOADED,
    data: null,
    ids: [],
    error: null,
  },
  functions: {
    isFetching: false,
    data: [],
    error: null,
  },
  saving: {
    isSaving: false,
    error: null,
  },
  events: {
    status: T.LoadStatuses.NOT_LOADED,
    isFetching: false,
    data: null,
    error: null,
  },
  eventsWaitlist: {
    isFetching: false,
    data: null,
    error: null,
  },
  searchPhraze: '',
  normalizedData: {
    employees: {
      error: null,
      status: T.LoadStatuses.NOT_LOADED,
      ids: [],
      data: {},
    },
    events: {
      error: null,
      status: T.LoadStatuses.NOT_LOADED,
      employeeIdsByEventId: {},
      employeeIdsWaitlistByEventId: {},
      employeeIdsConfirmedByEventId: {},
      eventsSortedByDate: [],
    },
  },
};
