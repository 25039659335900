import axios from 'axios';
import {all, call, put, select, takeLatest} from 'redux-saga/effects';
import {authUnauthorized} from '@actions/auth.actions';
import * as MA from '@actions/messages.actions';
import {notificationsAdd} from '@actions/notifications.actions';
import {backendUrl} from '@config';
import {i18n} from '@src/i18n';
import retry from '@utils/sagas.utils';

/*
* USED FOR POOLING FOR NEW MESSAGES.
* */
export function* checkMessages() {
  yield put(MA.messagesCheckMessagesRequest());

  try {
    const {data: {messages}} = yield retry(() =>
      axios.request({
        method: 'GET',
        params: {fields: 'ts,message_id,isread'},
        url: `${backendUrl}/api/messages?isread=1`,
        withCredentials: true,
      }));

    yield put(MA.messagesCheckMessagesSuccess({
      messages,
      notification: messages?.length,
    }));
  } catch (error) {
    console.error(error);
    yield put(MA.messagesCheckMessagesFailure({error}));
  }
}

export function* setReadMessage(action) {
  yield put(MA.messageSetReadRequest());
  try {
    const unread = yield select(state => state.messages.unread.data) || [];
    const {message_id} = action.payload.message;

    yield put(MA.messageSetReadSuccess({message_id}));

    const {data: {messages}} = yield retry(() =>
      axios.request({
        method: 'POST',
        params: {read: true},
        url: `${backendUrl}/api/messages/${message_id}`,
        withCredentials: true,
      }));

    yield put(MA.messagesCheckMessagesSuccess({
      messages,
      unread: Math.max(0, unread.length - 1),
    }));
  } catch (error) {
    console.error(error);
    yield put(MA.messageSetReadFailure({error}));
  }
}

/*
* GET ALL DATA FROM THE MESSAGES.
* */
export function* getMessages() {
  yield put(MA.messagesGetRequest());

  try {
    const {data: {messages}} = yield retry(() =>
      axios.request({
        method: 'GET',
        params: {fields: 'ts,message_id,isread,files,subject,ts,is_sms,text,sender_name'},
        url: `${backendUrl}/api/messages`,
        withCredentials: true,
      }));

    yield put(MA.messagesGetSuccess({messages}));
  } catch (error) {
    console.error(error);
    yield put(MA.messagesGetFailure({error}));
  }
}

function* sendMessage(action) {
  const {username: user_name, title, emailBody, callback} = action.payload;

  yield put(MA.messagesSendMessagePostRequest());

  try {
    yield all({
      email: call(() =>
        axios.request({
          method: 'POST',
          url: `${backendUrl}/api/messages`,
          params: {
            user_name,
            title,
            text: emailBody,
            send_medium: 'email',
          },
          withCredentials: true,
        })),
    });

    if (callback) {
      yield callback({employee: {user_name}});
    }

    yield put(MA.messagesSendMessagePostSuccess());
    yield put(notificationsAdd({
      notification: {
        text: i18n('message.message-sent-success'),
        color: 'green',
      },
    }));
  } catch (error) {
    console.error(error);
    if (error.status === 401) {
      yield put(authUnauthorized({error}));
    }
    yield put(MA.messagesSendMessagePostFailure({error}));
    yield put(notificationsAdd({
      notification: {
        text:  i18n('globals.error'),
        color: 'red',
      },
    }));
    if (callback) {
      yield callback({employee: {user_name}});
    }
  }
}

const exportObj = [
  takeLatest(MA.messagesGetMessages().type, getMessages),
  takeLatest(MA.messagesSendMessage().type, sendMessage),
  takeLatest(MA.messagesCheckMessages().type, checkMessages),
  takeLatest(MA.messageSetRead().type, setReadMessage),
];

export default exportObj;
