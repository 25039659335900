import React, {useCallback, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {
  coursesBeginSignature,
  coursesSignCourse,
} from '@actions/courses.actions';
import {ActionButton} from '@components/buttons/actionButton';
import {Checkbox} from '@components/checkbox';
import FormInput from '@components/form-input';
import Modal from '@components/modal/modal';
import {css} from '@emotion/react';
import {getConfigObject} from '@selectors/config.selectors';
import {getCurrentSignature, getSigningOnCourse} from '@selectors/courses.selectors';
import {i18n} from '@src/i18n';
import {Wrapper} from './signature-modal.styles';
import './signature-modal.scss';

const initialFormData = {
  password: '',
  checked: false,
};

const SignatureModal = React.memo(() => {
  const dispatch = useDispatch();

  const configObject = useSelector(getConfigObject);
  const signWithPassword = useMemo(() => configObject.getProperty('params.sign-with') !== 'check', [configObject]);

  const course = useSelector(getCurrentSignature);
  const courseId = course?.id;

  const [formData, setFormData] = useState(() => initialFormData);

  const handlePasswordInput = useCallback(({target: {value}}) => {
    setFormData(prev => ({
      ...prev,
      password: value,
    }));
  }, []);

  const {password, checked} = formData;
  const {isFetching: isBusy} = useSelector(getSigningOnCourse) || {};
  const readyToSign = !!courseId && !isBusy && (signWithPassword ? !!password : checked);

  const handleClose = useCallback(() => {
    setFormData(initialFormData);
    dispatch(coursesBeginSignature(null));
  }, [dispatch]);

  const handleSubmit = useCallback(() => {
    if (!readyToSign) return;

    dispatch(coursesSignCourse({
      courseId,
      password,
      checked: !signWithPassword,
    }));
  }, [dispatch, readyToSign, signWithPassword, password, courseId]);

  const toggleChecked = useCallback(() => {
    setFormData(prev => ({
      ...prev,
      checked: !prev.checked,
    }));
  }, []);

  if (!course) {
    return null;
  }

  return (
    <Modal
      onClose={handleClose}
      WrapperComponent={Wrapper}
      disableActionButtons
    >
      <div className="signature-modal">
        <div className="signature-modal__header">
          {`${course.title || course.competence_title}`}
        </div>

        {!!course.description && (
          <div css={css({padding: '1em 0'})}>
            {course.description}
          </div>
        )}

        {!signWithPassword && (
          <div css={css({padding: '1.5em 0'})}>
            <span
              css={{
                fontWeight: 600,
                fontSize: '16px',
              }}
            >
              {i18n('competence.confirm-to-sign')}
            </span>

            <Checkbox
              checked={checked}
              onClick={toggleChecked}
              viewBox="0 0 24 32"
              style={{
                marginTop: '2px',
                marginLeft: '8px',
                overflow: 'hidden',
              }}
              backgroundColor="#fff"
              backgroundColorChecked="#0070F3"
              border="1px solid #A2A3A2"
              borderChecked="1px solid #0070F3"
            />
          </div>
        )}

        <div>
          {signWithPassword && (
            <FormInput
              className="person-form__input"
              name="password"
              label={i18n('competence.password')}
              input={{}}
              value={password}
              meta={{}}
              type="password"
              id="password"
              onChange={handlePasswordInput}
            />
          )}
          <ActionButton
            key="sign"
            variant={readyToSign ? 'primary' : 'disabled'}
            disabled={!readyToSign}
            style={signWithPassword ? null : {marginTop: '0.5em'}}
            onClick={handleSubmit}
          >
            {i18n('competence.sign')}
          </ActionButton>
        </div>
      </div>
    </Modal>
  );
});

SignatureModal.propTypes = {signWithPassword: PropTypes.bool};

export default SignatureModal;
