import _get from 'lodash/get';
import {initialConfigObject} from '@reducers/config.reducers';
import * as T from '@types/load.types';

const defaultEnabledModules = new Set(['my-education', 'profile']);

// Returns a config-object with data and methods for accessing that data.
export const createConfigObject = config => {
  if (!config?.data) return initialConfigObject;

  const {data} = config;
  const routes = data?.routes || {};

  /* Get a property using dot syntax, eg a.b.c.d
    Returns undefined if the property is not found. */
  const getProperty = key => _get(data, key);

  const isModuleEnabled = moduleName => {
    if (routes[moduleName]?.disabled) return false;

    return defaultEnabledModules.has(moduleName);
  };

  const isMapActivated = isModuleEnabled('atlas') && getProperty('params.start-route');

  return {
    data,
    isMapActivated,
    isModuleEnabled,
    getProperty,
    status: T.LoadStatuses.LOADED,
  };
};
