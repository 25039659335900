import dayjs from 'dayjs';
import {getCompetenceCoverImageSrc, getDurationText} from '@routes/course-catalog/util';
import {parseEvent} from '@src/hooks/store/course/util';

export const sortEventsByDate = (a, b) => (a, b) => dayjs(a.startdate).diff(dayjs(b.startdate));

export const getYearMonthString = date => dayjs(date).format('YYYY-MM');

export const insertSingleEvent = (acc, parsedEvent) => {
  const {
    allEventIds,
    eventById,
    eventIdsByCourseId,
    eventIdsByYearMonth,
  } = acc;

  const {competence_id: courseId, id: eventId} = parsedEvent;
  const [year, month]  = getYearMonthString(parsedEvent.startDate).split('-');

  // no other events in prev state, we can return early
  if (!allEventIds.size) {
    return {
      allEventIds: new Set([eventId]),
      eventById: {[eventId]: parsedEvent},
      eventIdsByCourseId: {[courseId]: [eventId]},
      eventIdsByYearMonth: {[year]: {[month]: [eventId]}},
    };
  }

  allEventIds.add(eventId);
  eventById[eventId] = parsedEvent;

  if (!eventIdsByCourseId[courseId]) {
    eventIdsByCourseId[courseId] = [eventId];
  } else if (!eventIdsByCourseId[courseId].includes(eventId)) {
    eventIdsByCourseId[courseId].push(eventId);
  }

  const prevByYearMonth = eventIdsByYearMonth[year]?.[month] || [];

  if (prevByYearMonth.includes(eventId)) {
    return acc;
  }

  if (!eventIdsByYearMonth[year]) {
    eventIdsByYearMonth[year] = {[month]: [eventId]};
  } else if (!eventIdsByYearMonth[year][month]) {
    eventIdsByYearMonth[year][month] = [eventId];
  } else {
    eventIdsByYearMonth[year][month].push(eventId);
  }

  return acc;
};

export const reduceEventsPartialRemoval = (acc, event) => {
  const {
    allEventIds,
    eventById,
    eventIdsByCourseId,
    eventIdsByYearMonth,
  } = acc;

  const {competence_id: courseId, id: eventId} = event;

  if (!allEventIds.has(eventId)) {
    return acc;
  }

  allEventIds.delete(eventId);

  // reset object if no events left
  if (!allEventIds.size) {
    return {
      allEventIds,
      eventById: {},
      eventIdsByCourseId: {},
      eventIdsByYearMonth: {},
    };
  }

  delete eventById[eventId];

  if (eventIdsByCourseId[courseId]?.includes(eventId)) {
    eventIdsByCourseId[courseId] = eventIdsByCourseId[courseId].filter(id => id !== eventId);
  }

  const [year, month] = getYearMonthString(event.startdate || event.startDate).split('-');

  if (eventIdsByYearMonth[year]?.[month]?.includes(eventId)) {
    eventIdsByYearMonth[year][month] = eventIdsByYearMonth[year][month].filter(id => id !== eventId);
  }

  return acc;
};

export const reduceEventsPartialAdd = (acc, event) => {
  const parsedEvent = parseEvent(event);

  if (!parsedEvent) return acc;

  const {
    allEventIds,
    eventById,
  } = acc;

  const {id: eventId} = parsedEvent;

  // update event object
  eventById[eventId] = parsedEvent;

  // if id already in store, skip further sorting
  if (allEventIds.has(eventId)) {
    return acc;
  }

  return insertSingleEvent(acc, parsedEvent);
};

export const reduceEventsFullUpdate = (acc, event) => {
  const {id: eventId, competence_id: courseId} = event;
  const {allEventIds} = acc;

  if (!eventId || !courseId || allEventIds.has(eventId)) return acc;

  const parsedEvent = parseEvent(event);

  if (!parsedEvent) return acc;

  return insertSingleEvent(acc, parsedEvent);
};

export const getCompentenceMetaData = ({competence, passedIdsSet, passedCompetences}) => {
  const {id, competence_type, durations} = competence || {};

  if (!id || !competence_type || !durations) return null;

  const durationText = getDurationText(durations);

  const metaData = {
    durationText,
    ...competence_type === 'Nano Speed Course' && passedIdsSet.has(id)
      ? {
        passed: true,
        points: passedCompetences.find(({competence_id}) => competence_id === id),
      }
      : {},
  };

  return metaData;
};

export const getCompetenceCoverImage = (competence, defaultImage) => {
  const {files, content} = competence || {};

  if (!files && !content) return defaultImage;

  return getCompetenceCoverImageSrc({
    files,
    content,
  }, defaultImage);
};
