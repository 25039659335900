import React from 'react';
import {useInjectModule} from '@store';

export const ModuleWrapper = React.memo(({moduleId, disabled, component, children, renderAllways}) => {
  const moduleLoaded = useInjectModule(moduleId, disabled);

  const childrenRef = React.useRef(component || children || null);

  if (renderAllways || moduleLoaded || disabled) {
    return childrenRef.current;
  }

  if (!moduleLoaded) return null;

  return childrenRef.current;
}, () => true);

export const EmployeesModuleInjector = React.memo(() => <ModuleWrapper moduleId="employees" />, () => true);
export const AdminModuleInjector = React.memo(() => <ModuleWrapper moduleId="admin" />, () => true);
