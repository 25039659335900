import {createSelector} from 'reselect';
import * as T from '@types/load.types';

export const getConfig = ({config}) => config;

export const getConfigObject = ({config: {configObject}}) => configObject;

export const getIsConfigLoaded = createSelector(getConfigObject, ({status}) => status === T.LoadStatuses.LOADED);

export const getCourseCatalogFeatures = createSelector(getConfigObject, configObject => ({
  searchbar: configObject.getProperty('routes.course-catalog.showSearchField'),
  titleOfCategory: !configObject.getProperty('routes.course-catalog.hideTitleOfCategory'),
  courseTypeFilter: configObject.getProperty('routes.course-catalog.showCourseTypeFilter'),
  sorting: configObject.getProperty('routes.course-catalog.showSorting'),
  classromCourseDigitalCourseSwitch: configObject.getProperty('routes.course-catalog.showClassromDigitalCourseSwitch'),
}));

export const getDefaultImage = createSelector(getConfigObject, config => config.getProperty('params.default-img'));

export const getCurrTrack = createSelector(getConfigObject, configObject => {
  const track = localStorage.getItem('track') || configObject.getProperty('params.default-track');

  return track;
});

export const getCurrLanguage = createSelector(getConfigObject, configObject => {
  const language = localStorage.getItem('language')
    || configObject.getProperty('params.default-language')
    || 'no';

  return language;
});

export const getPropertiesForCurrLangAndTrackBadge = createSelector(getConfigObject, configObject => {
  if (!configObject.data) {
    return null;
  }

  const track = localStorage.getItem('track')
    || configObject.getProperty('params.default-track');

  const language
    = localStorage.getItem('language')
    || configObject.getProperty('params.default-language')
    || 'no';

  return configObject.getProperty(`routes.atlas.configByTrackAndLang.${track}.${language}.badge`);
});

export const getPropertiesForCurrLangAndTrack = createSelector(getConfigObject, configObject => {
  if (!configObject.data) {
    return null;
  }

  const track
    = localStorage.getItem('track')
    || configObject.getProperty('params.default-track');

  const language
    = localStorage.getItem('language')
    || configObject.getProperty('params.default-language')
    || 'no';

  try {
    const properties = {};
    // == atlas-config (config for the map)
    const atlasConfigByMapIdAndLang = configObject.getProperty('routes.atlas.configByTrackAndLang');
    const atlasConfigForCurrTrack
      = atlasConfigByMapIdAndLang && atlasConfigByMapIdAndLang[track] || {};

    const atlasConfigForCurrTrackAndLang
      = atlasConfigForCurrTrack && atlasConfigForCurrTrack[language] || {};

    if (atlasConfigByMapIdAndLang) {
      properties.atlas = {
        redirectAfterOutroFinished: (atlasConfigForCurrTrackAndLang.outro || {})
          .redirectAfterFinished,
      };
    }

    // == course catalog config
    const featuredTilesConfigByTrack = configObject.getProperty('routes.course-catalog.customToplevelSelector.featuredTiles.configByTrack');
    const courseCatalogConfigForCurrTrack
      = (configObject.getProperty('routes.course-catalog.configByTrack') || [])[
        track
      ] || {};
    const featuredTilesConfigForCurrTrack
      = featuredTilesConfigByTrack
        && featuredTilesConfigByTrack[track]
        && (configObject.getProperty('routes.course-catalog.customToplevelSelector.featuredTiles.configByTrack') || [])[track].featuredTiles
      || [];

    if (configObject.getProperty('routes.course-catalog.configByTrack')) {
      properties.courseCatalog = {
        ...courseCatalogConfigForCurrTrack,
        featuredTiles: featuredTilesConfigForCurrTrack,
      };
    }

    // == menu config
    const menuConfig = configObject.getProperty('params.menu');

    const menuConfigByTrack = configObject.getProperty('params.menu.configByTrack');
    const menuConfigForMapId
      = menuConfigByTrack && menuConfigByTrack[track] || {};

    const menuConfigForMapIdAndLanguage
      = menuConfigForMapId
        && menuConfigForMapId.configByLang
        && menuConfigForMapId.configByLang[language]
      || {};

    properties.menu = {
      ...menuConfig,
      ...menuConfigForMapId,
      ...menuConfigForMapIdAndLanguage,
    };

    return properties;
  } catch (error) {
    alert(`Manglende eller feil konfigurering for løype med navn ${track}`);
    console.error(`Manglende konfigurering for løype med id ${track}`);
    console.error(error);

    return {};
  }
});
