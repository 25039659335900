import axios from 'axios';
import {
  call,
  put,
  select,
  takeEvery,
} from 'redux-saga/effects';
import {
  helpGetData,
  helpGetDataFailure,
  helpGetDataRequest,
  helpGetDataSuccess,
  helpSearch,
  helpSearchFailure,
  helpSearchRequest,
  helpSearchSuccess,
  helpSendQuestion,
  helpSendQuestionFailure,
  helpSendQuestionRequest,
  helpSendQuestionSuccess,
} from '@actions/help.actions';
import {helpPagesSearchUrl, helpPagesUrl, supportUrl, tenant} from '@config';
import {getConfigObject} from '@selectors/config.selectors';
import {getProfile} from '@selectors/profile.selectors';
import {getSearchKey} from '../util/help.util';


function* getData(action) {
  const config = yield select(getConfigObject);

  yield put(helpGetDataRequest());
  const path = action.payload.path;

  try {
    const {data} = yield call(axios.request, {
      method: 'GET',
      url: `${ config.getProperty('routes.help.backend.url') || helpPagesUrl}${path}`,
    });

    yield data.valid
      ? put(helpGetDataSuccess({
        path,
        data,
      }))
      : put(helpGetDataFailure({error: data}));
  } catch (error) {
    yield put(helpGetDataFailure({error: error.message}));
  }
}


function* searchHelp({payload}) {
  yield put(helpSearchRequest());
  const query =  payload.query;
  const config = yield select(getConfigObject);

  try {
    const {data} = yield call(axios.request, {
      method: 'GET',
      url: `${ config.getProperty('routes.help.backend.searchUrl') || helpPagesSearchUrl}`,
      params: {query},
    });

    yield put(helpSearchSuccess({
      data,
      searchKey: getSearchKey({query}),
    }));
  } catch (error) {
    console.log(error);
    yield put(helpSearchFailure({error: error.message}));
  }
};

function* sendQuestion({payload}) {
  yield put(helpSendQuestionRequest());

  const profile = yield select(getProfile);

  try {
    const {data} = yield call(axios.request, {
      method: 'POST',
      url:`${supportUrl}/ticket`,
      headers:{'Content-Type': 'application/json'},
      data: JSON.stringify({
        data:{
          subject: payload.form.question,
          description: payload.form.description,
          type:'question',
        },
        requester: {
          name: profile.data.fullname,
          email: profile.data.email,
        },
        tenant,
      }),
    });

    yield put(helpSendQuestionSuccess({data}));
  } catch (error) {
    console.log(error);
    yield put(helpSendQuestionFailure({error: error.message}));
  }
};

const helpSagas = [
  takeEvery(helpGetData().type, getData),
  takeEvery(helpSearch().type, searchHelp),
  takeEvery(helpSendQuestion().type, sendQuestion),
];

export default helpSagas;
