import {createReducerAndActions} from '@src/store-normalized/setup/createDefault';
import * as T from '@types/load.types';

const initialState = {
  activeOrg: {orgId: null},
  person: {
    isFetching: false,
    data: null,
    error: null,
    updated: null,
    storeManager: false,
    orgId: null,
    organisations: null,
    tenets: {
      status: T.LoadStatuses.NOT_LOADED,
      data: null,
    },
    competencelevel: {
      status: T.LoadStatuses.NOT_LOADED,
      data: [],
      error: null,
    },
  },
  helptexts: null,
  tab: 'role',
  section: 'competences',
  selfSign: {
    data: null,
    status: T.LoadStatuses.NOT_LOADED,
  },
  competences: {
    isFetching: false,
    isSubFetching: false,
    data: null,
    error: null,
    updated: null,
  },
  menu: {
    status: T.LoadStatuses.NOT_LOADED,
    data: null,
  },
  expiring: {
    isFetching: false,
    data: null,
    updated: null,
    status: T.LoadStatuses.NOT_LOADED,
  },
  tasks: {
    status: T.LoadStatuses.NOT_LOADED,
    data: null,
  },
  tenets: {
    status: T.LoadStatuses.NOT_LOADED,
    data: null,
  },
  passed: {
    isFetching: false,
    data: [],
    error: null,
    updated: null,
  },
  passed_full: {
    isFetching: false,
    data: null,
    error: null,
    updated: null,
  },
  events: {
    isFetching: false,
    data: null,
    tracks: null,
    error: null,
    updated: null,
    status: T.LoadStatuses.NOT_LOADED,
  },
  summary: {
    isFetching: false,
    data: null,
    error: null,
    updated: null,
  },
  show_role: {
    isFetching: false,
    data: null,
    error: null,
    updated: null,
  },
  roles: {
    isFetching: false,
    data: null,
    error: null,
    updated: null,
  },
  report:{
    data: null,
    error: null,
    updated: null,
    isFetching: false,
  },
  cvs:{
    isFetching: false,
    data: null,
    error: null,
    updated: null,
  },
  cvs_full:{
    isFetching: false,
    data: null,
    error: null,
    updated: null,
  },
  specialroles: {
    data: null,
    superuser: false,
  },
  isFetching: false,
  updated: null,
  normalizedData: {
    events: {
      status: T.LoadStatuses.NOT_LOADED,
      eventsConfirmed: [],
      eventsWaitlist: [],
      confirmedIds: [],
      waitlistIds: [],
    },
  },
};

const newReducer = {
  RESET_STORE: state => {
    state = initialState;
  },
  fetchPerson: {
    request: (state, action) => {
      state.person.isFetching = true;
      state.person.data = null;
      state.person.updated = null;
    },
    success: (state, action) => {
      state.person.isFetching = false;
      state.person.data = action.payload.person;
      // if (action.payload.competencelevel) {
      //   state.person.competencelevel = action.payload.competencelevel;
      // }
      state.person.updated = new Date();
    },
    failure: (state, action) => {
      state.person.isFetching = false;
      state.person.data = null;
      state.person.error = action.payload.error;
      state.person.updated = null;
    },
  },
  fetchPersonFull: {
    request: (state, action) => {
      state.isFetching = true;
    },
    success: (state, action) => {
      state.isFetching = false;
      state.updated = new Date();
    },
  },
  fetchCompetenceLevel: {
    request: (state, action) => {
      state.person.competencelevel.status = T.LoadStatuses.IS_LOADING;
    },
    success: (state, action) => {
      state.person.competencelevel.data = action.payload.competencelevel;
      state.person.competencelevel.status = T.LoadStatuses.LOADED;
    },
    failure: (state, action) => {
      state.person.competencelevel.error = action.payload.error;
      state.person.competencelevel.status = T.LoadStatuses.ERROR;
    },
  },
  editPerson: {
    request: (state, action) => {
      state.isFetching = true;
    },
    success: (state, action) => {
      state.isFetching = false;
    },
  },
  setActiveOrgId: (state, action) => {
    const orgId = !!action.payload && Number.parseInt(action.payload, 10);

    // state.activeOrg.prevOrgId = state.activeOrg.orgId;
    state.activeOrg.orgId = orgId;
  },
  setManager: (state, action) => {
    state.person.storeManager = true;
  },
  setSpecialroles: (state, action) => {
    state.specialroles.data = action.payload.data;
    state.specialroles.superuser = action.payload.superuser;
    state.specialroles.driftsjef = action.payload.driftsjef;
  },
  selfSign: {
    request: (state, action) => {
      state.selfSign.status = T.LoadStatuses.IS_LOADING;
    },
    success: (state, action) => {
      state.selfSign.status = T.LoadStatuses.LOADED;
      state.selfSign.data = action.payload.competences;
    },
    failure: (state, action) => {
      state.selfSign.status = T.LoadStatuses.LOADED;
    },
  },
  cancelSelfSign: {
    request: (state, action) => {
      state.selfSign.status = T.LoadStatuses.IS_LOADING; // ?
    },
    success: (state, action) => {
      state.selfSign.status = T.LoadStatuses.NEED_RELOAD;
    },
    failure: (state, action) => {
      state.selfSign.status = T.LoadStatuses.FAILED;
    },
  },
  selfSignNeedsReload: (state, action) => {
    state.selfSign.status = T.LoadStatuses.NEED_RELOAD;
  },
  switchTab: (state, action) => {
    state.tab = action.payload;
  },
  switchSection: (state, action) => {
    state.section = action.payload;
  },
  fetchPersonEvents: {
    request: (state, action) => {
      state.events.isFetching = true;
      state.events.status = T.LoadStatuses.IS_LOADING;
      state.normalizedData.events.status = T.LoadStatuses.IS_LOADING;
    },
    success: (state, action) => {
      state.events.isFetching = false;
      state.events.status = T.LoadStatuses.LOADED;

      state.events.data = action.payload?.events?.personevents
        ? action.payload.events.personevents.filter(pe => pe.competence_type !== 28)
        : state.events.data;
      state.events.tracks = action.payload?.events?.personevents
        ? action.payload.events.personevents.filter(pe => pe.competence_type === 28)
        : state.events.tracks;

      const updated = action.payload
        ? action.payload?.events?.personevents?.reduce?.((acc, event) => {
          if (event.confirmed && !event.waitlist) {
            acc.eventsConfirmed.push(event);
            acc.confirmedIds.push(event.id);
          } else if (event.waitlist) {
            acc.eventsWaitlist.push(event);
            acc.waitlistIds.push(event.id);
          }

          return acc;
        }, {
          eventsConfirmed: [],
          eventsWaitlist: [],
          confirmedIds: [],
          waitlistIds: [],
        })
        : {};

      state.normalizedData.events.eventsConfirmed = updated.eventsConfirmed;
      state.normalizedData.events.eventsWaitlist = updated.eventsWaitlist;
      state.normalizedData.events.confirmedIds = updated.confirmedIds;
      state.normalizedData.events.waitlistIds = updated.waitlistIds;

      state.normalizedData.events.status = T.LoadStatuses.LOADED;
    },
    failure: (state, action) => {
      state.events.isFetching = false;
      state.events.status = T.LoadStatuses.FAILED;
      state.normalizedData.events.status = T.LoadStatuses.FAILED;
    },
  },
  signedOnSingleEvent: (state, action) => {
    state.events.data.push(action.payload.event);
  },
  setLastMessageTimestamp: (state, action) => {
    state.person.data.data.lastMessage = action.payload.last_message;
  },
  fetchHelptexts: (state, action) => {
    state.helptexts = action.payload.data;
  },
  fetchAllOrganisations: {
    request: (state, action) => {
      state.person.tenets.status = T.LoadStatuses.IS_LOADING;
      state.person.tenets.data = null;
    },
    success: (state, action) => {
      state.person.tenets.status = T.LoadStatuses.LOADED;
      state.person.tenets.data = action.payload.data.length !== undefined ? action.payload.data : null;
    },
    failure: (state, action) => {
      state.person.tenets.status = T.LoadStatuses.FAILED;
      state.person.tenets.data = null;
    },
  },
  fetchRole: {
    request: (state, action) => {
      state.show_role.isFetching = true;
      state.show_role.data = null;
      state.show_role.updated = null;
    },
    success: (state, action) => {
      state.show_role.isFetching = false;
      state.show_role.data = action.payload.role;
      state.show_role.updated = new Date();
    },
    failure: (state, action) => {
      state.show_role.isFetching = false;
      state.show_role.data = null;
      state.show_role.updated = null;
    },
  },
  fetchCv: {
    request: (state, action) => {
      state.cvs_full.isFetching = true;
      state.cvs_full.data = null;
      state.cvs_full.updated = null;
    },
    success: (state, action) => {
      const {cv: fetchedCv} = action.payload;

      state.cvs_full.isFetching = false;
      if(!state.cvs_full.data) {
        state.cvs_full.data = {};
      }
      state.cvs_full.data[fetchedCv.id] = fetchedCv;
      state.cvs_full.updated = new Date();
    },
    failure: (state, action) => {
      state.cvs_full.isFetching = false;
      state.cvs_full.data = null;
      state.cvs_full.updated = null;
      state.cvs_full.error = true;
    },
  },
  fetchMyTasks: {
    request: (state, action) => {
      state.tasks.status = T.LoadStatuses.IS_LOADING;
      state.tasks.data = null;
    },
    success: (state, action) => {
      state.tasks.status = T.LoadStatuses.LOADED;
      state.tasks.data = action.payload.competences;
    },
    failure: (state, action) => {
      state.tasks.status = T.LoadStatuses.FAILED;
      state.tasks.data = null;
    },
  },
  fetchCvs: {
    request: (state, action) => {
      state.cvs.isFetching = true;
      state.cvs.updated = null;
    },
    success: (state, action) => {
      state.cvs.isFetching = false;
      state.cvs.data = Object.fromEntries(action.payload.cvs.map(cv => [cv.id, cv]));
      state.cvs.updated = new Date();
    },
    failure: (state, action) => {
      state.cvs.isFetching = false;
      state.cvs.error = true;
      state.cvs.updated = null;
    },
  },
  editCv: {
    request: (state, action) => {
      state.cvs_full.isFetching = true;
      state.cvs_full.updated = null;
      state.cvs_full.data = null;
    },
    success: (state, action) => {
      const {cv: fetchedCvFromEdit} = action.payload;

      state.cvs_full.data[fetchedCvFromEdit.id] = fetchedCvFromEdit;

      state.cvs_full.isFetching = false;
      state.cvs_full.updated = new Date();
    },
    failure: (state, action) => {
      state.cvs_full.isFetching = false;
      state.cvs_full.data = null;
      state.cvs_full.error = true;
      state.cvs_full.updated = null;
    },
  },
  removeCv: {
    request: (state, action) => {
      state.cvs_full.isFetching = true;
      state.cvs_full.updated = null;
    },
    success: (state, action) => {
      const {removedId} = action.payload;

      delete state.cvs.data[removedId];
      delete state.cvs_full.data[removedId];

      state.cvs_full.isFetching = false;
      state.cvs_full.updated = new Date();
    },
    failure: (state, action) => {
      state.cvs_full.isFetching = false;
      state.cvs_full.error = true;
      state.cvs_full.updated = null;
    },
  },
  fetchReport: {
    request: (state, action) => {
      state.report.isFetching = true;
      state.report.data = null;
      state.report.updated = null;
    },
    success: (state, action) => {
      state.report.isFetching = false;
      state.report.data = action.payload.data;
      state.report.updated = new Date();
    },
    failure: (state, action) => {
      state.report.isFetching = false;
      state.report.data = null;
      state.report.error = true;
      state.report.updated = null;
    },
  },
  fetchRoles: {
    request: (state, action) => {
      state.roles.isFetching = true;
      state.roles.data = null;
      state.roles.updated = null;
    },
    success: (state, action) => {
      state.roles.isFetching = false;
      state.roles.data = action.payload.roles;
      state.roles.updated = new Date();
    },
    failure: (state, action) => {
      state.roles.isFetching = false;
      state.roles.data = null;
      state.roles.updated = null;
    },
  },
  fetchPersonSummary: {
    request: (state, action) => {
      state.summary.isFetching = true;
      state.summary.data = null;
      state.summary.updated = null;
    },
    success: (state, action) => {
      state.summary.isFetching = false;
      state.summary.data = action.payload.summary;
      state.summary.updated = new Date();
    },
    failure: (state, action) => {
      state.summary.isFetching = false;
      state.summary.data = null;
      state.summary.updated = null;
    },
  },
  fetchExpiring: {
    request: (state, action) => {
      state.expiring.isFetching = true;
      state.expiring.data = null;
      state.expiring.updated = null;
      state.expiring.status = T.LoadStatuses.IS_LOADING;
    },
    success: (state, action) => {
      state.expiring.isFetching = false;
      state.expiring.data = action.payload.data;
      state.expiring.updated = new Date();
      state.expiring.status = T.LoadStatuses.LOADED;
    },
  },
  fetchPassedCompetences: {
    request: (state, action) => {
      state.passed.isFetching = true;
      state.passed.data = [];
      state.passed.updated = null;
    },
    success: (state, action) => {
      state.passed.isFetching = false;
      state.passed.data = action.payload.competences;
      state.passed.updated = null;
    },
    failure: (state, action) => {
      state.passed.isFetching = null;
      state.passed.data = [];
      state.passed.updated = null;
      state.passed.error = true;
    },
  },
  fetchPassedCompetencesFull: {
    request: (state, action) => {
      state.passed_full.isFetching = true;
      state.passed_full.data = [];
      state.passed_full.updated = null;
    },
    success: (state, action) => {
      state.passed_full.isFetching = false;
      state.passed_full.data = action.payload.competences;
      state.passed_full.updated = null;
    },
    failure: (state, action) => {
      state.passed_full.isFetching = null;
      state.passed_full.data = null;
      state.passed_full.updated = null;
      state.passed_full.error = true;
    },
  },
  fetchCompetences: {
    request: (state, action) => {
      state.competences.isFetching = true;
      state.competences.data = null;
      state.competences.updated = null;
    },
    success: (state, action) => {
      state.competences.isFetching = false;
      state.competences.data = action.payload.competences;
      state.competences.updated = new Date();
    },
    failure: (state, action) => {
      state.competences.isFetching = false;
      state.competences.data = null;
      state.competences.error = action.payload.error;
      state.competences.updated = null;
    },
  },
  fetchCompetencesChildren: {
    request: (state, action) => {
      state.competences.isSubFetching = true;
    },
    success: (state, action) => {
      state.competences.isSubFetching = false;
      state.competences.data = action.payload.competences;
      state.competences.updated = new Date();
    },
    failure: (state, action) => {
      state.competences.isSubFetching = false;
    },
  },
  fetchShowRoleChildren: {
    request: (state, action) => {
      state.competences.isSubFetching = true;
      state.show_role.isSubFetching = true;
    },
    success: (state, action) => {
      state.competences.isSubFetching = false;
      state.show_role.isSubFetching = false;
      state.show_role.data = action.payload.competences;
    },
  },
  // actions without reducers
  editPassword: {
    request: null,
    success: null,
    failure: null,
  },
  createSelfSign: {
    request: null,
    success: null,
    failure: null,
  },
  deleteProfilePicture: {
    request: null,
    success: null,
    failure: null,
  },
  changeProfilePicture: {
    request: null,
    success: null,
    failure: null,
  },
  addCv: {
    request: null,
    success: null,
    failure: null,
  },
  updateOneCompetence: {
    request: null,
    success: null,
    failure: null,
  },
  toggleCompetence: null,
  fetchRequirements: null,
  updatePassedCompetences: null,
  cheatCompetence: null,
  updateActiveOrgId: null,
};

export const {
  actions: profileActions,
  reducer: profileImmerReducer,
} = createReducerAndActions({
  prefix: 'profile',
  initialState,
  actions: newReducer,
});

export const {
  fetchPerson: profileFetchPerson,
  fetchPersonFull: profileFetchPersonFull,
  editPerson: profileEditPerson,
  setActiveOrgId: profileSetActiveOrgId,
  setManager: profileSetManager,
  setSpecialroles: profileSetSpecialroles,
  selfSign: profileSelfSign,
  cancelSelfSign: profileCancelSelfSign,
  selfSignNeedsReload: profileSelfSignNeedsReload,
  switchTab: profileSwitchTab,
  switchSection: profileSwitchSection,
  fetchPersonEvents: profileFetchPersonEvents,
  signedOnSingleEvent: profileSignedOnSingleEvent,
  setLastMessageTimestamp: profileSetLastMessageTimestamp,
  fetchHelptexts: profileFetchHelptexts,
  fetchAllOrganisations: profileFetchAllOrganisations,
  fetchRole: profileFetchRole,
  fetchCv: profileFetchCv,
  fetchMyTasks: profileFetchMyTasks,
  fetchCvs: profileFetchCvs,
  editCv: profileEditCv,
  removeCv: profileRemoveCv,
  fetchReport: profileFetchReport,
  fetchRoles: profileFetchRoles,
  fetchPersonSummary: profileFetchPersonSummary,
  fetchExpiring: profileFetchExpiring,
  fetchPassedCompetences: profileFetchPassedCompetences,
  fetchPassedCompetencesFull: profileFetchPassedCompetencesFull,
  fetchCompetences: profileFetchCompetences,
  fetchCompetencesChildren: profileFetchCompetencesChildren,
  fetchShowRoleChildren: profileFetchShowRoleChildren,
  editPassword: profileEditPassword,
  fetchRequirements: profileFetchRequirements,
  createSelfSign: profileCreateSelfSign,
  deleteProfilePicture: profileDeleteProfilePicture,
  updateOneCompetence: profileUpdateOneCompetence,
  updatePassedCompetences: profileUpdatePassedCompetences,
  cheatCompetence: profileCheatCompetence,
  updateActiveOrgId: profileUpdateActiveOrgId,
  changeProfilePicture: profileChangeProfilePicture,
  addCv: profileAddCv,
  toggleCompetence: profileToggleCompetence,
  fetchCompetenceLevel: profileFetchCompetenceLevel,
} = profileActions;

export default profileImmerReducer;
