import {useSelector} from 'react-redux';
import {getDurationName} from '@components/course/course-duration';
import {backendUrl} from '@config';
import {getConfigObject} from '@selectors/config.selectors';
import {getImageUrl} from '@utils/misc.utils';

export const getDurationText = durations => durations?.length
  ? durations
    .map(({duration, type}) => `${duration} ${getDurationName(type, duration !== 1)}`)
    .join(' ')
  : '';

export const useDefaultImage = () => {
  const configObject = useSelector(getConfigObject);

  return configObject.getProperty('params.default-img');
};

export const getCompetenceCoverImageSrc = (competence, placeholderUrl) => {
  const {files, content} = competence || {};

  if (!files?.length) return null;

  const coverImage = files?.find?.(file => file?.title === 'cover');

  if (coverImage) return `${backendUrl}/files/image/${coverImage?.file_id ? coverImage.file_id : coverImage?.id}`;

  return content?.url ? getImageUrl(content.url) : getImageUrl(placeholderUrl);
};
