import * as configActions from '@actions/config.actions';
import * as T from '@types/load.types';
import {createConfigObject} from '../util/config.util';

export const initialConfigObject = {
  getProperty: () => {},
  isModuleEnabled: () => {},
  data: null,
  status: T.LoadStatuses.NOT_LOADED,
  isMapActivated: undefined,
};

const initialState = {
  isFetching: false,
  data: null,
  error: null,
  status: T.LoadStatuses.NOT_LOADED,
  configObject: initialConfigObject,
};

const config = (state = initialState, action) => {
  switch (action.type) {
  case configActions.CONFIG_GET_CONFIG_REQUEST:
    return {
      ...state,
      isFetching: true,
      status: T.LoadStatuses.IS_LOADING,
    };
  case configActions.CONFIG_GET_CONFIG_SUCCESS:
    return {
      ...state,
      data: action.payload.config,
      status: T.LoadStatuses.LOADED,
      configObject: createConfigObject({data: action.payload.config}),
    };
  case configActions.CONFIG_GET_CONFIG_FAILURE:
    return {
      ...initialState,
      error: action.payload.error,
      status: T.LoadStatuses.FAILED,
      configObject: {
        ...initialState.configObject,
        status: T.LoadStatuses.FAILED,
      },
    };
  case 'RESET_STORE':
    return initialState;
  default:
    return state;
  }
};

export default config;
