import axios from 'axios';
import dayjs from 'dayjs';
import produce from 'immer';
import {
  all,
  call,
  fork,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import * as alertAction from '@actions/alert.actions';
import {authUnauthorized} from '@actions/auth.actions';
import {CONFIG_GET_CONFIG_SUCCESS} from '@actions/config.actions';
import * as CA from '@actions/courses.actions';
import {
  employeesFetchSelectedPersonCompetences,
  employeesFetchSelectedPersonCompetencesChildren,
} from '@actions/employees.actions';
import {
  selectMapCourse,
  setMapIsNotVerified,
  setMapIsVerified,
  setOutroIsCompleted,
  setOutroIsNotCompleted,
} from '@actions/map.actions';
import * as mapCoursesActions from '@actions/map.actions';
import {notificationsAdd} from '@actions/notifications.actions';
import * as PA from '@actions/profile.actions';
import {fetchRole} from '@actions/roles.actions';
import {ROUTER_MY_EDUCATION_MAIN_VIEW_DID_MOUNT} from '@actions/router.actions';
import {backendUrl, backendUrlV2} from '@config';
import {getCourseEventsAPI} from '@sagas/courses.sagas';
import {getAuthStatus} from '@selectors/auth.selectors';
import {
  getConfigObject,
  getPropertiesForCurrLangAndTrackBadge,
} from '@selectors/config.selectors';
import {
  getIsAllMapDotsCompleted,
  getMapCourses,
  getMapDotts,
  getMapOutro,
  getMapVerification,
} from '@selectors/map.selectors';
import {
  getActiveOrgId,
  getCompetences,
  getOrganisationId,
  getPassed,
  getPassedCompetences,
  getPassedCompetencesIds,
  getPassedIds,
  getProfile,
  getProfileId,
  getProfileUserName,
  getRoles,
  getSelectedRoleId,
  getSelfSign,
  getShowRoles,
} from '@selectors/profile.selectors';
import {i18n} from '@src/i18n';
import {CourseType} from '@types/competence';
import * as T from '@types/load.types';
import {stringifyUrlParams} from '@utils/requests.utils';
import retry from '@utils/sagas.utils';
import {getSelectedPerson} from '../selectors/employees.selectors';

const cheif_names = new Set([
  'employeemanager',
  'admin',
  'manager',
  'superadmin',
  'superuser',
]);

const ProfileAxios = axios.create({
  headers: {'X-Grape-Lang': localStorage.getItem('language')},
  withCredentials: true,
});

const delay = ms => new Promise(res => setTimeout(res, ms));

function* refetchSelfSign() {
  yield put(PA.profileSelfSign({reload: true}));
}

export function* waitForProfile(returnProfile) {
  const profile = yield select(getProfile);

  if (!profile?.data?.person_id) {
    yield take([`${PA.profileFetchPerson.success}`,
    `${PA.profileFetchPersonFull.success}`]);
  }

  return returnProfile ? yield select(getProfile) : null;
}

export function* waitForOrgId(returnOrgId) {
  const orgId = yield select(getOrganisationId);

  if (!orgId) {
    yield take(`${PA.profileSetActiveOrgId.success}`);
  }

  return returnOrgId ? yield select(getOrganisationId) : null;
}

export function* updateActiveOrgIdSaga(action) {
  const orgId = action.payload;

  yield put(PA.profileSetActiveOrgId(orgId));

  localStorage.setItem('orgId', orgId);
}

export function* fetchAllOrganisations() {
  yield put(PA.profileFetchAllOrganisations());

  try {
    const data = yield call(() =>
      ProfileAxios
        .request({
          method: 'GET',
          url: `${backendUrl}/persons/allMyOrganisations`,
          withCredentials: true,
        })
        .then(response => response.data));

    yield put(PA.profileFetchAllOrganisations.success({data}));
  } catch (error) {
    console.error(error);
    if (error.code === 401) {
      yield put(authUnauthorized({error}));
    }
    yield put(PA.profileFetchAllOrganisations.failure({error}));
  }
}

function* createSelfSign(action) {
  const {formData, callback} = action.payload;

  yield put(PA.profileCreateSelfSign.request());

  // eslint-disable-next-line no-extend-native
  Date.prototype.toJSON = function () {
    return dayjs(this).format('YYYY-MM-DDThh:mm:00.000Z');
  };

  try {
    const data = yield call(() =>
      ProfileAxios.request({
        method: 'POST',
        headers: {'Content-type': 'application/json'},
        url: `${backendUrlV2}/personcompetences/`,
        data: JSON.stringify(formData),
        withCredentials: true,
      }).then(response => response.data));

    if (formData.files && Object.keys(formData.files).length !== 0) {
      yield all(Object.keys(formData.files).map(file => call(() => {
        if (formData['files'][file].length !== 0) {
          const formPost = new FormData();

          formPost.append('file', formData['files'][file][0]);
          formPost.append('timestamp', Math.round(Date.now() / 1000));

          return ProfileAxios.request({
            method: 'POST',
            url: `${backendUrl}/files/savemanagefiles/person_has_course_event/${data.id}`,
            data: formPost,
            withCredentials: true,
          }).then(response => response.data);
        }
      })));
    }
    yield put(notificationsAdd({
      notification: {
        color: 'green',
        text: data.passed === 100 && i18n('person.registered-competence-passed') || i18n('person.registered-competence'),
      },
    }));
    yield put(PA.profileCreateSelfSign.success());

    const profile = yield select(getProfile);

    if (data.passed === 100) {
      yield window.location.href.includes('/role') ? put(PA.profileFetchPersonSummary()) : put(PA.profileFetchReport());
    }

    yield put(PA.profileUpdateOneCompetence({
      cid: formData.competenceId,
      userName: profile.user_name,
    }));

    if (callback) {
      callback();
    };
  } catch (error) {
    console.log(error);
  };
}

function* fetchProfileCompetenceLevelSaga(action) {
  const {person_id, refetch} = action.payload || {};

  let personId = person_id;

  if (!person_id) {
    yield call(waitForProfile);
    personId = yield select(getProfileId);
  }

  yield put(PA.profileFetchCompetenceLevel.request());

  try {
    const {data} = yield call(
      axios.request,
      {
        url: `${backendUrlV2}/persons/${personId}/competencelevel`,
        method: 'GET',
        withCredentials: true,
      },
    );

    const competencelevel = [
      {
        title: i18n('person.level.total'),
        progress: Math.round(data.competence_level_total),
      },
      {
        title: i18n('person.level.mandatory'),
        progress: Math.round(data.competence_level_required),
      },
      {
        title: i18n('person.level.recomended'),
        progress: Math.round(data.competence_level_optional),
      },
      {
        title: i18n('person.level.personal'),
        progress: Math.round(data.competence_level_personal),
      },
    ];

    yield put(PA.profileFetchCompetenceLevel.success({competencelevel}));
  } catch (error) {
    console.error(error);
    yield put(PA.profileFetchCompetenceLevel.failure({error}));
  }
}

function* fetchPerson() {
  yield put(PA.profileFetchPerson.request());

  try {
    const profileData = yield retry(() => ProfileAxios
      .request({
        method: 'GET',
        url: `${backendUrl}/api/person`,
        params: {
          fields:
            'person_id,firstname,lastname,profile_image,email,mobile,fullname,user_name,roles(title,role_type_role_type,organisation_id),positions(organisation_id,title,role_type_role_type),organisations(organisation_id,extern_organisation_id,id,title,brand_id),data(avatar,last_message)',
        },
        withCredentials: true,
      })
      .then(response => response.data?.persons?.[0]));

    const person_id = profileData.person_id;

    if (person_id) {
      yield put(PA.profileFetchCompetenceLevel({person_id}));
    }

    let isCheif = false;
    let pos = null;

    if (Array.isArray(profileData?.positions)) {
      pos = profileData.positions.some(p => cheif_names.has(p.role_type_role_type));
      isCheif = pos;
    }

    if (!isCheif && Array.isArray(profileData?.roles)) {
      pos = profileData.roles.some(p => cheif_names.has(p.role_type_role_type));
      isCheif = pos;
    }

    const activeOrgId = yield select(getActiveOrgId);

    let orgIdFinal = activeOrgId || localStorage.getItem('orgId');

    if (isCheif) {
      yield put(PA.profileSetManager());

      /*
       * IF WE ARE CHEIF, CHECK ALL ORGS AND REMOVE ANY THAT ARE NOT CHEIF.
       *
       * */
      const {
        positions = [],
        roles = [],
      } = profileData || {};

      if (positions.length) {
        const rpos = positions.filter(p =>
          cheif_names.has(p.role_type_role_type));

        if (rpos.length !== 0 && !orgIdFinal) {
          orgIdFinal = rpos[0].organisation_id;
        }
      }

      /*
      *  IF USER IS NOT LEADER FOR ITS ORG, CHECK ITS ROLES.
      * */
      if (!orgIdFinal && roles.length) {
        const rrpos = roles.filter(p =>
          cheif_names.has(p.role_type_role_type));

        if (rrpos.length && positions.length) {
          orgIdFinal = positions[0].organisation_id;
        }
      }
    }

    if (orgIdFinal && !activeOrgId) {
      yield call(updateActiveOrgIdSaga, {payload: Number.parseInt(orgIdFinal, 10)});
    }

    const superuser = !!profileData?.roles?.some?.(p => p.role_type_role_type === 'superuser') || false;

    yield put(PA.profileSetSpecialroles({
      data: null,
      superuser,
    }));

    // profileData.competencelevel = competencelevel;

    yield put(PA.profileFetchPerson.success({
      person: profileData,
      positions: profileData.positions,
      // competencelevel,
    }));
  } catch (error) {
    console.error(error);
    yield put(authUnauthorized({error}));
    yield put(PA.profileFetchPerson.failure({error}));
  }
}

function* fetchCompetencesChildrenAPI(payload) {
  const {courseId, root, isOtherCompetences} = payload.payload;
  let profile = null;
  const orgId = yield select(getOrganisationId);

  switch (root) {
  case 'show-role': {
    profile = yield select(getProfile);
    yield put(PA.profileFetchShowRoleChildren.request());

    break;
  }
  case 'employee-activites': {
    profile = yield select(getSelectedPerson);
    yield put(employeesFetchSelectedPersonCompetencesChildren.request());

    break;
  }
  case 'show-employee': {
    profile = yield select(getSelectedPerson);
    yield put(employeesFetchSelectedPersonCompetencesChildren.request());

    break;
  }
  default: {
    profile = yield select(getProfile);
    yield put(PA.profileFetchCompetencesChildren.request());
  }
  }

  const personId = profile.data.id;

  let competences = [];

  switch (root) {
  case 'show-role': {
    competences = yield select(getShowRoles);
    if (competences.data) {
      competences = competences.data;
    }

    break;
  }
  case 'employee-activites': {
    competences = yield select(getSelectedPerson);

    break;
  }
  case 'show-employee': {
    competences = yield select(getSelectedPerson);

    break;
  }
  default: {
    competences = yield select(getCompetences);
  }
  }

  try {
    const {data} = yield retry(() =>
      ProfileAxios.request({
        method: 'GET',
        url: `${backendUrl}/persons/get_children_competences_json/${personId}/${courseId}/${orgId}/0`,
        params: {
          state: 'all',
          limit: 100,
          fields:
            'certificate_url,passed,competence_id,competence(files,title,id,short_description,description,person_competence_id,competence_type_id),competence_title,person_competence_id,event_id,date,competence_type,competence_type_id,grade',
        },
        withCredentials: true,
      }));

    switch (root) {
    case 'show-role': {
      const newCompetences = produce(competences, draft => {
        for (let i = 0; i < draft.requirements.length; i += 1) {
          if (draft.requirements[i].competence_id === courseId) {
            draft.requirements[i].competence.children = data;
          }
        }
      });

      yield put(PA.profileFetchShowRoleChildren.success({competences: newCompetences}));

      break;
    }
    case 'employee-activites': {
      const newCompetences = produce(competences, draft => {
        for (
          let i = 0;
          i < draft.data.summary.activities.children.length;
          i += 1
        ) {
          if (
            draft.data.summary.activities.children[i].competence_id
          === courseId
          ) {
            draft.data.summary.activities.children[i].children = data;
            break;
          }
        }
      });
      // const new_data = competences.data;

      yield put(employeesFetchSelectedPersonCompetences.success({data: newCompetences.data}));

      break;
    }
    case 'show-employee': {
      const newCompetences = produce(competences, draft => {
        const targetCompetences = draft.data.summary[isOtherCompetences ? 'other' : 'requirement'];

        for (const targetCompetence of targetCompetences) {
          for (
            let ii = 0;
            ii < targetCompetence.children.length;
            ii += 1
          ) {
            if (
              targetCompetence.children[ii]
                .competence_id === courseId
            ) {
              targetCompetence.children[
                ii
              ].children = data;
            }
          }
        }
      });

      yield put(employeesFetchSelectedPersonCompetences.success({data: newCompetences.data}));


      break;
    }
    default: {
      const newCompetences = produce(competences, draft => {
        for (const competence of draft) {
          if (competence.competence_id === courseId) {
            if (root === 'show-role') {
              competence.competence.children = data;
            } else {
              competence.children = data;
            }
          }
        }
      });

      yield put(PA.profileFetchCompetencesChildren.success({competences: newCompetences}));
    }
    }
  } catch (error) {
    if (error.status === 401) {
      yield put(authUnauthorized({error}));
    }
    console.error(error);
    if (root === 'show-role') {
      yield put(PA.profileFetchShowRoleChildren.failure({error}));
    } else if (root === 'show-employee') {
      yield put(employeesFetchSelectedPersonCompetencesChildren.failure({error}));
    } else {
      yield put(PA.profileFetchCompetencesChildren.failure({error}));
    }
  }
}


/*
 *
 *   -- updateOneCompetence --
 *
 *   Update one competence and go back to redux.
 *   and passed competence
 *
 * */

export function* updateOneCompetence(action) {
  const {cid, userName} = action.payload;

  try {
    console.log('update', cid);
    yield put(PA.profileUpdateOneCompetence.request());
    const competences = yield retry(() =>
      ProfileAxios
        .request({
          method: 'GET',
          url: `${backendUrl}/api/personcompetences/${cid}`,
          params: {
            fields:
              'title,description,valid_until,requirement_type,certificate_url,description,competence_id,competence_type,competence(files,competence_title,checked_by,short_description,title,competence_type,competence_type_id,competence_id),passed,manager_check_user_id',
            limit: 101,
            user_name: userName,
          },
          withCredentials: true,
        })
        .then(response => response.data.personcompetences));

    console.log('competences', competences);
    if (competences && competences.length !== 0) {
      console.log('competences', competences);
      const isPasssed = competences[0].passed === 100;

      console.log('isPassed', isPasssed);

      if (window.location.href.includes('/role')) {
        const userRolesCompetences = yield select(getShowRoles);
        const userCompetence = userRolesCompetences.data.requirements.findIndex(urc => urc.competence_id === competences[0].competence_id);

        const newCompetences = produce({
          userRolesCompetences,
          competences,
        }, draft => {
          draft.competences[0].competence.competence_type = competences[0].competence_type;
          draft.competences[0].competence.competence_title = competences[0].competence.title;
          draft.competences[0].requirement_type = 'Mandatory';

          if (userCompetence !== -1) {
            draft.userRolesCompetences.data.requirements[userCompetence] = competences[0];
          } else {
            draft.userRolesCompetences.data.requirements.push(competences[0]);
          }
        });

        yield put(PA.profileFetchRole.success({role: newCompetences.userRolesCompetences.data}));
      };

      if (isPasssed) {
        /* UPDATE PASSED COMPETENCES */
        const usersPassed = yield select(getPassed);
        const userPassedCompetence = usersPassed.data.findIndex(urc => urc.competence_id === competences[0].competence_id);

        const newUsersPassed = produce(usersPassed, draft => {
          if (userPassedCompetence !== -1) {
            console.log('exists');
            console.log('userPassedCompetence', userPassedCompetence);
          } else {
            draft.data.push({
              competence_id: competences[0].competence_id,
              date: competences[0].date,
              requirement: competences[0].requirement,
              valid_until: competences[0].valid_until,
              passed: 100,
              id: competences[0].id,
            });
          }
        });

        yield put(PA.profileFetchPassedCompetences.success({competences: newUsersPassed.data}));

        // yield put(profileActions.getPassedCompetences.success({data: newUsersPassed.data}));
      }
    }
    yield put(PA.profileUpdateOneCompetence.success());
  }catch(error) {
    console.log('err');
    console.log(error);
  }
};

/*
 *
 *   FETCHROLESREQUIRED
 *   Get the roles and requierments for this section.
 *
 * */

export function* fetchPersonsSummary() {
  yield put(PA.profileFetchPersonSummary.request());
  try {
    let userName = yield select(getProfileUserName);

    while (userName === null) {
      yield take();
      userName = yield select(getProfileUserName);
    }
    const summary = yield retry(() =>
      ProfileAxios
        .request({
          method: 'GET',
          url: `${backendUrl}/api/roles`,
          params: {
            fields: 'description,files',
            role_meta_types: 'position,role',
            summary: 1,
            user_name: userName,
          },
          withCredentials: true,
        })
        .then(response => response.data));

    yield put(PA.profileFetchPersonSummary.success({summary}));
  } catch (error) {
    console.error(error);
    if (error.code === 401) {
      yield put(authUnauthorized({error}));
    }
    yield put(PA.profileFetchPersonSummary.failure({error}));
  }
}

function* fetchPassedCompetences(action) {
  yield put(PA.profileFetchPassedCompetences.request());

  // TODO use this when we can fetch a single competence

  let params = {
    state: 'passed',
    limit: 100,
    // lms: 1,
    fields: 'passed,competence_id,points,date,valid_until',
  };

  if (action.dirty) {
    params = {
      state: 'passed',
      limit: 100,
      // lms: 1,
      dirty: 1,
      fields: 'passed,competence_id,points,date',
    };
  }
  try {
    const {data: {personcompetences: competences}} = yield retry(() =>
      ProfileAxios.request({
        method: 'GET',
        url: `${backendUrl}/api/personcompetences`,
        params,
        withCredentials: true,
      }));

    yield put(PA.profileFetchPassedCompetences.success({competences}));
  } catch (error) {
    console.error(error);
    if (error.status === 401) {
      yield put(authUnauthorized({error}));
    }
    yield put(PA.profileFetchPassedCompetences.failure({error}));
  }
}

function fetchSinglePassedCompetenceAPI(action) {
  const params = {
    state: 'passed',
    fields: 'passed,competence_id,points',
  };

  return ProfileAxios
    .request({
      method: 'GET',
      url: `${backendUrl}/api/personcompetences/${action.pid}`,
      params,
      withCredentials: true,
    })
    .then(({data}) => data);
}

function* fetchSelfSign(action) {
  const selfSign = yield select(getSelfSign);

  if (!action.payload?.reload && selfSign.status !== T.LoadStatuses.NOT_LOADED) {
    return;
  }
  yield put(PA.profileSelfSign.request());
  try {
    const {data: {competences}} = yield retry(() =>
      ProfileAxios.request({
        method: 'GET',
        url: `${backendUrl}/persons/pendingchecklists`,
        withCredentials: true,
      }));

    yield put(PA.profileSelfSign.success({competences: competences.filter(cc => cc.course_type.course_type !== CourseType.checklist_item)}));
  }catch (error) {
    yield put(PA.profileSelfSign.failure({error}));
  }
}

function* cancelMySelfSign(action) {
  const {courseId, phceId, callback} = action.payload;

  const personId = yield select(getProfileId);

  yield put(PA.profileCancelSelfSign.request());

  try {
    const data = {
      personId,
      courseId,
      passed: 0,
    };

    yield retry(() =>
      ProfileAxios.request({
        method: 'DELETE',
        url: `${backendUrlV2}/personcompetences/${phceId}`,
        data,
        withCredentials: true,
      }));

    yield put(PA.profileCancelSelfSign.success());

    if (callback) {
      callback();
    }
    yield put(notificationsAdd({
      notification: {
        color: 'red',
        text: i18n('person.cancel-competence-success'),
      },
    }));
  } catch (error) {
    if (callback) {
      callback();
    }
    yield put(PA.profileCancelSelfSign.failure({error}));
  }
};

function* fetchPassedCompetencesFull(action) {
  try {
    const {selfSign, summary, expiring} = action?.payload || {};

    if (expiring) yield put(PA.profileFetchExpiring());
    if (summary) yield put(PA.profileFetchPersonSummary());
    if (selfSign) yield fork(refetchSelfSign);
  } catch (error) {
    console.error(error);
    if (error.status === 401) {
      yield put(authUnauthorized({error}));
    }
    yield put(PA.profileFetchPassedCompetencesFull.failure({error}));
  }
}

function* fetchMissingCompetencesAPI() {
  yield put(PA.profileFetchCompetences.request());

  try {
    const {data: {personcompetences: competences}} = yield retry(() =>
      ProfileAxios.request({
        method: 'GET',
        url: `${backendUrl}/api/personcompetences`,
        params: {
          state: 'missing',
          limit: 100,
          isthins: 1,
          fields:
            'certificate_url,passed,competence_id,competence(files,title,id,short_description,description,person_competence_id,competence_type_id),competence_title,person_competence_id,event_id,date,competence_type,competence_type_id,grade',
        },
        withCredentials: true,
      }));

    for (const competence of competences) {
      competence.competence_type_id
        = competence.competence_type.competence_type_id;
      competence.id = competence.competence_id;
      competence.children = [];
      competence.expanded = false;
      if (competence.competence.files.length !== 0) {
        competence.competence.cover = competence.competence.files.find(f => f.title === 'cover');
      }
    }

    yield put(PA.profileFetchCompetences.success({competences}));
  } catch (error) {
    console.error(error);
    if (error.status === 401) {
      yield put(authUnauthorized({error}));
    }
    yield put(PA.profileFetchCompetences.failure({error}));
  }
}

function* updateMapCompetences(action) {
  const {alreadyFetchedOutroAndVerification} = action || {};

  const passedCompetencesIds = yield select(getPassedCompetencesIds);

  const mapOutroDot = yield select(getMapOutro);
  const mapVerificationDot = yield select(getMapVerification);

  const outroId = mapOutroDot && mapOutroDot.id;
  const verifyId = mapVerificationDot && mapVerificationDot.id;

  let mapOutroPassedCompetence = passedCompetencesIds.find(id => id === outroId);
  let mapVerificationPassedCompetence = passedCompetencesIds.find(id => id === outroId);

  if (outroId || verifyId) {
    // TODO, when we are able to specify multiple competences to fetch at the same time, use this instead of
    // fetching two times
    if (!mapOutroPassedCompetence && !alreadyFetchedOutroAndVerification) {
      mapOutroPassedCompetence = yield call(fetchSinglePassedCompetenceAPI, {pid: outroId}).personcompetences[0];
    }
    if (
      !mapVerificationPassedCompetence
      && !alreadyFetchedOutroAndVerification
    ) {
      mapVerificationPassedCompetence = yield call(
        fetchSinglePassedCompetenceAPI,
        {pid: verifyId},
      ).personcompetences[0];
    }

    yield mapOutroPassedCompetence ? put(setOutroIsCompleted()) : put(setOutroIsNotCompleted());
    yield mapVerificationPassedCompetence ? put(setMapIsVerified()) : put(setMapIsNotVerified());
  }
}

function* fetchCompetencesAPI() {
  yield put(PA.profileFetchCompetences.request());

  try {
    const {data: {personcompetences: competences}} = yield retry(() =>
      ProfileAxios.request({
        method: 'GET',
        url: `${backendUrl}/api/personcompetences`,
        params: {
          state: 'all',
          limit: 100,
          fields:
              'certificate_url,passed,competence_id,competence(files,title,id,short_description,description,person_competence_id,competence_type_id),competence_title,person_competence_id,event_id,date,competence_type,competence_type_id,grade',
        },
        withCredentials: true,
      }));

    for (const competence of competences) {
      competence.competence_type_id
          = competence.competence_type.competence_type_id;
      competence.id = competence.competence_id;
      competence.children = [];
      competence.expanded = false;
      if (competence.competence.files.length !== 0) {
        competence.competence.cover = competence.competence.files.find(f => f.title === 'cover');
      }
    }

    yield put(PA.profileFetchCompetences.success({competences}));
  } catch (error) {
    console.error(error);
    if (error.status === 401) {
      yield put(authUnauthorized({error}));
    }
    yield put(PA.profileFetchCompetences.failure({error}));
  }
}

function* updateProgress() {
  const personCurrent = yield select(getProfile);
  const rolesCurrent = yield select(getRoles);
  const passedIdsCurrent = yield select(getPassedCompetences);

  const updated = personCurrent.data && Array.isArray(rolesCurrent.data) && passedIdsCurrent
    ? produce({
      personCurrent,
      rolesCurrent,
      passedIdsCurrent,
    }, draft => {
      const person = draft.personCurrent;
      const roles = draft.rolesCurrent;
      const passed_ids = draft.passedIdsCurrent;

      if (person.data && Array.isArray(roles.data) && passed_ids) {
        for (let i = 0; i < roles.data.length; i += 1) {
          const pos = roles.data[i];
          let comp_passed_ids = 0;

          if (passed_ids.keys.length > 0) {
            for (let ii = 0; ii < pos.required_competences.length; ii += 1) {
              if (passed_ids.keys.includes(pos.required_competences[ii].id)) {
                comp_passed_ids += 1;
              }
            }
          }
          pos.progress = 0;
          pos.taskdone = comp_passed_ids;
          pos.tasks = pos.required_competences.length;
          if (comp_passed_ids !== 0) {
            pos.progress = Math.round(comp_passed_ids / pos.tasks * 100);
          }
        }

        const roles_update = [];

        for (let i = 0; i < roles.data.length; i += 1) {
          const pos = roles.data[i];

          if (pos.id === person.data.positions[0].id) {
            roles_update.push(pos);
            break;
          }
        }
        for (let i = 0; i < roles.data.length; i += 1) {
          const pos = roles.data[i];

          if (pos.required_competences.length !== 0 && pos.id !== person.data.positions[0].id) {
            roles_update.push(pos);
          }
        }

        updated.updatedRoles = roles_update;
      }
    })
    : null;

  if (updated) {
    yield put(PA.profileFetchRoles.success({roles: updated.updatedRoles}));
  }
}

export function* fetchCourseEvents() {
  yield put(PA.profileFetchPersonEvents.request());

  const userName = yield select(getProfileUserName);
  const my_events = yield call(getCourseEventsAPI, {userName});

  yield put(PA.profileFetchPersonEvents.success({events: my_events}));
}

export function* fetchPersonEvents(userName) {
  const events = yield call(getCourseEventsAPI, {userName});

  yield put(PA.profileFetchPersonEvents.success({events}));
}

export function* fetchFullPerson() {
  try {
    const loggedIn = yield select(getAuthStatus);

    // IF WE ARE NOT LOGGED IN JUST RETURN.
    if (!loggedIn?.isLoggedIn) return;

    yield put(PA.profileFetchPersonFull.request());
    const configObject = yield select(getConfigObject);

    const atlasActivated = configObject.isMapActivated;
    const myEducationEnabled = configObject.isModuleEnabled('my-education');

    if (myEducationEnabled) {
      yield call(fetchPerson, {});

      const userName = yield select(getProfileUserName);

      yield all([
        call(fetchPersonEvents, userName),
        call(fetchPassedCompetences, {userName}),
      ]);
    }

    if (atlasActivated) {
      yield call(updateMapCompetences, {alreadyFetchedOutroAndVerification: myEducationEnabled});
    }

    yield put(PA.profileFetchPersonFull.success());
    yield put(alertAction.actionClear());
  } catch (error) {
    console.error(error);
  }
}

function* updateCompetenceLevel() {
  const userName = yield select(getProfileUserName);
  const competencelevel = yield call(() =>
    ProfileAxios
      .request({
        method: 'GET',
        params: {user_name: userName},
        url: `${backendUrl}/api/competencelevel`,
        withCredentials: true,
      })
      .then(({data: {competencelevel: [profile]}}) => profile));

  return competencelevel;
}

function* editPassword({payload: {data: {newPassword, oldPassword}}}) {
  yield put(PA.profileEditPassword.request());
  try {
    const params = {};

    params.password = newPassword;
    params.old_password = oldPassword;
    params.password_match = newPassword;
    const query = encodeURI(stringifyUrlParams({...params}, 'person_data'));
    const userName = yield select(getProfileUserName);
    // TODO: some sort of feedback with result for the user
    const {data: return_data} = yield call(ProfileAxios.request, {
      method: 'PUT',
      url: `${backendUrl}/api/persons/${userName}?${query}`,
      withCredentials: true,
    });

    if (return_data.valid) {
      yield put(PA.profileEditPassword.success());
      yield put(notificationsAdd({
        notification: {
          text: 'Passordet er endret',
          color: 'green',
        },
      }));
    } else {
      yield put(PA.profileEditPassword.failure({}));
      yield put(notificationsAdd({
        notification: {
          text: return_data.message,
          color: 'red',
        },
      }));
    }
  } catch (error) {
    console.error(error);
    yield put(PA.profileEditPassword.failure({error}));
    yield put(notificationsAdd({
      notification: {
        text: 'Feil ved endring av passord',
        color: 'red',
      },
    }));
  }
}


function* fetchExpiringCompetences() {
  try {
    const {data: profileData} = yield call(waitForProfile, true);

    console.log({profileData});

    const userName = profileData.user_name; // yield select(getProfileUserName);

    yield put(PA.profileFetchExpiring.request());
    const {data: {personcompetences: data}} = yield retry(() =>
      ProfileAxios.request({
        method: 'GET',
        url: `${backendUrl}/api/personcompetences`,
        params: {
          user_name: userName,
          fields:
            'id,passed,date,valid_until,competence_title,competence_id,competence_type(title,competence_type)',
          state: 'expiring',
        },
        withCredentials: true,
      }));

    yield put(PA.profileFetchExpiring.success({data}));
  } catch (error) {
    console.error(error);
    yield put(PA.profileFetchExpiring.failure({error}));
  }
}

/*
 *  FUNCTION ON UPDATING ALL COMPETENCES, WILL CONTROL THE REST OF THE PROCESS AS WELL.
 */
function* updateCompetences(action) {
  // let section = null;
  // let refetchCompetenceOfId = null;
  // let disableStatusUpdateCheck = null;

  const {payload} = action || {};

  const {
    cid: refetchCompetenceOfId,
    disableStatusUpdateCheck,
    mapId,
  } = payload || {};

  const section = window.location.href.includes('/role')
    ? 'roles'
    : payload?.section;

  const mapDotts = yield select(getMapDotts);
  const userName = yield select(getProfileUserName);

  const courseToUpdateIsMapCourse = mapDotts?.some(dott => dott.id === refetchCompetenceOfId);

  yield mapId
    ? put(mapCoursesActions.fetchMapCourses({
      id: mapId,
      refetch: true,
    }))
    : !courseToUpdateIsMapCourse && call(fetchPassedCompetences, {
      cid: [refetchCompetenceOfId],
      userName,
      dirty: 1,
    });


  // yield courseToUpdateIsMapCourse
  //   ? put(mapCoursesActions.fetchMapCourses({id: refetchCompetenceOfId}))
  //   : ;

  const skipPageReload = payload?.skipPageReload || courseToUpdateIsMapCourse;

  if (!skipPageReload) {
    yield put(alertAction.actionLoading({message: 'course-done'}));
  }

  const passedIds = yield select(getPassedIds);
  const configObject = yield select(getConfigObject);
  const mapCourses = yield select(getMapCourses);

  if (mapId) {
    console.log('mapId', mapId);
  } else if (configObject.isMapActivated && courseToUpdateIsMapCourse) {
    const newMapCourses = produce(mapCourses, draft => {
      if (payload?.data?.passed === 100) {
        draft.data.tracks[0].dotts.forEach((mc, i) => {
          if (mc.id === refetchCompetenceOfId) {
            mc.status = 'DONE';
            if (i < mapCourses.data.tracks[0].dotts.length - 1 // IF NOT OPEN, OPEN IT.
              && mapCourses.data.tracks[0].dotts[i + 1].status !== 'DONE') {
              mapCourses.data.tracks[0].dotts[i + 1].status = 'OPEN';
            }
          }
        });
      }
    });

    yield put(mapCoursesActions.fetchMapCoursesSucceeded({data: newMapCourses.data}));

    yield call(updateMapCompetences);
  }

  const passedIds_new = yield select(getPassedIds);

  if (
    !disableStatusUpdateCheck
    && (passedIds.length !== passedIds_new.length || section === 'roles')
  ) {
    /*
     * check if we have a status update, if we do "reload" page.
     *
     * */
    yield put(PA.profileFetchCompetenceLevel());// updateCompetenceLevel();

    if (section === 'roles') {
      const selectedRoleId = yield select(getSelectedRoleId);

      yield put(fetchRole(selectedRoleId));
    } else {
      yield fetchCompetencesAPI();
    }
  }

  yield updateProgress();

  if (window.location.href.includes('/atlas')) {
    const isAllDone = yield select(getIsAllMapDotsCompleted);

    if (isAllDone) {
      const badge = yield select(getPropertiesForCurrLangAndTrackBadge);

      if (badge) {
        yield put(selectMapCourse({id: 'badge'}));
      }
    }
  }

  yield put(CA.coursesCloseCourse());
  yield put(alertAction.actionClear());
}

function* editPerson(action) {
  const {
    person: {
      profile_image_dataURL,
      firstname,
      lastname,
      email,
      mobile,
      roles,
    },
  } = action.payload;

  /*
   * TRIGGER SO WE UPDATE THE PERSON CARD AS WELL.
   * */
  yield put(PA.profileEditPerson.request());
  const userName = yield select(getProfileUserName);
  const params = encodeURI(stringifyUrlParams(
    {
      profile_image_dataURL,
      firstname,
      lastname,
      mobile,
      email,
      roles: roles.map(role => role.role_id),
    },
    'person_data',
  ));

  try {
    yield call(() =>
      ProfileAxios.request({
        method: 'PUT',
        url: `${backendUrl}/api/persons/${userName}?${params}`,
        withCredentials: true,
      }));

    yield call(fetchPerson);
    yield put(PA.profileEditPerson.success());
    yield put(notificationsAdd({
      notification: {
        text: i18n('person.your-profile-updated-success'),
        color: 'green',
      },
    }));
  } catch (error) {
    console.error(error);
    yield put(PA.profileEditPerson.failure(error));
  }
}

function* changeProfilePicture(action) {
  const {person_id, file} = action.payload;

  if (!file) return;

  yield put(PA.profileChangeProfilePicture.request());

  try {
    const formData = new FormData();

    formData.append('profile_image', file);
    const {data} = yield call(() =>
      ProfileAxios.request({
        method: 'POST',
        url: `${backendUrl}/persons/save_profile_image/${person_id}`,
        data: formData,
        withCredentials: true,
        config: {
          headers: {
            'Content-Type': 'multipart/form-data',
            'X-Requested-With': 'XMLHttpRequest',
          },
        },
      }));

    yield put(PA.profileChangeProfilePicture.success({data}));
  } catch (error) {
    console.error(error);
    yield put(PA.profileChangeProfilePicture.failure({error}));
  }
}

function* deleteProfilePicture(action) {
  const {person_id, file_id} = action.payload;

  yield put(PA.profileDeleteProfilePicture.request());

  try {
    const {data} = yield call(() =>
      ProfileAxios.request({
        method: 'POST',
        url: `${backendUrl}/deletemanagefile/person/${person_id}/${file_id}`,
        withCredentials: true,
      }));

    yield put(PA.profileDeleteProfilePicture.success({data}));
  } catch (error) {
    console.error(error);
    yield put(PA.profileDeleteProfilePicture.failure({error}));
  }
}

function cheatAPI(action) {
  return ProfileAxios
    .request({
      method: 'get',
      url: `${backendUrl}/templates/set-comp?username=${action.userName}&cid=${action.id}`,
    })
    .then(response => response.data);
}

function* cheatCompetence(payload) {
  const userName = yield select(getProfileUserName);

  yield call(cheatAPI, {
    id: payload.payload.id,
    userName,
  });
  yield delay(2000);
  yield call(() =>
    ProfileAxios
      .request({
        method: 'GET',
        url: `${backendUrl}/api/competencelevel`,
        withCredentials: true,
      })
      .then(({data: {competencelevel: [profile]}}) => profile));
  yield call(updateCompetences, {cid: payload.payload.id});
}

function* fetchCv(action) {
  const {cvId} = action.payload;

  yield put(PA.profileFetchCv.request());
  try {
    const cv = yield retry(() =>
      ProfileAxios.request({
        method: 'GET',
        url: `${backendUrl}/api/cvs/${cvId}`,
        withCredentials: true,
      }));


    yield put(PA.profileFetchCv.success({cv: cv.data.cv[0]}));
  } catch (error) {
    console.error(error);
    yield put(PA.profileFetchCv.failure({error}));
  }
}

function* fetchCvs(action) {
  yield put(PA.profileFetchCvs.request());
  try {
    const cvs = yield retry(() =>
      ProfileAxios.request({
        method: 'GET',
        url: `${backendUrl}/api/cvs`,
        withCredentials: true,
      }));


    yield put(PA.profileFetchCvs.success({cvs: cvs.data.cv}));
  } catch (error) {
    console.error(error);
    yield put(PA.profileFetchCvs.failure({error}));
  }
}

function* addCv(action) {
  const {data} = action.payload;

  yield put(PA.profileAddCv.request());

  try {
    const cv = yield retry(() =>
      ProfileAxios.request({
        method: 'POST',
        data,
        url: `${backendUrl}/api/cvs`,
        withCredentials: true,
      }));

    yield put(PA.profileAddCv.success({cv: cv.data.cv[0]}));
    yield put(PA.profileFetchCvs());
    if(action.payload.onSuccessCallback) {
      action.payload.onSuccessCallback(cv.data.cv[0]);
    }
  } catch (error) {
    console.error(error);
    yield put(PA.profileAddCv.failure({error}));
  }
}

function* editCv(action) {
  const {data, cvId} = action.payload;

  yield put(PA.profileEditCv.request());

  try {
    const cv = yield retry(() =>
      ProfileAxios.request({
        method: 'PUT',
        data,
        url: `${backendUrl}/api/cvs/${cvId}`,
        withCredentials: true,
      }));

    yield put(PA.profileEditCv.success({cv: cv.data.cv[0]}));
    yield put(PA.profileFetchCvs());
    if(action.payload.onSuccessCallback) {
      action.payload.onSuccessCallback();
    }
  } catch (error) {
    console.error(error);
    yield put(PA.profileEditCv.failure({error}));
  }
}

function* removeCv(action) {
  const {cvId, onSuccessCallback, onFailureCallback} = action.payload;

  yield put(PA.profileRemoveCv.request());

  try {
    yield retry(() =>
      ProfileAxios.request({
        method: 'DELETE',
        url: `${backendUrl}/api/cvs/${cvId}`,
        withCredentials: true,
      }));

    yield put(PA.profileRemoveCv.success({removedId: cvId}));
    yield put(PA.profileFetchCvs());

    if(onSuccessCallback) {
      onSuccessCallback({removedId: cvId});
    }
  } catch (error) {
    console.error(error);
    if(onFailureCallback) {
      onFailureCallback();
    }
    yield put(PA.profileRemoveCv.failure({error}));
  }
}

function* fetchTasks(payload) {
  const profile = yield call(waitForProfile, true);

  yield put(PA.profileFetchMyTasks.request());

  try {
    const {data} = yield retry(() =>
      ProfileAxios.request({
        method: 'GET',
        url: `${backendUrl}/persons/pendingchecklists/${profile.data.person_id}`,
        withCredentials: true,
      }));

    console.log('my data', data);
    yield put(PA.profileFetchMyTasks.success({competences: data.competences}));
  } catch (error) {
    console.error(error);
    yield put(PA.profileFetchMyTasks.failure({error}));
  }
}

function* fetchReport(payload) {
  const profile = yield call(waitForProfile, true);

  yield put(PA.profileFetchReport.request());

  try {
    const {data} = yield retry(() =>
      ProfileAxios.request({
        method: 'GET',
        url: `${backendUrl}/reports/personfullcompetencereportjson/${profile.data.person_id}`,
        params: {
          fields:
            'id,passed,date,certificate_url,competence_title,competence(competence_type,files)',
        },
        withCredentials: true,
      }));

    yield put(PA.profileFetchReport.success({data}));
  } catch (error) {
    console.error(error);
    yield put(PA.profileFetchReport.failure());
  }
}

const selfSignRefetchListener = takeLatest([
  `${PA.profileCancelSelfSign.success}`,
  `${PA.profileCancelSelfSign.failure}`,
  `${PA.profileCreateSelfSign.success}`,
  `${PA.profileCreateSelfSign.failure}`,
], refetchSelfSign);

const exportObj = [
  selfSignRefetchListener,
  takeLatest(`${PA.profileUpdateActiveOrgId}`, updateActiveOrgIdSaga),
  takeLatest(`${PA.profileFetchPersonEvents}`, fetchCourseEvents),
  takeLatest(`${PA.profileFetchExpiring}`, fetchExpiringCompetences),
  takeLatest(`${PA.profileEditPassword}`, editPassword),
  takeLatest([CONFIG_GET_CONFIG_SUCCESS, `${PA.profileFetchPersonFull}`], fetchFullPerson),
  takeLatest(`${PA.profileFetchPersonSummary}`, fetchPersonsSummary),
  takeLatest(`${PA.profileUpdatePassedCompetences}`, updateCompetences),
  takeLatest(`${PA.profileFetchRequirements}`, fetchMissingCompetencesAPI),
  takeLatest(`${PA.profileFetchCompetences}`, fetchCompetencesAPI),
  takeLatest(`${PA.profileSelfSign}`, fetchSelfSign),
  takeLatest(
    ROUTER_MY_EDUCATION_MAIN_VIEW_DID_MOUNT,
    fetchPassedCompetencesFull,
  ),
  takeLatest(
    `${PA.profileFetchPassedCompetencesFull}`,
    fetchPassedCompetencesFull,
  ),
  takeEvery(`${PA.profileFetchPassedCompetences}`, fetchPassedCompetences),
  takeEvery(`${PA.profileUpdateOneCompetence}`, updateOneCompetence),
  takeEvery(`${PA.profileFetchCompetencesChildren}`, fetchCompetencesChildrenAPI),
  takeLatest(`${PA.profileEditPerson}`, editPerson),
  takeLatest(`${PA.profileChangeProfilePicture}`, changeProfilePicture),
  takeLatest(`${PA.profileChangeProfilePicture.success}`, fetchPerson),
  takeLatest(`${PA.profileDeleteProfilePicture}`, deleteProfilePicture),
  takeLatest(`${PA.profileDeleteProfilePicture.success}`, fetchPerson),
  takeLatest(`${PA.profileFetchPerson}`, fetchPerson),
  takeLatest(`${PA.profileCheatCompetence}`, cheatCompetence),
  takeLatest(`${CA.coursesCourseFinished}`, updateCompetences),
  takeLatest(`${PA.profileFetchCv}`, fetchCv),
  takeLatest(`${PA.profileCreateSelfSign}`, createSelfSign),
  takeLatest(`${PA.profileFetchCvs}`, fetchCvs),
  takeLatest(`${PA.profileEditCv}`, editCv),
  takeLatest(`${PA.profileFetchReport}`, fetchReport),
  takeLatest(`${PA.profileFetchMyTasks}`, fetchTasks),
  takeLatest(`${PA.profileAddCv}`, addCv),
  takeLatest(`${PA.profileRemoveCv}`, removeCv),
  takeLatest(`${PA.profileCancelSelfSign}`, cancelMySelfSign),
  takeLatest(`${PA.profileFetchCompetenceLevel}`, fetchProfileCompetenceLevelSaga),
];


export default exportObj;
