import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {allowedLanguages} from '@src/config';
import {useCurrentLanguage} from '@src/hooks/misc/use-current-language';
import {i18n} from '@src/i18n';
import backgroundOslo1 from '../../../assets/images/background-olso-1.jpg';
import LanguageSelect from '../components/LanguageSelect/LanguageSelect';
import ChangePasswordForm from '../components/LoginFormTwo/ChangePasswordForm';
import LoginFormTwo from '../components/LoginFormTwo/LoginFormTwo';
import {SnapperFooter} from '../components/snapper-footer';
import {authDispatch} from '../utils/auth-actions';
import {
  BgImage,
  Footer,
  LanguageSelectWrapper,
  LeftPart,
  LoginFormWrapper,
  MainHeader,
  SubHeader,
  RightPart,
  Warning,
  Wrapper,
} from './styles/styles';
import '../styles/login-container.scss';
import { getConfigObject } from '@selectors/config.selectors';

const isIE = false || !!document.documentMode;

export const LoginContainer = ({to, serviceName = null}) => {
  const dispatch = useDispatch();
  const configObject = useSelector(getConfigObject);
  const loginTxt = configObject.getProperty('params.login');
  const {
    sessionId,
    stage,
    loginAttempts,
    newPwdStatus: forgotStatus = -1,
    secondLogin,
    newPwd: forgotStatusMsg,
    authStatus,
  } = useSelector(({auth}) => auth);

  const {
    isLoggedIn,
    loginFailed,
  } = authStatus || {};

  const {
    forgot: handleForgot,
    select: handleSelect,
    login: handleLogin,
    attempt: handleAttempt,
    reset: handleReset,
  } = useMemo(() => authDispatch(dispatch), [dispatch]);

  const [currLanguage, setCurrLanguage] = useCurrentLanguage();

  const [activeSection, setActiveSection] = useState('login');

  const handleCancel = useCallback(() => {
    setActiveSection('login');
    handleReset();
  }, [handleReset]);

  // Use this to switch menu type
  const loginType = 0;

  useEffect(() => {
    if (loginType === 0
      && secondLogin
      && sessionId) handleSelect('this');
  }, [
    loginType,
    secondLogin,
    sessionId,
    handleSelect,
  ]);

  useEffect(() => {
    if (activeSection === 'forgot' && forgotStatus === 2) {
      setActiveSection('login');
    };
  }, [forgotStatus, activeSection]);

  useEffect(() => {
    if (isLoggedIn || !to) return;

    if (sessionId && loginAttempts) {
      console.log('check it first.');
    }

    window.setTimeout(function () {
      if (!to) return;
      if (loginAttempts <= 2) {
        handleAttempt();
        window.location.replace(to + '?redirect=/auth/login');
      } else {
        console.warn('authAttempts:', loginAttempts);
      }
    }, 500);
  }, [isLoggedIn, dispatch, to, sessionId, loginAttempts, handleAttempt]);

  useEffect(() => {
    if (!isLoggedIn) {
      localStorage.removeItem('orgId');
    }
  }, [isLoggedIn]);
  if (to) {
    return (
      <div>{i18n('login.progress')}</div>
    );
  }

  return (
    <Wrapper className="login-wrapper">
      <LeftPart className="left-part">
        {Array.isArray(allowedLanguages) && allowedLanguages.length > 1 && (
          <LanguageSelectWrapper>
            <LanguageSelect
              languages={allowedLanguages}
              currLanguage={currLanguage}
              onChoosedLanguage={setCurrLanguage}
            />
          </LanguageSelectWrapper>
        )}
        <LoginFormWrapper>
          {loginTxt && loginTxt.image && (
            <div className="login-logo">
              <img src={loginTxt.image} alt="Customer logo" />
            </div>
          )}
          <MainHeader>
            {loginTxt && loginTxt.title ? loginTxt.title : i18n('login.login')}
            {serviceName ? ` – ${serviceName}` : ''}
          </MainHeader>
          <SubHeader>
          {loginTxt && loginTxt.subtitle && (
            <div dangerouslySetInnerHTML={{__html: loginTxt.subtitle}} />
          )}
          </SubHeader>
          {isIE && <Warning>{i18n('UNSUPPORTED_BROWSER')}</Warning>}

          {activeSection === 'login'
            ? (
              <LoginFormTwo
                stage={stage}
                handleSubmit={handleLogin}
                invalidCredentials={loginFailed}
                handleForgotPassword={() => setActiveSection('forgot')}
              />
            )
            : activeSection === 'forgot'
              ? (
                <ChangePasswordForm
                  forgotStatus={forgotStatus}
                  handleSubmit={handleForgot}
                  forgotStatusMsg={forgotStatusMsg}
                  handleCancel={handleCancel}
                />
              )
              : activeSection === 'faq'
                ? (
                  <div>
                    <h1>FAQ</h1>
                  </div>
                )
                : null}
        </LoginFormWrapper>
        <Footer>
          {SnapperFooter}
        </Footer>
      </LeftPart>

      <RightPart className="right-part">
        <BgImage
          className="login-background"
          src={backgroundOslo1}
        />
        <div className="copyright">
          Photo: Oliver Cole -
          {' '}
          <a
            href="https://unsplash.com/@oliver_photographer"
            target="_blank"
            rel="noreferrer"
          >
            Unsplash
          </a>
        </div>
      </RightPart>
    </Wrapper>
  );
};

export default LoginContainer;
