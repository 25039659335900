import axios from 'axios';
import {freeze} from 'immer';
import {END, eventChannel} from 'redux-saga';
import {
  all,
  call,
  debounce,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import * as CA from '@actions/courses.actions';
import {
  employeesFetchEvents,
  employeesGetEmployees,
} from '@actions/employees.actions';
import {notificationsAdd} from '@actions/notifications.actions';
import {
  profileFetchPerson,
  profileFetchPersonEvents,
  profileUpdatePassedCompetences,
} from '@actions/profile.actions';
import {backendUrl, backendUrlV2, nanoLearningUrl} from '@config';
import {
  getConfigObject,
  getPropertiesForCurrLangAndTrack,
} from '@selectors/config.selectors';
import {
  getCompetencesSearchTerm,
  getCourseCatalogDefaultCompetencegroup,
  getGroupedCompetenceIds,
  getNormalizedCompetenceDetails,
  getNormalizedCompetencegroups,
  getNormalizedCourseEvents,
  getSelectedCompetencegroupId,
  getSelectedCompetencetypes,
  getSelectedSubcompetencegroupId,
  getSelectedSubSubcompetencegroupId,
} from '@selectors/courses.selectors';
import {
  getNormalizedEmployees,
  getNormalizedEmployeesEvents,
} from '@selectors/employees.selectors';
import {
  getNormalizedProfileEvents,
  getProfile,
  getProfileUserName,
  isManager as getIsManager,
} from '@selectors/profile.selectors';
import {getChildrenDataRecurse} from '@utils/misc.utils';
import {stringifyUrlParams} from '@utils/requests.utils';
import retry from '@utils/sagas.utils';
import * as T from '../../store/types/load.types';
import * as CT from '../types/competence';
import {reduceEventsFullUpdate, reduceEventsPartialAdd, reduceEventsPartialRemoval} from '../util/courses.util';
import {cmsFetchContentListAPI} from './cms.sagas';

const defaultFieldsCompetences = [
  'short_description',
  'files',
  'title',
  'modified',
  'course_type',
  'competence_type_id',
  'competence_type_key',
  'durations',
  'has_parents',
  'competence_type',
  'url',
  'category',
  // 'description',
  'content(image,imageAltText,teaserForListView,id,video,files)',
];

// const defaultFieldsCmsContent = [
//   'title',
//   'image',
//   'video',
//   'imageCaption',
//   'imageAltText',
//   'authorText',
//   'authorImage',
//   'files',
//   'teaserForListView',
//   'category',
//   'short_description',
//   'description',
// ];

const CourseAxios = axios.create({
  headers: {'X-Grape-Lang': localStorage.getItem('language')},
  withCredentials: true,
});

function* getCompetenceById(action) {
  const {cid} = action.payload;

  const fields = defaultFieldsCompetences.join(',') + ',description';

  const params = encodeURI(stringifyUrlParams({
    fields,
    view: 'full',
  }));

  const competence = yield retry(() =>
    CourseAxios
      .request({
        method: 'GET',
        url: `${backendUrl}/api/competences/${cid}?${params}`,
        withCredentials: true,
      })
      .then(response => response.data.competences));

  return {
    ...competence[0],
    cid: competence[0].id,
  };
}

function* getCompetencegroupChildren(action) {
  const {competencegroupId} = action?.payload || {};

  const configObject = yield select(getConfigObject);
  const configForCurrLangAndTrack = yield select(getPropertiesForCurrLangAndTrack);

  const allowChildCompetenceForGroupIds = configObject.getProperty('routes.course-catalog.allowChildCompetenceForGroupIds') || [];

  try {
    const types = 'complex,ecourse,equivalents,course,content,nano,track';

    const fields = (
      action && action.payload && action.payload.fields
      || defaultFieldsCompetences
    ).join(',');

    const baseCompetenceGroupId = configForCurrLangAndTrack?.courseCatalog?.startAtGroupId;

    const params = encodeURI(stringifyUrlParams({
      fields,
      view: 'full',
      'competence_group_ids[]': competencegroupId || baseCompetenceGroupId || '',
      'types[]': types,
    }));

    yield put(CA.coursesGetCompetencegroupChildren.request({competencegroupId}));

    try {
      const limit = 100;

      const {competenceIds, data} = yield retry(() =>
        CourseAxios
          .request({
            method: 'GET',
            params: {
              children: '1',
              limit: limit + 1,
            },
            url: `${backendUrl}/api/competences/${params ? '?' + params : ''}`,
          })
          .then(response => {
            const {competences} = response.data;

            const ids = [];
            const data = {};

            if (!competences) return {
              competenceIds: ids,
              data,
            };

            const filtered = !!competencegroupId && !allowChildCompetenceForGroupIds.includes(competencegroupId)
              ? competences.filter(c => !c.has_parents)
              : competences;

            filtered.forEach(competence => {
              ids.push(competence.id);
              data[competence.id] = competence;
            });

            return {
              competenceIds: ids,
              data,
            };
          }));

      yield put(CA.coursesGetCompetencegroupChildren.success({
        ids: competenceIds,
        data,
        competencegroupId,
      }));
    } catch (error) {
      console.error(error);
      yield put(CA.coursesGetCompetencegroupChildren.failure({error}));
    }
  } catch (error) {
    console.error(error);
  }
}

function* loadCompetencegroup(action) {
  const {competencegroupId, subCategoryIds} = action?.payload || {};

  if (!competencegroupId) return;

  const groupedCompetences = yield select(getGroupedCompetenceIds);
  const toplevelGroups = yield select(getNormalizedCompetencegroups);

  const isLoaded = !!groupedCompetences?.data?.[competencegroupId];

  if (!isLoaded) {
    const isLoadingTopLevelGroups = toplevelGroups?.status === T.LoadStatuses.IS_LOADING;

    if (isLoadingTopLevelGroups) {
      yield take([
        `${CA.coursesGetCompetencegroupsList.success}`,
        `${CA.coursesGetCompetencegroupsList.failure}`,
      ]);
    }

    const action = {
      payload: {
        competencegroupId,
        subCategoryIds,
      },
    };

    try {
      yield put(CA.coursesLoadCompetencegroup.request({competencegroupId}));
      yield call(getCompetencegroupChildren, action);
      yield put(CA.coursesLoadCompetencegroup.success({competencegroupId}));
    } catch (error) {
      console.error(error);
    }
  } else {
    yield put(CA.coursesLoadCompetencegroup.success({
      competencegroupId,
      subCategoryIds,
    }));
  }
}

function* loadCompetencegroupsSubCategories(action) {
  const {parentGroupIds, subCategoryIds} = action?.payload || {};

  if (!parentGroupIds?.length && !subCategoryIds?.length) return;

  const groupedCompetences = yield select(getGroupedCompetenceIds);

  const groupsNotLoaded = groupedCompetences?.data
    ? [...parentGroupIds || [], ...subCategoryIds || []].filter(id => !groupedCompetences?.data?.[id])
    : [...parentGroupIds || [], ...subCategoryIds || []];

  if (groupsNotLoaded.length) {
    const mapToAction = competencegroupId => call(getCompetencegroupChildren, {payload: {competencegroupId}});

    try {
      yield put(CA.coursesLoadCompetencegroupsSubcategories.request({
        parentGroupIds,
        subCategoryIds,
      }));
      yield all(groupsNotLoaded.map(mapToAction));
      yield put(CA.coursesLoadCompetencegroupsSubcategories.success({
        parentGroupIds,
        subCategoryIds,
      }));
    } catch (error) {
      console.error(error);
    }
  } else {
    yield put(CA.coursesLoadCompetencegroupsSubcategories.success({
      parentGroupIds,
      subCategoryIds,
    }));
  }
}

function* getCompetencegroupsList(action) {
  yield put(CA.coursesGetCompetencegroupsList.request());

  const configObject = yield select(getConfigObject);
  const configForCurrLangAndTrack = yield select(getPropertiesForCurrLangAndTrack);

  const configStartAtGroupId = configForCurrLangAndTrack?.courseCatalog?.startAtGroupId;

  const baseCompetenceGroupId = configObject?.isMapActivated
    ? !!configStartAtGroupId && configStartAtGroupId
    : configObject?.getProperty?.('routes.course-catalog.startAtGroupId') || configStartAtGroupId;

  const disallowedGroupIds = configObject?.getProperty?.('routes.course-catalog.disalowwedCompetenceGroupIds') || [];

  try {
    const {
      data,
      ids: competencegroupIds,
      subCategoriesByGroupId,
    } = yield retry(() =>
      CourseAxios.request({
        method: 'GET',
        url: `${backendUrl}/api/competencegroups${
          baseCompetenceGroupId
            ? `/${baseCompetenceGroupId}`
            : ''
        }`,
        params: {
          fields: 'color,title,files,children(title)',
          ...action?.payload?.params || {},
        },
      }).then(response => {
        const groups = response?.data?.competencegroups
          ? baseCompetenceGroupId
            ? response?.data?.competencegroups?.[0]?.children
            : response?.data?.competencegroups
          : {};

        if (!groups?.length) return {};

        const ids = [];
        const data = {};
        const subCategoriesByGroupId = {};

        groups.filter(({id}) => !disallowedGroupIds?.includes(id))?.forEach?.(group => {
          const {id: groupId} = group;

          ids.push(groupId);

          const subCategories = group?.children?.reduce(getChildrenDataRecurse, []);

          subCategoriesByGroupId[groupId] = subCategories;

          const subCategoryIds = subCategories?.map(({id}) => id);

          data[groupId] = {
            ...group,
            subCategoryIds,
          };
        });

        return {
          ids,
          data,
          subCategoriesByGroupId,
        };
      }));

    yield put(CA.coursesGetCompetencegroupsList.success({
      ids: competencegroupIds,
      data: data || [],
      subCategoriesByGroupId,
    }));

    if (competencegroupIds !== undefined) {
      const defaultCompetencegroupId = yield select(getCourseCatalogDefaultCompetencegroup);

      const competencegroupId = defaultCompetencegroupId || competencegroupIds[0];

      yield call(loadCompetencegroup, {
        payload: {
          competencegroupId,
          subCategoryIds: subCategoriesByGroupId[competencegroupId],
        },
      });
    }
  } catch (error) {
    console.error(error);
    yield put(CA.coursesGetCompetencegroupsList.failure({error}));
  }
}

function* getCompetences(action) {
  try {
    const searchTerm = yield select(getCompetencesSearchTerm) || null;

    yield searchTerm
      ? put(CA.coursesUpdateSearchResults.request({}))
      : put(CA.coursesUpdateSearchResults.success({reset: true}));

    const selectedSubcompetencegroupId = yield select(getSelectedSubcompetencegroupId);

    const selectedSubSubcompetencegroupId = yield select(getSelectedSubSubcompetencegroupId);

    const selectedCompetencetypes = [
      ...yield select(getSelectedCompetencetypes),
    ];
    const selectedCompetencegroupId = [
      yield select(getSelectedCompetencegroupId),
    ];

    const configObject = yield select(getConfigObject);

    const types
      = selectedCompetencetypes.length && selectedCompetencetypes.join(',')
      || 'complex,ecourse,equivalents,course,content,nano,track';

    const fields = (
      action && action.payload && action.payload.fields
      || defaultFieldsCompetences
    ).join(',');

    const configForCurrLangAndTrack = yield select(getPropertiesForCurrLangAndTrack);

    const baseCompetenceGroupId = configForCurrLangAndTrack?.courseCatalog?.startAtGroupId;

    let params = encodeURI(stringifyUrlParams({
      fields,
      view: 'full',
      'competence_group_ids[]':
          selectedSubSubcompetencegroupId
          || selectedSubcompetencegroupId
          || selectedCompetencegroupId
          || baseCompetenceGroupId
          || '',
      'types[]': types,
    }));

    const disalowwedCompetenceGroupIds
      = configObject.getProperty('routes.course-catalog.disalowwedCompetenceGroupIds') || [];
    const allowChildCompetenceForGroupIds
      = configObject.getProperty('routes.course-catalog.allowChildCompetenceForGroupIds') || [];

    if (params) {
      params = `?${params}`;
    }

    yield put(CA.coursesGetCompetences.request());

    try {
      const limit = 100;

      const competences = yield searchTerm
        ? retry(() =>
          CourseAxios
            .request({
              method: 'GET',
              params: {
                term: searchTerm,
                not_course_groups: disalowwedCompetenceGroupIds.join(','),
              },
              url: `${backendUrlV2}/competences/search`,
            })
            .then(response => response.data))
        : retry(() =>
          CourseAxios
            .request({
              method: 'GET',
              params: {
                children: '1',
                limit: limit + 1,
              },
              url: `${backendUrl}/api/competences/${params}`,
            })
            .then(response => response.data.competences));

      let filter_out = null;

      filter_out = selectedCompetencegroupId
        && !allowChildCompetenceForGroupIds.includes(selectedCompetencegroupId[0])
        ? competences.filter(c => !c.has_parents)
        : competences;

      let hasMore = false;

      if (filter_out.length >= limit) {
        hasMore = true;
        filter_out = filter_out.slice(0, limit - 1);
      }
      yield put(CA.coursesGetCompetences.success({
        competences: filter_out,
        hasMore,
      }));

      if (searchTerm) {
        yield put(CA.coursesUpdateSearchResults.success({
          competences: filter_out,
          hasMore,
          searchTerm,
        }));
      }
    } catch (error) {
      console.error(error);
      yield put(CA.coursesGetCompetences.failure({error}));
    }
  } catch (error) {
    console.error(error);
  }
}

function* getFeaturedCompetences(action) {
  const featuredCompetenceIdsToGet
    = action && action.payload && action.payload.cids;

  yield put(CA.coursesGetFeaturedCompetences.request());

  try {
    const featuredCompetences = yield all(featuredCompetenceIdsToGet.map(cid =>
      call(getCompetenceById, {payload: {cid}})));

    yield put(CA.coursesGetFeaturedCompetences.success({featuredCompetences}));
  } catch (error) {
    console.error(error);
    yield put(CA.coursesGetFeaturedCompetences.failure({error}));
  }
}

export function getCourseEventsAPI(payload) {
  const fields = [
    'short_description',
    'title',
    'startdate',
    'competence_type',
    'competence_id',
    'enddate',
    'available_count',
    'participants_count',
    'sign_on_deadline',
    'sign_off_deadline',
    'max_participants',
    'waitlist',
    'confirmed',
    // 'description',
    'confirmed(profile_image,fullname,firstname,lastname,user_name)',
    'waitlist',
    'person',
    'location',
  ].join(',');

  let params = '';

  params = payload.userName
    ? encodeURI(stringifyUrlParams({
      fields,
      view: 'full',
      waitlist: 1,
      confirmed: 1,
      user_name: payload.userName,
    }))
    : encodeURI(stringifyUrlParams({
      fields,
      view: 'full',
    }));

  if (params) {
    params = `?${params}`;
  }
  let gourl = `${backendUrl}/api/events${params}`;

  if (payload.userName) {
    gourl = `${backendUrl}/api/personevents${params}`;
  }

  return CourseAxios
    .request({
      method: 'get',
      url: gourl,
      params: {limit: 100},
      withCredentials: true,
    })
    .then(function (response) {
      return response.data;
    });
}

export function* updateCourseEvents(action) {
  const {events, partial, remove} = action.payload || {};

  if (!events?.length) {
    if (partial) {
      return;
    }

    yield put(CA.coursesUpdateEvents.success({empty: true}));

    return;
  }

  // partially update events, without mutating
  if (partial) {
    const prevState = yield select(getNormalizedCourseEvents);

    const {
      allEventIds: prevAllEventIds,
      eventById: prevEventById,
      eventIdsByCourseId: prevEventIdsByCourseId,
      eventIdsByYearMonth: prevEventIdsByYearMonth,
    } = prevState;

    const reducerFn = remove
      ? reduceEventsPartialRemoval
      : reduceEventsPartialAdd;

    const normalized = events.reduce(reducerFn, {
      allEventIds: new Set(prevAllEventIds),
      eventById: {...prevEventById},
      eventIdsByCourseId: {...prevEventIdsByCourseId},
      eventIdsByYearMonth: {...prevEventIdsByYearMonth},
    });

    yield put(CA.coursesUpdateEvents.success(freeze({
      ids: [...normalized.allEventIds],
      eventById: normalized.eventById,
      eventIdsByCourseId: normalized.eventIdsByCourseId,
      eventIdsByYearMonth: normalized.eventIdsByYearMonth,
      partial: true,
    }), true));

    return;
  }
  // if not partial update: fully replace events in store
  const normalized = events.reduce(reduceEventsFullUpdate, {
    allEventIds: new Set(),
    eventById: {},
    eventIdsByCourseId: {},
    eventIdsByYearMonth: {},
  });

  yield put(CA.coursesUpdateEvents.success(freeze({
    ids: [...normalized.allEventIds],
    eventById: normalized.eventById,
    eventIdsByCourseId: normalized.eventIdsByCourseId,
    eventIdsByYearMonth: normalized.eventIdsByYearMonth,
    // temp to not break existing code. todo: remove
    // nonNormalizedData: events.sort((a, b) => dayjs(a.startdate).diff(dayjs(b.startdate))),
  }), true));
}

function* getCourseEvents(action) {
  yield put(CA.coursesGetCourseEvents.request());

  try {
    const {events} = yield call(getCourseEventsAPI, {});

    yield call(updateCourseEvents, {payload: {events}});
  } catch (error) {
    console.error(error);
    yield put(CA.coursesGetCourseEvents.failure({error}));
  }
}

function* fetchCompetenceDetails(action) {
  const {cid} = action.payload;

  yield put(CA.coursesCloseCourse());

  const fields = [
    'description',
    'short_description',
    'files',
    'title',
    'course_type',
    'competence_type_id',
    'competence_type',
    'competence_id',
    'competence_type_key',
    'durations',
    'children(competence_type,description,competence_type_key,competence_type_id,title,competence_id,files)',
  ].join(',');

  const fields_events = ['title', 'location'].join(',');

  const params = encodeURI(stringifyUrlParams({
    fields,
    view: 'full',
  }));

  const params_events = encodeURI(stringifyUrlParams({
    fields_events,
    view: 'full',
  }));

  yield put(CA.coursesGetCompetenceDetails.request({cid}));

  try {
    const {data: {competences: [competenceDetails]}} = yield retry(() =>
      CourseAxios.request({
        method: 'GET',
        params: {children: '1'},
        url: `${backendUrl}/api/competences/${cid}?${params}`,
        withCredentials: true,
      }));

    if (competenceDetails.competence_type_id) {
      /*
        this is classroom course, the events for it.
      */
      const {data: {events: eventsDetails}} = yield retry(() =>
        CourseAxios.request({
          method: 'GET',
          params: {children: '1'},
          url: `${backendUrl}/api/competences/${cid}/events?${params_events}`,
        }));

      yield call(updateCourseEvents, {
        payload: {
          events: eventsDetails,
          partial: true,
          remove: false,
        },
      });

      competenceDetails.events = eventsDetails;

      /*
      # if manager, and course details */

      let userName = yield select(getProfileUserName);
      const profile = yield select(getProfile);

      if (userName === null && profile.isFetching === false) {
        yield put(profileFetchPerson());
      }

      while (userName === null) {
        yield take();
        userName = yield select(getProfileUserName);
      }

      const isUserManager = yield select(getIsManager);

      if (isUserManager) {
        const {status: employeesEventsStatus} = yield select(getNormalizedEmployeesEvents);

        if (employeesEventsStatus === T.LoadStatuses.NOT_LOADED) {
          yield put(employeesFetchEvents({}));
        }

        const {status: employeesStatus} = yield select(getNormalizedEmployees);
        const employeesNotLoaded = employeesStatus === T.LoadStatuses.NOT_LOADED;

        if (employeesNotLoaded) yield put(employeesGetEmployees({all: true}));
      }
    }

    competenceDetails.competence_type = {
      competence_type: competenceDetails.competence_type,
      competence_type_id: competenceDetails.competence_type_id,
    };

    if (competenceDetails.children) {
      for (let i = 0; i < competenceDetails.children.length; i += 1) {
        competenceDetails.children[i].competence_type = {
          competence_type: competenceDetails.children[i].competence_type,
          competence_type_id: competenceDetails.children[i].competence_type_id,
        };
      }
    }

    yield put(CA.coursesGetCompetenceDetails.success({
      competenceDetails,
      cid,
    }));
  } catch (error) {
    console.error(error);
    yield put(CA.coursesGetCompetenceDetails.failure({error}));
  }
}

function* loadCompetenceDetails(action) {
  const {cid, refetch} = action.payload;

  if (!cid) return;

  yield put(CA.coursesLoadCompetenceDetails.request());

  try {
    const {data: competenceById} = yield select(getNormalizedCompetenceDetails);

    if (!competenceById[cid] || refetch) {
      yield call(fetchCompetenceDetails, {payload: {cid}});
    }

    yield put(CA.coursesLoadCompetenceDetails.success({cid}));
  } catch (error) {
    console.error(error);
    yield put(CA.coursesGetCompetenceDetails.failure({error}));
  }
}

export const getIncludesPerson = (personsArray, person) => {
  if (!personsArray?.length || !person || !person?.id && !person?.user_name) return false;

  for (const element of personsArray) {
    const {id, user_name} = element || {};

    if (!id && !user_name) continue;
    if (id === person.id || user_name === person.user_name) return true;
  }

  return false;
};

export function* getIncludesProfile(personsArray) {
  if (!personsArray?.length) return false;

  const {data} = yield select(getProfile);
  const {id, user_name} = data || {};

  if (!id && !user_name) return false;

  return getIncludesPerson(personsArray, {
    id,
    user_name,
  });
}

function* courseEventsGetShouldRefetch(signedPersons) {
  if (!signedPersons?.length) return false;

  const includesSelf = yield call(getIncludesProfile, signedPersons);
  const refetchEmployeesEvents = signedPersons.length > 1 || !includesSelf;

  return {
    refetchCourseEvents: true,
    refetchProfileEvents: includesSelf,
    refetchEmployeesEvents,
  };
}

function* courseEventsRefetchIfNeeded({persons: signedPersons, callback}) {
  const shouldRefetch = yield call(courseEventsGetShouldRefetch, signedPersons);

  if (!shouldRefetch) {
    if (callback) callback();

    return;
  }

  yield put(CA.coursesLoadCourseEventsFull({
    ...shouldRefetch,
    callback,
  }));
}

function* courseSignOn(action) {
  const {courseEvent, employees, callback} = action.payload;

  const courseEventId = courseEvent.id;

  const {data: person} = yield select(getProfile);

  const signedEmployees = employees || [person];

  yield put(CA.coursesCourseSignOn.request({ceid: courseEventId}));

  try {
    const results = {
      correct: [],
      errors: [],
    };

    const calls = [];

    for (const signedEmployee of signedEmployees) {
      const {firstname, lastname, user_name} = signedEmployee;
      const fullname = `${firstname} ${lastname}`;
      const user = fullname.trim() !== '' ? fullname : user_name;

      calls.push(() => CourseAxios
        .request({
          method: 'POST',
          url: `${backendUrl}/api/personevents/${courseEventId}?fields=id,waitlist`, // person(person_id,user_name,firstname,lastname,fullname)`,
          params: {user_name},
          withCredentials: true,
        })
        .then(({data}) => {
          results.correct.push({
            user,
            waitlist: data.personevents?.[0]?.waitlist,
            message: data.message,
          });
        })
        .catch(error => {
          console.error(error);
          results.errors.push({
            user,
            error,
          });

          return error;
        }));
    };

    yield all(calls.map(c => c()));

    if (!employees) {
      let notification;

      if (results.correct && results.correct.length > 0) {
        notification = {
          text: results.correct[0].message,
          color: 'green',
        };
      } else if (results.errors && results.errors.length > 0) {
        if (callback) callback('error');

        notification = {
          title: 'Feil',
          text: results.errors[0].error.message,
          color: 'red',
        };
      }

      yield put(notificationsAdd({notification}));
    }

    yield put(CA.coursesCourseSignOnResults({results}));

    yield put(CA.coursesCourseSignOn.success({results}));

    if (callback) callback(results);

    yield call(courseEventsRefetchIfNeeded, {persons: signedEmployees});
  } catch (error) {
    console.error(error);

    if (callback) callback('error');

    yield put(CA.coursesCourseSignOn.failure({error}));
  }
}

function* courseSignOff(action) {
  const {courseEventId, employees, callback} = action.payload;
  const {data: person} = yield select(getProfile);

  const signedEmployees = employees
    ? Array.isArray(employees)
      ? employees
      : [employees]
    : [person];

  yield put(CA.coursesCourseSignOff.request({ceid: courseEventId}));

  try {
    const results = {
      correct: [],
      errors: [],
    };

    yield all(signedEmployees.map(({fullname, user_name}) => {
      const user = fullname && fullname.trim() !== ''
        ? fullname
        : user_name;

      return call(() =>
        CourseAxios
          .request({
            method: 'PUT',
            url: `${backendUrl}/api/personevents/${courseEventId}?fields=id`, // ,person(person_id,user_name,firstname,lastname,fullname)`,
            params: {
              action: 'off',
              user_name,
            },
            withCredentials: true,
          })
          .then(({data}) => {
            results.correct.push({
              user,
              message: data.message,
            });
          })
          .catch(error => {
            console.error(error);
            results.errors.push({
              user,
              error,
            });

            return error;
          }));
    }));

    let notification;

    if (results.correct && results.correct.length > 0) {
      notification = {
        text: results.correct[0].message,
        color: 'green',
      };
    } else if (results.errors && results.errors.length > 0) {
      if (callback) callback('error');

      notification = {
        title: 'Feil',
        text: results.errors[0].error.message,
        color: 'red',
      };
    }

    yield put(notificationsAdd({notification}));

    yield put(CA.coursesCourseSignOff.success({results}));

    if (callback) callback(results);

    yield call(courseEventsRefetchIfNeeded, {persons: signedEmployees});
  } catch (error) {
    if (callback) callback('error');
    console.error(error);
    yield put(CA.coursesCourseSignOff.failure({error}));
  }
}

function* onCourseStart(action) {
  try {
    const {cid, type} = action.payload;

    if (CT.CourseTypes.ecourse.includes(type)) {
      const {valid, lmsstarturls} = yield call(() =>
        axios
          .request({
            method: 'get',
            url: `${backendUrl}/api/lmsstarturls?competence_ids=${cid}&external=1`,
            withCredentials: true,
          })
          .then(response => response.data));

      const [{urls}] = lmsstarturls;

      if (valid === true && urls[0]) {
        // CLEAR THE INFO BOX.
        const open_url = `${urls[0].id}`;
        const win = window.open(open_url, '_blank');

        yield put(CA.coursesRunCourse({
          url: open_url,
          opened: !!win,
        }));
      } else {
        yield put(CA.coursesCourseError('no valid url for course'));
      }
    } else if (CT.CourseTypes.nano.includes(type)) {
      const nanoCourseStartUrl = `${nanoLearningUrl}?id=${cid}`;

      yield put(CA.coursesRunNanoCourse({
        url: nanoCourseStartUrl,
        cid,
        type,
      }));
    } else {
      const iframeMessageChannel = yield call(() =>
        eventChannel(emmiter => {
          window.addEventListener('message', ({data}) => {
            if (
              data === 'IFRAME_COURSE_FINISHED'
              || data === 'TAB_COURSE_FINISHED'
            ) {
              emmiter();
              emmiter(END);
            }
          });

          return () => {
            window.removeEventListener('message');
          };
        }));

      yield take(iframeMessageChannel);
      yield put(CA.coursesCourseFinished({cid}));
    }
  } catch (error) {
    console.error(error);
    yield put(CA.coursesCourseFailure({error}));
  }
}

function* onCourseSign(action) {
  const {courseId, password, checked} = action.payload;

  const bodyFormData = new FormData();

  bodyFormData.set('formIndex', '0');

  if (!checked) {
    bodyFormData.set('password', password);
  } else {
    bodyFormData.set('checked', '1');
  }

  let status;

  try {
    const data = yield call(() =>
      CourseAxios
        .request({
          method: 'POST',
          url: `${backendUrl}/courses/sign_digitally/${courseId}`,
          data: bodyFormData,
          config: {headers: {'Content-Type': 'multipart/form-data'}},
        })
        .then(response => response.data));

    status = data.status;

    if (data.statuscode === -1) {
      yield put(CA.coursesSignCourse.success({
        status,
        courseId,
      }));

      yield put(notificationsAdd({
        notification: {
          text: status,
          color: 'green',
        },
      }));

      yield put(CA.coursesBeginSignature(null));
      yield put(profileUpdatePassedCompetences({skipPageReload: true}));
    } else {
      if (status) {
        yield put(notificationsAdd({
          notification: {
            text: status,
            color: 'red',
          },
        }));
      }
      yield put(CA.coursesSignCourse.failure());
    }
  } catch (error) {
    console.error(error);

    if (status) {
      yield put(notificationsAdd({
        notification: {
          text: status,
          color: 'red',
        },
      }));
    }
    yield put(CA.coursesSignCourse.failure({error}));
  }
}

export function* courseCatalogFetchNews(action) {
  yield put(CA.coursesFetchCourseCatalogNews.request());

  const {ids, limit = 50} = action.payload;

  try {
    // old endpoint:
    // const catalogNews = yield all(ids.flatMap(id =>
    //   retry(() =>
    //     CourseAxios
    //       .request({
    //         method: 'GET',
    //         url: `${backendUrl}/api/cms/${id}/pages?fields=teaser,body,image,category,author`,
    //       })
    //       .then(res => res?.data?.pages || []))));

    // yield put(CA.coursesFetchCourseCatalogNews.success({data: catalogNews.flat() || []}));

    // new endpoint
    // (should we use the same id as news on frontpage?)
    const catalogNews = yield call(cmsFetchContentListAPI, ids, limit);

    yield put(CA.coursesFetchCourseCatalogNews.success({data: catalogNews}));
  } catch (error) {
    console.error(error);
    yield put(CA.coursesFetchCourseCatalogNews.failure({error}));
  }
}

export function* loadCourseEventsFull(action) {
  const {
    refetchCourseEvents,
    refetchEmployees,
    refetchEmployeesEvents,
    refetchProfileEvents,
    callback,
  } = action?.payload || {};

  const toTake = [
    refetchCourseEvents && take(`${CA.coursesUpdateEvents.success}`),
    refetchEmployees && take(`${employeesGetEmployees.success}`),
    refetchEmployeesEvents && take(`${employeesFetchEvents.success}`),
    refetchProfileEvents && take(`${profileFetchPersonEvents.success}`),
  ].filter(Boolean);

  try {
    const {status: allEventsStatus} = yield select(getNormalizedCourseEvents);

    const allEventsNotLoaded = allEventsStatus === T.LoadStatuses.NOT_LOADED;

    if (allEventsNotLoaded || refetchCourseEvents) {
      yield put(CA.coursesGetCourseEvents());
    }

    const {status: profileEventsStatus} = yield select(getNormalizedProfileEvents);

    const profileEventsNotLoaded = profileEventsStatus === T.LoadStatuses.NOT_LOADED;

    if (profileEventsNotLoaded || refetchProfileEvents) {
      yield put(profileFetchPersonEvents());
    }

    const isManager = yield select(getIsManager);

    if (isManager) {
      const {status: employeesEventsStatus} = yield select(getNormalizedEmployeesEvents);
      const {status: employeesStatus} = yield select(getNormalizedEmployees);

      const employeesEventsNotLoaded = employeesEventsStatus === T.LoadStatuses.NOT_LOADED;
      const employeesNotLoaded = employeesStatus === T.LoadStatuses.NOT_LOADED;

      const shouldFetchEmployees = employeesNotLoaded || refetchEmployees;
      const shouldFetchEmployeesEvents = employeesEventsNotLoaded || refetchEmployeesEvents;

      if (shouldFetchEmployees) {
        yield put(employeesGetEmployees({all: true}));
      }
      if (shouldFetchEmployeesEvents) {
        yield put(employeesFetchEvents({}));
      }
    }
    if(toTake.length) {
      yield all(toTake);
    }
    if (callback) {
      callback('success');
    }
    yield put(CA.coursesLoadCourseEventsFull.success({}));
  } catch (error) {
    console.error(error);
    if (callback) {
      callback('error');
    }
    yield put(CA.coursesLoadCourseEventsFull.failure({error}));
  }
}

const exportObj = [
  takeEvery(`${CA.coursesCourseSignOn}`, courseSignOn),
  takeEvery(`${CA.coursesCourseSignOff}`, courseSignOff),
  takeLatest(`${CA.coursesGetCompetences}`, getCompetences),
  takeLatest(
    `${CA.coursesGetFeaturedCompetences}`,
    getFeaturedCompetences,
  ),
  debounce(400, `${CA.coursesSetSearchterm}`, getCompetences),
  takeLatest(
    `${CA.coursesGetCompetencegroupChildren}`,
    getCompetencegroupChildren,
  ),
  takeLatest(
    `${CA.coursesLoadCompetencegroup}`,
    loadCompetencegroup,
  ),
  takeLatest(
    `${CA.coursesLoadCompetencegroupsSubcategories}`,
    loadCompetencegroupsSubCategories,
  ),
  takeLatest(
    `${CA.coursesGetCompetencegroupsList}`,
    getCompetencegroupsList,
  ),
  takeLatest(`${CA.coursesGetCourseEvents}`, getCourseEvents),
  takeLatest(
    `${CA.coursesFetchCourseCatalogNews}`,
    courseCatalogFetchNews,
  ),
  takeLatest(`${CA.coursesStartCourse}`, onCourseStart),
  takeEvery(`${CA.coursesSignCourse}`, onCourseSign),
  takeLatest(
    `${CA.coursesLoadCourseEventsFull}`,
    loadCourseEventsFull,
  ),
  takeLatest(`${CA.coursesUpdateEvents}`, updateCourseEvents),
  takeLatest(`${CA.coursesLoadCompetenceDetails}`, loadCompetenceDetails),
];

export default exportObj;
