import axios from 'axios';
import {call} from 'ramda';
import {put, select, takeLatest} from 'redux-saga/effects';
import * as cmsActions from '@actions/cms.actions';
import {backendUrl} from '@config';
import {getSelectedParentPage} from '@selectors/cms.selectors';
import retry from '@utils/sagas.utils';

export const formatCmsContentListItems = competences => competences.map(({
  id: competence_id,
  title: competence_title,
  files: competence_files,
  cover: competence_cover,
  content,
}) => {
  const {
    files: contentFiles,
    body,
    teaser,
    id: contentId,
    title: contentTitle,
  } = content || {};

  const cover = competence_cover || competence_files?.[0] || contentFiles?.[0];

  return {
    id: contentId || competence_id,
    title: competence_title || contentTitle,
    body,
    teaser,
    cover,
    image: cover?.url,
    competence_id,
    content_id: contentId,
    files: competence_files || contentFiles,
  };
});

export function* cmsFetchContentListAPI(competenceGroupIds, limit = 10) {
  const data = yield retry(() =>
    axios
      .request({
        method: 'GET',
        url: `${backendUrl}/api/competences/`,
        params: {
          types: 'content',
          fields: 'title,content(body,image,teaser,template),files',
          limit,
          competence_group_ids: competenceGroupIds.join(','),
        },
      })
      .then(({data}) => {
        const {competences} = data;

        return formatCmsContentListItems(competences);
      }));

  return data;
}

export function* cmsGetContentListSaga(action) {
  const {id: payloadId, onSuccess, limit = 10} = action?.payload || {};

  const id = payloadId
    ? payloadId
    : yield select(getSelectedParentPage) || null;

  yield put(cmsActions.cmsGetchildrenRequest());

  try {
    // const children = yield retry(() =>
    //   axios
    //     .request({
    //       method: 'GET',
    //       url: `${backendUrl}/api/cms/${id}/pages`, // ?t=${Date.now()}`, // this is cached, add t? + Date.now() for nocache
    //       withCredentials: true,
    //     })
    //     .then(response => response.data));

    const children = yield call(cmsFetchContentListAPI, [id], limit);

    if (children?.length) {
      yield put(cmsActions.cmsGetchildrenSuccess({children}));
    }

    if (onSuccess) onSuccess(children);
  } catch (error) {
    console.error(error);
    yield put(cmsActions.cmsGetchildrenFailure({error}));
  }
}

function* getContent(action) {
  const {contentId} = action.payload;

  yield put(cmsActions.cmsgGetContentRequest({contentId}));

  try {
    const content = yield retry(() =>
      axios
        .request({
          method: 'GET',
          url: `${backendUrl}/api/cms/${contentId}?fields=body,teaser,title,image,video,imageCaption,imageAltText`, // t=${Date.now()}`,
          withCredentials: true,
        })
        .then(response => response.data?.pages?.[0]));

    yield put(cmsActions.cmsGetContentSuccess({
      content,
      contentId,
    }));
  } catch (error) {
    console.error(error);
    yield put(cmsActions.cmsGetContentFailure({contentId}));
  }
}

const exportObj = [
  takeLatest(cmsActions.CMS_GET_CHILDREN, cmsGetContentListSaga),
  takeLatest(cmsActions.CMS_GET_CONTENT, getContent),
];

export default exportObj;
