import React, {Component, useEffect} from 'react';
import classNames from 'classnames';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {BrowserRouter as Router, Redirect, Route, Switch, useHistory} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import {
  authGetAuthStatusAndStartLoading,
  authLogoutRequest,
  authStartLoginCheckOnInterval,
} from '@actions/auth.actions';
import {coursesCourseFinished} from '@actions/courses.actions';
import {notificationsHide} from '@actions/notifications.actions';
import {routerAppComponentDidMount} from '@actions/router.actions';
import ClearCache from '@components/clear-cache/clear-cache';
import SignatureModal from '@components/course/signature-modal';
import CourseLoader from '@components/loading/courseLoader';
import Loading from '@components/loading/loading';
import Menu from '@components/menu/menu';
import Notifications from '@components/notifications/notifications';
import {allowedLanguages, buildHash, nanoLearningLoginUrl, version} from '@config';
import {css} from '@emotion/react';
import {library} from '@fortawesome/fontawesome-svg-core';
import {faAt} from '@fortawesome/pro-solid-svg-icons/faAt';
import {faCalendar} from '@fortawesome/pro-solid-svg-icons/faCalendar';
import {faCalendarAlt} from '@fortawesome/pro-solid-svg-icons/faCalendarAlt';
import {faChevronDown} from '@fortawesome/pro-solid-svg-icons/faChevronDown';
import {faChevronRight} from '@fortawesome/pro-solid-svg-icons/faChevronRight';
import {faChevronUp} from '@fortawesome/pro-solid-svg-icons/faChevronUp';
import {faFileExcel} from '@fortawesome/pro-solid-svg-icons/faFileExcel';
import {faHourglass} from '@fortawesome/pro-solid-svg-icons/faHourglass';
import {faPlus} from '@fortawesome/pro-solid-svg-icons/faPlus';
import {faRedo} from '@fortawesome/pro-solid-svg-icons/faRedo';
import {faThumbsUp} from '@fortawesome/pro-solid-svg-icons/faThumbsUp';
import {faUser} from '@fortawesome/pro-solid-svg-icons/faUser';
import {faUserCheck} from '@fortawesome/pro-solid-svg-icons/faUserCheck';
import loadable from '@loadable/component';
import {EmployeesModuleInjector} from '@routes/admin/common/components/module-wrapper';
import {PrivateRoute} from '@routes/auth';
import {Dummy} from '@routes/dummy/dummy';
import {
  atlas as AtlasRoutes,
  courseCatalog as courseCatalogRoutes,
  dashboard,
  employees as employeesRoutes,
  help as helpRoutes,
  learningPath,
  logout,
  manage as manageRoutes,
  myEducation as myEducationRoutes,
  onboarding,
} from '@routes/routes.manifest';
import {getAuthStatus, getStartUrl} from '@selectors/auth.selectors';
import {getConfigObject, getIsConfigLoaded} from '@selectors/config.selectors';
import {getActiveCourse} from '@selectors/courses.selectors';
import {getIsMobile} from '@selectors/global.selectors';
import {getIsMapCompleted} from '@selectors/map.selectors';
import {getShownNotifications} from '@selectors/notifications.selectors';
import {getProfile, isManager as getIsManager} from '@selectors/profile.selectors';
import {i18n} from '@src/i18n';
import {IdentityWarning, NoticeBanner} from '@styles/mixins';
import {configObjectShape} from '@types/config';
import {getTwoletterLangCodeFromNavigatorLanguage} from '@utils/misc.utils';
import {ResponsiveStyles} from './styles/responsive-styles';
import {env} from './env';
import '@styles/app.scss';
import '@styles/foundation.min.css';
import 'dayjs/locale/nb'; // import locale
import 'focus-visible';

dayjs.extend(weekOfYear);

const CvContainer = loadable(async () => {
  console.log('load cv.container');

  return /* webpackChunkName: "cv.container" */ import('./routes/my-education/containers/my-education-cv/cv-container');
});


const LoginContainer = loadable(async () => {
  console.log('load login.container');

  return import(/* webpackChunkName: "login.container" */ './routes/auth/containers/login-container');
});

const HelpContainer = loadable(async () => {
  console.log('load help');

  return import(/* webpackChunkName: "help.container" */ './routes/help/containers/help-container/help-container');
});

const AdminSwitch = loadable(async () => {
  console.log('load admin.switch');

  return import(/* webpackChunkName: "admin.switch" */ './routes/admin');
});

const CourseCatalogSwitch = loadable(async () => {
  console.log('load course.catalog');

  return import(/* webpackChunkName: "course.catalog" */ './routes/course-catalog/course-catalog-switch');
});

const MessageCheck = loadable(async () => {
  console.log('load message.check');

  return import(/* webpackChunkName: "message.check" */ './common/components/message-check/message-check');
});


const MyEducationSwitch = loadable(async () => {
  console.log('load myEducation.switch');

  return import(/* webpackChunkName: "my.education" */ './routes/my-education/my-education-switch');
});

const NanoLearningPlayerContainer = loadable(async () =>
  import(/* webpackChunkName: "nano.player" */ './routes/nano-learning/containers/nano-learning-player-container'));

const CoursePlayer = loadable(async () =>
  import(/* webpackChunkName: "course.player" */ './routes/course-catalog/components/course-player/course-player'));


const EmployeesContainer = loadable(async () => {
  console.log('load employees.container');

  return import(/* webpackChunkName: "employees" */ './routes/employees/containers/employees-container');
});

const EmployeeContainer = loadable(async () => {
  console.log('load employee.container');

  return import(/* webpackChunkName: "employee" */ './routes/employees/containers/employee-container');
});

const Atlas = loadable(async () => {
  console.log('load Atlas');

  return import(/* webpackChunkName: "atlas" */ './routes/atlas/index');
});

const ReportSwitch = loadable(async () => {
  console.log('load up report.switch')
  const {ReportSwitch} = await import(/* webpackChunkName: "report.switch" */ './routes/reports/report-switch');

  return props => <ReportSwitch {...props} />;
});

const LearningPathSwitch = loadable(() => {
  console.log('load learning.path');

  return import(/* webpackChunkName: "learning-path" */ './routes/learning-path/learning-path-switch');
});

// makes history from react-router visible from outside
// this can example be used to change url without reloading the page
const CypressHistorySupport = () => {
  const history = useHistory();

  useEffect(() => {
    window.cyHistory = history;
  }, []);

  useEffect(() => {
    window.cyHistory = history;
  }, [history]);

  return null;
};

library.add(
  faChevronRight,
  faThumbsUp,
  faUser,
  faPlus,
  faHourglass,
  faCalendar,
  faAt,
  faRedo,
  faCalendarAlt,
  faUserCheck,
  faChevronUp,
  faChevronDown,
  faFileExcel,
);

const employeesContainerRoutes = [...Object.values(employeesRoutes), dashboard.userPreview];
const cvContainerRoutes = [myEducationRoutes.cvEdit.path, myEducationRoutes.cvAdd.path];
const employeeRoutes = [employeesRoutes.employeePreview, dashboard.userPreview];
const learningPathRoutes = [learningPath.main.path, learningPath.track.path, onboarding.main.path, onboarding.track.path];
const _helpRoutes = [helpRoutes.search, helpRoutes.item, helpRoutes.startpage];

class App extends Component {
  componentDidMount() {
    const {authStartLoginCheckOnInterval, authGetAuthStatusAndStartLoading} = this.props;

    this.setLanguage();

    if (buildHash || version) {
      // eslint-disable-next-line no-console
      console.log(`${buildHash} v. ${version}\n${window.navigator.userAgent}`);
    }

    // the process is like this:
    // 1. check if the user is logged in or not, display loading while we wait
    // 2. if not logged in: display login, else: continue to step 3
    // 3. get config, display loading while we wait
    // 4. when the config is done loading, load the rest
    authGetAuthStatusAndStartLoading();

    // check if the user is still logged in
    authStartLoginCheckOnInterval({intervalMs: 1000 * 60 * 60});
  }

  setLanguage = () => {
    const langCodeNavigator = getTwoletterLangCodeFromNavigatorLanguage();
    let locale = localStorage.getItem('language');

    if (langCodeNavigator && !locale) {
      locale = langCodeNavigator;
    }

    if (!allowedLanguages.includes(locale)) {
      locale = allowedLanguages[0];
    }

    localStorage.setItem('language', locale);

    const dayjsLocale = {
      no: 'nb',
      en:'en-us',
    }[locale];

    dayjs.extend(customParseFormat);
    dayjs.locale(dayjsLocale);
  }

  onEndCourseClick = () => {
    const {coursesCourseFinished, activeCourse} = this.props;

    let my_section = 'first-page';

    if (window.location.pathname.includes('/role')) {
      my_section = 'roles';
    }

    coursesCourseFinished({
      cid: activeCourse.cid,
      section: my_section,
    });
  }

  handleToggleParams = () => {
    const isSetMapLocation
      = window.location.pathname.includes('/set-atlas');

    const isLoadConfig
      = window.location.pathname.includes('/load-config');

    if (isSetMapLocation) {
      const track = window.location.pathname.split('/')[2];
      const params = new URL(document.location).searchParams;

      const langId = params.get('lang');

      localStorage.setItem('track', track);
      localStorage.setItem('language', langId || 'no');
      Object.entries(localStorage).forEach(([k, v]) => {
        if (/^track-data\/\d+$/.test(k)) {
          localStorage.removeItem(k);
        }
      });
    }

    if (isLoadConfig) {
      const config = window.location.pathname.split('/')[2];

      localStorage.setItem('config', config);
      localStorage.setItem('track', config);
      localStorage.removeItem('learningportalConfig');
      window.location = '/';
    }
  }

  getRedirectRoute = () => {
    const {
      startUrl,
      configObject,
      isMapCompleted,
    } = this.props;

    const useMap = configObject.isMapActivated;

    const defaultRoute = startUrl !== '/'
      && startUrl
      || configObject.getProperty('params.default-route');

    if (useMap) {
      if(isMapCompleted) {
        return configObject.isModuleEnabled('coursecatalog')
          ? courseCatalogRoutes.main.path
          : defaultRoute || AtlasRoutes.main.path;
      }

      return defaultRoute || AtlasRoutes.main.path;
    }

    return defaultRoute || courseCatalogRoutes.main.path;
  };

  handleLogout = () => {
    const {authLogoutRequest} = this.props;

    authLogoutRequest();
  };

  render() {
    const {
      sessionId,
      profile,
      alert,
      activeCourse,
      notifications,
      secondLogin,
      hideNotification,
      configObject,
      authStatus,
      isManager,
      loadedConfig,
    } = this.props;

    this.handleToggleParams();

    const isProd = process?.env?.NODE_ENV === 'production';

    if (
      !Cookies.get('tg-visit')
      || !sessionId
      || sessionId === 'sd'
      || secondLogin
      || profile.error
      || authStatus.isLoggedIn === false
    ) {
      return (
        <Router basename={env.REACT_APP_MOUNT_PATH}>
          {!isProd
          && <CypressHistorySupport />}
          <div className="app-wrapper menu-two">
            {nanoLearningLoginUrl
              ? <LoginContainer to={nanoLearningLoginUrl} />
              : (
                <>
                  <LoginContainer />

                  {!window.location.pathname.includes('login') && !window.location.pathname.includes('logout') && (
                    <Redirect
                      from="*"
                      to={window.location.pathname !== 'login' && '/login?redirect=' + window.location.pathname}
                    />
                  ) || window.location.pathname.includes('logout') && (
                    <Redirect
                      from="*"
                      to="/login"
                    />
                  )}
                </>
              )}

            <Notifications
              notifications={notifications}
              onHideNotification={hideNotification}
            />
          </div>
        </Router>
      );
    }

    const isReady = loadedConfig && !!authStatus.isLoggedIn;

    if (!isReady) return <CourseLoader appload />;

    const redirectRoute = this.getRedirectRoute();

    return (
      <>
        {isReady && (
          <Router basename={process.env.REACT_APP_MOUNT_PATH}>
            {!isProd && <CypressHistorySupport />}
            {activeCourse && activeCourse.type === 24 && <NanoLearningPlayerContainer courseId={activeCourse.cid} /> || (
              <ResponsiveStyles
                data-testid="main-app-wrapper"
                className={classNames('app-wrapper', configObject.getProperty('params.menu.type') || 'menu-two')}
              >
                <div
                  key="portal-above"
                  id="portal-above"
                />
                <div
                  key="side-modal"
                  id="side-modal"
                />
                <div
                  key="persons-container"
                  id="persons-container"
                />

                {isManager && <EmployeesModuleInjector />}

                {/* Routes shown above other content */}
                <Route
                  path={cvContainerRoutes}
                  component={CvContainer}
                  breadcrumbs
                />
                <Route
                  path={employeeRoutes}
                  component={EmployeeContainer}
                />

                <div className="app-wrapper__menu">
                  <Menu
                    messagesCount={0}
                    orgId={-1}
                    onLogoutClick={this.handleLogout}
                  />
                </div>

                {activeCourse && activeCourse.status !== 0 && (
                  <div className="app-wrapper__content fullscreen">
                    <CoursePlayer
                      reloading={alert.type === 'alert-reloading'}
                      onEnd={this.onEndCourseClick}
                      id={activeCourse.cid}
                      cid={activeCourse.cid}
                      type={activeCourse.type}
                      url={activeCourse.url}
                      opened={activeCourse.opened}
                      timestamp={activeCourse.timestamp}
                    />
                  </div>
                ) || (
                  <div className="app-wrapper__content">
                    <Switch>
                      {!Cookies.get('tg-visit') || !!profile.error && <LoginContainer />}

                      <Route
                        path={logout}
                        render={this.handleLogout}
                      />
                      <PrivateRoute
                        path={'/admin/*'}
                        component={AdminSwitch}
                      />
                      <PrivateRoute
                        path={'/dashboard/*'}
                        component={ReportSwitch}
                      />
                      <PrivateRoute
                        path={courseCatalogRoutes.main.path}
                        component={CourseCatalogSwitch}
                      />
                      <PrivateRoute
                        path={myEducationRoutes.main.path}
                        component={MyEducationSwitch}
                      />
                      <PrivateRoute
                        path={employeesContainerRoutes}
                        component={EmployeesContainer}
                      />
                      <PrivateRoute
                        path={AtlasRoutes.main.path}
                        component={Atlas}
                      />
                      <PrivateRoute
                        path={learningPathRoutes}
                        component={LearningPathSwitch}
                      />
                      <PrivateRoute
                        path={_helpRoutes}
                        component={HelpContainer}
                      />
                      <PrivateRoute
                        path="/dummy"
                        component={Dummy}
                      />
                      <Redirect to={redirectRoute} />
                    </Switch>
                  </div>
                )}

                <SignatureModal />

                <Notifications
                  notifications={notifications}
                  onHideNotification={hideNotification}
                />

                {alert.type === 'alert-reloading' && (configObject.getProperty('params.reloading-indicator-variant') === 'fullscreen'
                  ? alert.message === 'course-done'
                    && <CourseLoader />
                    || <CourseLoader />
                  : <Loading />
                )}

                {['poll', 'push'].includes(configObject.getProperty('params.mail')) && (
                  <MessageCheck
                    type={configObject.getProperty('params.mail')}
                  />
                )}
                <ClearCache>
                  {({message, isLatestVersion, emptyCacheStorage}) =>
                    !isLatestVersion && (
                      <NoticeBanner>
                        <span>
                          {message || 'Ny versjon er lansert. Klikk refresh for ny versjon.'}
                        </span>
                        <button
                          type="button"
                          className="btn"
                          zIndex="0"
                          onClick={() => {
                            localStorage.removeItem('learningportalConfig');
                            emptyCacheStorage();
                          }}
                        >
                          REFRESH
                        </button>
                      </NoticeBanner>
                    )}
                </ClearCache>
              </ResponsiveStyles>
            )}
          </Router>
        )}
        {Boolean(localStorage.getItem('bart')
          && profile?.data?.person_id.toString() === localStorage.getItem('bart')) && (
            <IdentityWarning>
              {i18n('person.you-have-now')}
              <strong css={css({marginLeft: '.4em'})}>{profile.data.fullname}</strong>
            </IdentityWarning>
        )}
      </>
    );
  }
}

App.propTypes = {
  sessionId: PropTypes.string,
  isMobile: PropTypes.bool.isRequired,
  profile: PropTypes.shape({}).isRequired,
  activeCourse: PropTypes.shape({}),
  isManager: PropTypes.bool.isRequired,
  secondLogin: PropTypes.bool.isRequired,
  coursesCourseFinished: PropTypes.func.isRequired,
  alert: PropTypes.shape({}).isRequired,
  notifications: PropTypes.shape({}).isRequired,
  routerAppComponentDidMount: PropTypes.func.isRequired,
  authLogoutRequest: PropTypes.func.isRequired,
  hideNotification: PropTypes.func.isRequired,
  configObject: configObjectShape,
  isMapCompleted: PropTypes.any,
  // configLoadConfigFromLocalStorage: PropTypes.func.isRequired,
  // configGetConfig: PropTypes.func.isRequired,
  authStatus: PropTypes.shape({}).isRequired,
  startUrl: PropTypes.string,
  authGetAuthStatusAndStartLoading: PropTypes.func.isRequired,
  authStartLoginCheckOnInterval: PropTypes.func.isRequired,
};

App.defaultProps = {
  sessionId: undefined,
  activeCourse: null,
  startUrl: null,
  isMapCompleted: null,
};

const mapStateToProps = state => ({
  sessionId: state.auth.sessionId,
  secondLogin: state.auth.secondLogin,
  alert: state.alert,
  activeCourse: getActiveCourse(state),
  profile: getProfile(state),
  notifications: getShownNotifications(state),
  isManager: getIsManager(state),
  isMobile: getIsMobile(state),
  configObject: getConfigObject(state),
  isMapCompleted: getIsMapCompleted(state),
  startUrl: getStartUrl(state),
  authStatus: getAuthStatus(state),
  loadedConfig: getIsConfigLoaded(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      routerAppComponentDidMount,
      authLogoutRequest,
      coursesCourseFinished,
      hideNotification: notificationsHide,
      authGetAuthStatusAndStartLoading,
      authStartLoginCheckOnInterval,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(App);
