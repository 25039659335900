import {selectors} from '../selectors';
import {units} from './units';
import {containerMaxWidth as getContainerMaxWidth, getSidePadding} from './utils';

/*  sets max-width of child container, with --container-{width}
    usage ex.: <div className="global__wrapper --container-1024"> */
export const maxWidthContainer = [1280, 1080, 1024, 960, 900, 768, 576, 320].map(getContainerMaxWidth);

export const wrapper = `
    ${selectors.global.wrapper} {
        width: 100%;
        max-width: 100%;
        margin: 0;
        padding: 0;
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        ${maxWidthContainer.join('\n')}
    }

    ${selectors.global.heading} {
        margin-bottom: .5em;
    }

    ${selectors.global.subHeading} {
        p:first-of-type {
            margin-top: 0;
        }
    }
`;

export const container = `
    ${selectors.global.container} {
        max-width: 1280px;
        flex: 1;
        width: 100%;
        margin: 0;
        display: flex;
        flex-direction: column;
        padding-left: ${units.desktop.sidePadding};
        padding-right: ${units.desktop.sidePadding};

        > .global__heading {
            margin-top: 0;
        }
    }
`;

export const pageSection = `
    ${selectors.global.pageSection} {
        &.--first {
            margin-top: 0;
        }

        &.--last {
            margin-bottom: 0;
        }
    }
`;

// todo: add this to Header in snapper-core.
// update Header to not render title if empty
const header = `
    .header__title:empty {
        display: none;
    }

    .header.header-wrapper {
        padding-top: 1.5em;
        padding-bottom: 2.5em;

        .header__top {
          padding-bottom: 2em;
        }

        .header__bottom {
            p {
                &:first-of-type {
                    margin-top: 0;
                }
                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }
    }
`;

// todo: add this to Pagination in snapper-core.
const pagination = `
    .pagination,
    .pagination__wrapper {
        width: 100%;
    }
`;

const defaultPadding = `${selectors.utils.defaultPadding} {
    ${getSidePadding(units.desktop.sidePadding)}
}`;

const smallerHeader = `
    ${selectors.utils.smallerHeaderTitle} {
        font-size: 1.75rem;
    }
`;

const noHeaderPadding = `
    ${selectors.utils.noHeaderPadding} {
        .header-container {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
    }
`;

const base = {
  container,
  wrapper,
  maxWidthContainer,
  header,
  defaultPadding,
  smallerHeader,
  noHeaderPadding,
  pageSection,
  pagination,
};

export const baseStyles = Object.values(base).join('');
