import dayjs from 'dayjs';
import produce from 'immer';
import {
  MESSAGE_SET_READ_SUCCESS,
  MESSAGES_CHECK_MESSAGES_REQUEST,
  MESSAGES_CHECK_MESSAGES_SUCCESS,
  MESSAGES_GET_FAILURE,
  MESSAGES_GET_REQUEST,
  MESSAGES_GET_SUCCESS,
} from '@actions/messages.actions';
import * as T from '@types/load.types';

const initialState = {
  all: {
    status: T.LoadStatuses.NOT_LOADED,
    data: [],
    error: null,
  },
  unread: {
    data: [],
    status: T.LoadStatuses.NOT_LOADED,
    notification: 0,
  },
};

const messages = (state = initialState, action) => {
  switch (action.type) {
  case 'RESET_STORE':
    return initialState;
  case MESSAGES_GET_REQUEST:
    return {
      ...state,
      all: {
        ...state.all,
        isFetching: true,
        status: T.LoadStatuses.IS_LOADING,
        error: null,
      },
    };
  case MESSAGES_GET_SUCCESS:
    return {
      ...state,
      all: {
        isFetching: false,
        status: T.LoadStatuses.LOADED,
        data: action.payload.messages,
        error: null,
      },
    };
  case MESSAGE_SET_READ_SUCCESS:
    const messageId = action.payload.message_id;

    const newData = produce(state.all.data, draft => {
      const index = draft.findIndex(({message_id: id}) => id === messageId);

      if (index !== -1) {
        draft[index].isread = dayjs(new Date()).format('YYYY-MM-DD HH:MM:SS');
      }

      return draft;
    });

    const newUnread = produce(state.unread.data, draft => {
      const index = draft.findIndex(({message_id: id}) => id === messageId);

      if (index !== -1) {
        draft.splice(index, 1);
      }

      return draft;
    });

    return {
      ...state,
      all: {
        ...state.all,
        data: newData,
      },
      unread: {
        ...state.unread,
        data: newUnread,
        notification: state.unread.notification - 1,
      },
    };

  case MESSAGES_GET_FAILURE:
    return {
      ...state,
      all: {
        ...state.all,
        status: T.LoadStatuses.FAILED,
        isFetching: false,
        error: action.payload.error,
      },
    };
  case MESSAGES_CHECK_MESSAGES_REQUEST:
    return {
      ...state,
      unread: {
        ...state.unread,
        status: T.LoadStatuses.IS_LOADING,
      },
    };
  case MESSAGES_CHECK_MESSAGES_SUCCESS:
    return {
      ...state,
      unread: {
        ...state.unread,
        status: T.LoadStatuses.LOADED,
        data: action.payload.messages,
        notification: action.payload.notification,
      },
    };
  default:
    return state;
  }
};

export default messages;
