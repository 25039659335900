import {rgba} from 'polished';
import {css} from '@emotion/react';
import styled from '@emotion/styled';
import {mq} from '@facepaint';
import {colors} from '@src/colors';

export const StyledModalTitle = styled.h2`
    display: flex;
    font-weight: 500;
    margin-bottom: 0.35em;
    margin: 0;
`;

export const StyledModalBody = styled.div`
`;

export const Wrapper = styled.div`
  z-index: 2000;
`;

export const LearnMoreWrapper = styled.div`
    display: flex;
    align-items: center;
    * {
        color: ${colors.blue};
    }

    &:hover, &:focus{
        text-decoration: underline;
    }
    &:hover{
        cursor: pointer;
    }
`;

export const LearnMoreLink = styled.a`
    font-weight: 500;
    margin-right: 0.75em;
`;

export const ActionButtonsWrapper = styled.div`
  flex-direction: inherit;
  margin-left: auto;
  width: auto;
  flex-direction: row;
  display: flex;

  * {
    margin-right: 0;
    margin-left: 1.5em;
  }
`;

export const CloseButton = styled.button`
  background: ${rgba('#000000', 0.4)};
  color: white;
  border-radius: 50%;
  color: white;
  padding: 0;
  width: 1.7em;
  height: 1.7em;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 1.75em;
  top: 1.75em;

  &:hover {
    cursor: pointer;
    background: ${rgba('#000000', 0.48)};
  }

  svg {
    width: 55%;
    height: 55%;
    font-size: 1.75em;
  }

  ${({isMobile}) => isMobile && css`
    top: 1.5em;
    right: 1.5em;
  `}
`;

export const BottomButtonsWrapper  = styled.div`
    display: flex;
    align-items: center;

    margin-top: 1em;
    align-items: center;

    ${({isMobile}) => isMobile && css`
        margin-top: auto;
    `}
`;

export const BodyWrapper = styled.div`
    margin-bottom: 2.5em;
    height: 100%;
`;
export const TitleWrapper = styled.div`
    display: flex;
    margin-bottom: 0.6em;

    ${({isMobile}) => isMobile && css`
        margin-top: 1em;
    `}
`;

export const ContentWrapper  = styled.div`
    background: white;
    border-radius: 8px;
    padding-top: 2.5em;
    padding-left: 1.8em;
    padding-right: 1.8em;
    display: flex;
    flex-direction: column;
    border: 1px solid #E0E0E0;
    top: 2%;
    left: 50%;
    margin-top: 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 55%;

    .action-button.--button-spinner {
      pointer-events: none !important;
      cursor: default !important;
      transition: opacity 0.2s;

      &.action-button--cancel.--primary {
        opacity: .7;
      }
    }

    &.self-sign-modal {
      overflow-y: auto;
      max-height: 70vh;

      @media screen and (max-width: 768px) {
        max-height: unset;
      }
    }

    ${mq.medium} {
      width: 85%;
      top: 10%;
    }
    ${mq.large} {
      top: 15%;
    }
    max-width: 700px;
    ${({fullWidth}) => fullWidth && css`
      max-width: 1000px;
    `}
    padding-bottom: 2.2em;

    ${({hasIcon}) => hasIcon && css`
        ${BodyWrapper}, ${BottomButtonsWrapper}{
            margin-left: 2.5rem;
        }
        `}

    ${({isMobile, hasIcon}) => isMobile && css`
        width: calc(100% - 20px);
        height: calc(100% - 20px);
        margin: 10px;
        overflow: auto;
        transform: none;
        top: 0;
        left: 0;

        ${BottomButtonsWrapper}{
            flex-direction: column;
        }
        ${LearnMoreWrapper}{
            align-self: flex-start;
            margin-bottom: 0.35em;
        }

        ${hasIcon && css`
            ${BodyWrapper}, ${BottomButtonsWrapper} {
                margin-left: 1.1em;
            }

        `}

        ${ActionButtonsWrapper}{
            > * {
                margin-left: 0.6em;
            }
        }
      `}
`;
