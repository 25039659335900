import {
  HELP_GET_DATA_FAILURE,
  HELP_GET_DATA_REQUEST,
  HELP_GET_DATA_SUCCESS,
  HELP_SEARCH_FAILURE,
  HELP_SEARCH_REQUEST,
  HELP_SEARCH_SUCCESS,
  HELP_SEND_QUESTION_FAILURE,
  HELP_SEND_QUESTION_REQUEST,
  HELP_SEND_QUESTION_RESET,
  HELP_SEND_QUESTION_SUCCESS,
} from '@actions/help.actions';
import * as T from '@types/load.types';

const initialState = {
  helpPages: {
    data: null,
    error: null,
    status: T.LoadStatuses.NOT_LOADED,
  },
  questionSubmit:{
    data: null,
    error: null,
    status: T.LoadStatuses.NOT_LOADED,
  },
};

const helpReducer = (state = initialState, action) => {
  const {type, payload} = action;

  switch (type) {
  case HELP_GET_DATA_REQUEST: {
    return {
      ...state,
      helpPages:{
        ...state.helpPages,
        error: null,
        status: T.LoadStatuses.IS_LOADING,
      },
    };
  }
  case HELP_GET_DATA_SUCCESS: {
    return {
      ...state,
      helpPages:{
        ...state.helpPages,
        error: null,
        status: T.LoadStatuses.LOADED,
        data: {
          ...state.helpPages.data,
          [payload.path]: payload.data,
        },
      },
    };
  }
  case HELP_GET_DATA_FAILURE: {
    return {
      ...state,
      helpPages:{
        ...state.helpPages,
        error: payload.error,
        data: {
          ...state.data,
          status: T.LoadStatuses.FAILED,
        },
      },
    };
  }
  case HELP_SEARCH_REQUEST: {
    return {
      ...state,
      helpPages:{
        ...state.helpPages,
        error: null,
        status: T.LoadStatuses.IS_LOADING,
      },
    };
  }
  case HELP_SEARCH_SUCCESS: {
    return {
      ...state,
      helpPages:{
        ...state.helpPages,
        error: null,
        status: T.LoadStatuses.LOADED,
        data: {
          ...state.data,
          [payload.searchKey]: payload.data,
        },
      },
    };
  }
  case HELP_SEARCH_FAILURE: {
    return {
      ...state,
      helpPages:{
        ...state.helpPages,
        error: payload,
        status: T.LoadStatuses.FAILED,
        data: {...state.data},
      },
    };
  }
  case HELP_SEND_QUESTION_REQUEST: {
    return {
      ...state,
      questionSubmit:{
        ...state.questionSubmit,
        error: null,
        status: T.LoadStatuses.IS_LOADING,
      },
    };
  }
  case HELP_SEND_QUESTION_SUCCESS: {
    return {
      ...state,
      questionSubmit:{
        ...state.questionSubmit,
        error: null,
        status: T.LoadStatuses.LOADED,
      },
    };
  }
  case HELP_SEND_QUESTION_FAILURE: {
    return {
      ...state,
      questionSubmit:{
        ...state.questionSubmit,
        error: payload,
        status: T.LoadStatuses.FAILED,
      },
    };
  }
  case HELP_SEND_QUESTION_RESET:
    return {
      ...state,
      questionSubmit: initialState.questionSubmit,
    };
  default: {
    return state;
  }
  }
};

export default helpReducer;
