export const _snake2PascalOrCamel = pascal => str => {
  str += '';
  str = str.toLowerCase().split('_');
  for(var i = 0; i < str.length; i++) {
    const first = str[i].charAt(0).toUpperCase();
    const rest = str[i].slice(1);

    if (pascal) {
      str[i] = first + rest;
    } else {
      str[i] = i === 0 ? first.toLowerCase() + rest : first + rest;
    }
  }

  return str.join('');
};

export const snake2Pascal = _snake2PascalOrCamel(true);
export const snake2Camel = _snake2PascalOrCamel(false);

export const camel2Snake = str => str.replace(/([A-Z])/g, $1 => `_${$1.toLowerCase()}`);

export function camelCaseToNormalCase(str) {
  return str.replace(/([a-z])([A-Z])/g, '$1 $2').toLowerCase();
}

export const normalCaseToCamelCase = str => str.toLowerCase()
  .replace(
    /^\w|[A-Z]|\b\w/g,
    (word, index) => index === 0 ? word.toLowerCase() : word.toUpperCase(),
  )
  .replace(/\s+/g, '');

export const isObject = obj => obj && typeof obj === 'object' && !Array.isArray(obj);

export const isObjectWithKeys = obj => isObject(obj) && Object.keys(obj).length > 0;

export const joinPrefixes = args => args.join('/');

export const isUpperCase = str => str === str.toUpperCase();

export const isCamelCase = str => !/[\s_-]/g.test(str);
