import {freeze} from 'immer';
import merge from 'lodash/merge';
import union from 'lodash/union';
import {LoadStatuses} from '@types/load.types';
import {isObjectWithKeys} from './misc';

export function insertData({
  draftState,
  action,
  loadStatus,
  partial,
  rest,
  data,
}) {
  const {isNormalized} = draftState;

  const {payload} = action || {};
  const {
    data: payloadData,
    reset: payloadReset,
    empty: payloadEmpty,
    byId: payloadById,
    allIds: payloadAllIds,
    ...restPayload
  } = payload || {};

  const reset = !partial && (Array.isArray(payloadData) && !payloadData.length) || payloadReset || payloadEmpty;

  if (isObjectWithKeys(restPayload)) {
    const frozenRestPayload = freeze(restPayload);

    merge(draftState, frozenRestPayload);
  }

  const frozenData = freeze(payloadData);

  if (!isNormalized) {
    if (!partial) {
      draftState.data = frozenData;

      return;
    }

    draftState.data = payloadData
      ? union(draftState.data, frozenData)
      : draftState.data;

    return;
  }

  if (reset) {
    if (partial) {
      return;
    }

    draftState.allIds = [];
    draftState.byId = {};

    return;
  }

  if (!frozenData) {
    if (payloadById && payloadAllIds) {
      draftState.byId = freeze(payloadById);
      draftState.allIds = freeze(payloadAllIds);
    }

    return;
  }

  const _idKey = draftState.idKey || 'id';

  frozenData.forEach(item => {
    const id = item[_idKey];

    if (id == null) return;

    draftState.byId[id] = item;

    if (!draftState.allIds.includes(id)) {
      draftState.allIds.push(id);
    }
  });
};

export function update(draftState, action, loadStatus) {
  const {payload, type} = action || {};

  const isPartial = loadStatus === LoadStatuses.LOADED_PARTIAL || type.endsWith('PARTIAL') || type.endsWith('MORE');

  const {
    data = null,
    error = null,
    ...rest
  } = payload || {};

  draftState.error = error;

  if (draftState.data && (loadStatus === LoadStatuses.FAILED || error)) {
    draftState.data = null;
  } else if (data || isObjectWithKeys(rest)) {
    insertData({
      draftState,
      action,
      loadStatus,
      partial: isPartial,
    });
  }

  if (isPartial) {
    draftState.status = draftState.status === LoadStatuses.LOADED
      ? LoadStatuses.LOADED
      : LoadStatuses.LOADED_PARTIAL;
  } else {
    draftState.status = loadStatus;
  }

  const isLoaded = draftState.status === LoadStatuses.LOADED;

  if (isLoaded && draftState.refetchAfter) {
    draftState.lastFetch = Date.now();
  }

  draftState.isFetching = loadStatus === LoadStatuses.IS_LOADING;
};
